import { Button, Tooltip } from '@mui/material';
import React from 'react';

function IconButton({ icon, title, onClick, className }) {
  return (
    <Tooltip title={title} arrow>
      <Button className={className} onClick={onClick}>
        {icon}
      </Button>
    </Tooltip>
  );
}

export default IconButton;
