import moment from 'moment';
import React, { useState } from 'react';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import {
  appointmentTypeColors,
  weekDays,
} from '../../constants/otherConstants';
import { Chip } from '@mui/material';
import MockFeedbackDialog from './MockFeedbackDialog';
import { MdFeedback } from 'react-icons/md';
import IconButton from '../../common/components/IconButton';

function MockHistory({ mock }) {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const toggleFeedbackDialog = () => {
    setShowFeedbackDialog((prev) => !prev);
  };

  const appointmentName = `${mock?.Assignment?.AppointmentSetup?.type} : ${mock?.Assignment?.AppointmentSetup?.title}`;
  return (
    <>
      <div className="w-full  border p-3 grid grid-cols-4 items-center">
        <div className="flex flex-col gap-3 ">
          <p className="flex items-center gap-1">
            <FaCalendarAlt size="1em" color="#85c1e9" className="icon" />
            {mock?.date && moment(mock?.date).format('DD/MM/YYYY, ')}
            {weekDays[mock?.day]}{' '}
          </p>
          <p className="flex items-center gap-1">
            <FaClock size="1em" color="#f1948a" className="icon" />
            {moment(mock?.time, 'HH:mm:ss').format('hh:mm a')} -{' '}
            {moment(mock?.time, 'HH:mm:ss')
              .add(mock?.Assignment?.AppointmentSetup?.slotsLength, 'minutes')
              .format('hh:mm a')}
          </p>
        </div>

        <div className="flex flex-col items-center justify-center">
          <p>{mock?.Interviewer?.name}</p>
          <Chip
            color={
              appointmentTypeColors[mock?.Assignment?.AppointmentSetup?.type]
            }
            size="small"
            label={appointmentName}
          />
        </div>

        {/* STATUS */}
        <p className="b text-center">{mock?.status}</p>

        {/* FEEDBACK */}
        {mock?.feedback && (
          <IconButton
            icon={<MdFeedback className="text-3xl" />}
            title="Feedback"
            onClick={toggleFeedbackDialog}
          />
        )}
      </div>

      {showFeedbackDialog && (
        <MockFeedbackDialog
          feedback={mock?.feedback}
          onClose={toggleFeedbackDialog}
          open={showFeedbackDialog}
        />
      )}
    </>
  );
}

export default MockHistory;
