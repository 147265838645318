import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import './Courses.scss';
import { MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(category, selectedCategories, theme) {
  return {
    fontWeight: selectedCategories.find((c) => c.id === category.id)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
    background: selectedCategories.find((c) => c.id === category.id)
      ? 'lightGrey'
      : 'white',
  };
}

export default function CategoriesSelect(props) {
  const theme = useTheme();
  const categories = useSelector((state) => state.CoursesReducers.categories);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }
    props.setSelectedCategories(getUniqueListBy(value, 'id'));
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 700 }}>
        <InputLabel id="demo-multiple-chip-label">Categories</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props.selectedCategories}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Categories" />}
          renderValue={(selected) => (
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
              key={selected.id}
            >
              {selected.map((value) => (
                <Chip
                  key={value.id}
                  label={`${value.category} - ${value.dificulty} - ${value.points}`}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          disabled={props.isUpdate}
        >
          {categories.map((category) => (
            <MenuItem
              key={category.id}
              value={category}
              style={getStyles(category, props.selectedCategories, theme)}
              selected={props.selectedCategories.find(
                (c) => c.id === category.id
              )}
            >
              {`${category.category} - ${category.dificulty} - ${category.points}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
