import React, { useState, useEffect, useRef } from 'react';
import './TaskQuestions.scss';
import Loader from '../../common/components/Loader';
import EditIcon from '@material-ui/icons/Edit';
import agent from '../../agent';
import { taskQuestionTypes, taskStatus } from '../../constants/otherConstants';
import TaskQuestionAnswer from './TaskQuestionAnswer';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { toast } from 'react-toastify';
import { Draggable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import { reactQuillFormats, reactQuillModules } from '../../common/functions';

function TaskQuestion({
  isCreateNew,
  onQuestionAdd,
  onQuestionDelete,
  question: propsQuestion,
  task,
  index,
}) {
  const quillQuestionTextRef = useRef(null);
  const quillAnswerTextRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(
    propsQuestion || { answers: [], points: 1, type: -1 }
  );
  const [showAction, setShowAction] = useState(false);
  const [isEditing, setIsEditing] = useState(isCreateNew);

  useEffect(() => {
    if (propsQuestion && propsQuestion !== question) {
      setQuestion(propsQuestion);
    }
  }, [propsQuestion]);

  const onQuestionTypeChange = (e) => {
    const {
      target: { value },
    } = e;
    setShowAction(true);
    setQuestion({ ...question, type: Number(value) });
  };

  const createQuestion = async () => {
    if (!question.questionText) {
      toast.error('Please enter question text');
      return;
    }

    if (!question.points) {
      toast.error('Please enter question points');
      return;
    }

    if (question.type == null) {
      toast.error('Please choose question type');
      return;
    }

    // if question type is MCQ or multi select mcq then one answer needs to be selected.
    if (
      taskQuestionTypes[question.type] === taskQuestionTypes[1] ||
      taskQuestionTypes[question.type] === taskQuestionTypes[2]
    ) {
      // at least one answer needs to be correct.
      const correctAnswer = question.answers.find(
        (answer) => answer.isCorrect === true
      );

      if (!correctAnswer) {
        toast.error('Question should have a correct answer');
        return;
      }
    }

    setLoading(true);
    // make an api call to add question in backend.
    if (isCreateNew) {
      // make an api call to add question in backend
      const data = { ...question, AssignmentTaskId: task.id, index };
      try {
        const createdQuestion = await agent.Assignments.createTaskQuestion(
          data
        );
        setQuestion({ questionText: '', answers: [], points: 1, type: -1 });
        onQuestionAdd({ ...data, id: createdQuestion.data.data.id });

        quillQuestionTextRef.current?.getEditor()?.setContents([]);
        quillAnswerTextRef.current?.getEditor()?.setContents([]);
      } catch (err) {}
    } else {
      try {
        await agent.Assignments.updateTaskQuestion(question);
        setIsEditing(false);
      } catch (err) {
        console.log(err);
      }
    }

    setLoading(false);
  };

  const deleteQuestion = async () => {
    try {
      await agent.Assignments.deleteTaskQuestion({ id: question.id });
      onQuestionDelete(question);
      toast.success('Question deleted');
    } catch (err) {
      console.log(err);
    }
  };
  const cancelEditing = () => {
    setIsEditing(false);
  };

  const onAnswerAdd = (answer) => {
    setQuestion({ ...question, answers: [...question.answers, answer] });
  };

  const onAnswerUpdate = (answer) => {
    const updatedAnswers = question.answers.map((a) => {
      return a.id === answer.id ? answer : a;
    });

    setQuestion({ ...question, answers: updatedAnswers });
  };

  const onAnswerDelete = (answerIndex) => {
    question.answers.splice(answerIndex, 1);
    setQuestion({ ...question, answers: [...question.answers] });
  };

  const onAnswerChange = (event) => {
    if (event.target.type === 'text') {
      return;
    }

    const value = event.target.value;
    // if the question type is mcq then it can have only one correct answer.
    if (taskQuestionTypes[question.type] === taskQuestionTypes[1]) {
      const newAnswers = question.answers.map((answer, i) => {
        return { ...answer, isCorrect: i === Number(value) };
      });

      setQuestion({ ...question, answers: newAnswers });
    } else if (taskQuestionTypes[question.type] === taskQuestionTypes[2]) {
      question.answers[Number(value)].isCorrect = event.target.checked;
      setQuestion({ ...question });
    }
  };

  const handleDescriptionChange = (value) => {
    setQuestion({ ...question, answerExplanation: value });
  };
  const handleQuestionTextChange = (value) => {
    setQuestion({ ...question, questionText: value });
  };

  console.log(question);
  return (
    <Draggable draggableId={`${question.id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="task-question bt1 pt2 ba br2 pa2 mb2 relative">
            <div className="flex justify-end">
              {isEditing ? (
                <div className="flex ">
                  <button className="primary-btn mr2" onClick={createQuestion}>
                    {!isCreateNew ? 'Update' : 'Create'} Question
                  </button>
                  {!isCreateNew && (
                    <button className="red-btn" onClick={deleteQuestion}>
                      <DeleteForeverIcon />
                    </button>
                  )}
                </div>
              ) : (
                !task.isLockedForEdit && (
                  <button onClick={() => setIsEditing(true)}>
                    <EditIcon />
                  </button>
                )
              )}
            </div>
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-col gap-3 w-full">
                <p className="mr2 b">Question: </p>
                {isEditing ? (
                  <ReactQuill
                    defaultValue={question?.questionText || ''}
                    onChange={handleQuestionTextChange}
                    modules={reactQuillModules}
                    formats={reactQuillFormats}
                    ref={quillQuestionTextRef}
                    className="mb2"
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question?.questionText || '',
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center mb2">
              <p className="mr2 b">Points: </p>
              {isEditing ? (
                <input
                  placeholder="Question points"
                  type="number"
                  onChange={(e) =>
                    setQuestion({ ...question, points: e.target.value })
                  }
                  value={question.points}
                  className="h-7 rounded p-1"
                />
              ) : (
                <p>{question.points}</p>
              )}
            </div>
            <div className="flex items-center mb2">
              <p className="mr2 b">Type:</p>
              {isEditing ? (
                <select onChange={onQuestionTypeChange} value={question?.type}>
                  <option value={-1}> Select Question Type</option>

                  {Object.keys(taskQuestionTypes).map((k) => {
                    return (
                      <option key={k} value={k}>
                        {taskQuestionTypes[k]}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <p>{taskQuestionTypes[question.type]}</p>
              )}
            </div>

            <div className="action-section">
              <div className="mt2">
                {taskQuestionTypes[question.type] === taskQuestionTypes[1] ||
                taskQuestionTypes[question.type] === taskQuestionTypes[2] ? (
                  <>
                    <p className="b">Answers: </p>
                    <div onChange={onAnswerChange}>
                      {question.answers &&
                        question.answers.map((o, i) => (
                          <TaskQuestionAnswer
                            type={question.type}
                            key={o.id || i}
                            onAnswerAdd={onAnswerAdd}
                            onAnswerDelete={onAnswerDelete}
                            answer={o}
                            question={question}
                            answerIndex={i}
                            isEditing={isEditing}
                            onAnswerUpdate={onAnswerUpdate}
                          />
                        ))}
                    </div>
                    {isEditing && (
                      <TaskQuestionAnswer
                        type={question.type}
                        onAnswerAdd={onAnswerAdd}
                        onAnswerDelete={onAnswerDelete}
                        isEditing={isEditing}
                        createNewAnswer={true}
                        question={question}
                        answerIndex={question.answers.length + 1}
                      />
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div>
              <p className="b mt2 mb2"> Answer explanation</p>
              {isEditing ? (
                <ReactQuill
                  defaultValue={question?.answerExplanation || ''}
                  onChange={handleDescriptionChange}
                  modules={reactQuillModules}
                  formats={reactQuillFormats}
                  ref={quillAnswerTextRef}
                  className="mb2"
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answerExplanation,
                  }}
                />
              )}
            </div>
          </div>
          {loading && <Loader />}
        </div>
      )}
    </Draggable>
  );
}
export default TaskQuestion;
