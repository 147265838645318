import { GET_QUESTIONS } from '../../constants/actionTypes';

const defaultState = {
  questions: { rows: [] },
};

const QuestionsReducers = (state = defaultState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      return { ...state, questions: action.payload || [] };

    default:
      return state;
  }
};

export default QuestionsReducers;
