import CoursesReducers from './Courses/CoursesReducers';
import AssignmentsReducers from './Courses/AssignmentsReducers';
import QuestionsReducers from './Questions/QuestionsReducers';
import SlotsReducer from './Slots/SlotsReducer';
export default {
  QuestionsReducers,
  CoursesReducers,
  AssignmentsReducers,
  SlotsReducer,
};
