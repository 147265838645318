import React, { useState, useEffect } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import './Courses.scss';
import { Link } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';
import { questionStatus } from '../../constants/otherConstants';

function Course(props) {
  const [course, setCourse] = useState({});
  useEffect(() => {
    if (props.course) {
      setCourse(props.course);
    }
  });
  return (
    <div className="course">
      <div className="flex items-center justify-between">
        <h3>{course.name}</h3>
        {course.creator && !props.mentor && (
          <Link
            className="underline blue"
            to={`${pathNames.MENTOR_DETAILS}?MentorId=${course.creator.id}`}
          >
            {course.creator.name}
          </Link>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: course.description,
        }}
      ></div>
      <div className="actions">
        <Link to={`${pathNames.COURSE_STUDENTS}?CourseId=${course.id}`}>
          <GroupIcon />
        </Link>
        <Link
          to={`${pathNames.QNA_PAGE}?CourseId=${course.id}&&status=${questionStatus.UNANSWERED}`}
        >
          <HelpIcon />
        </Link>
        <Link to={`${pathNames.MESSAGES}?CourseId=${course.id}`}>
          <EmailIcon />
        </Link>
        {/* <EditIcon /> */}
        <Link to={`${pathNames.COURSE_PLAN}?CourseId=${course.id}`}>
          <AccountTreeIcon />
        </Link>

        <Link to={`${pathNames.COURSE_DETAILS}?CourseId=${course.id}`}>
          <EditIcon />
        </Link>
      </div>
    </div>
  );
}

export default Course;
