import React from 'react';
import { appointmentRatings } from '../../constants/otherConstants';

function AllAppointmentQuestions({ allQuestions, setCurrentQuestionIndex }) {
  const handelCurrentQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
  };

  return (
    <div className=" appointmentScrollbar w-[20%] bg-emerald-700 flex flex-col gap-1 sticky bottom-0 left-0 p-1 h-[calc(100vh-7rem)] overflow-y-auto">
      {allQuestions.map((question, index) => {
        return (
          <div
            onClick={() => handelCurrentQuestionChange(index)}
            className={`p-2 h-10 cursor-pointer items-center  overflow-hidden   flex-shrink-0 flex justify-between ${
              question?.feedback
                ? `${appointmentRatings[question?.rating]?.color}  text-white`
                : 'bg-gray-100'
            }`}
            key={question.id}
          >
            <p className="text-ellipsis overflow-hidden  whitespace-nowrap ">
              {question?.AssignmentTask?.name}
            </p>
            {question?.feedback && (
              <p className="flex-shrink-0">
                {appointmentRatings[question?.rating]?.emoji}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default AllAppointmentQuestions;
