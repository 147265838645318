import React, { useEffect, useState } from 'react';
import agent from '../../agent';
import { useSelector, useDispatch } from 'react-redux';
import Slot from './Slot';
import { GET_ALL_APPOINTMENTS_SLOTS } from '../../constants/actionTypes';
import { ERROR_MSG } from '../../constants/otherConstants';
import Loader from '../../common/components/Loader';
import { toast } from 'react-toastify';
const Slots = () => {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.common.user);
  const appointments = useSelector((state) => state.SlotsReducer.appointments);

  const dispatch = useDispatch();

  const fetchMentorAppointments = async () => {
    setIsLoading(true);
    try {
      const res = await agent.Appointments.getAllAppointments(
        `?InterviewerId=${user.id}`
      );
      dispatch({
        type: GET_ALL_APPOINTMENTS_SLOTS,
        payload: res.data.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || ERROR_MSG);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMentorAppointments();
  }, []);

  return (
    <>
      <div className="courses-page">
        <div className="head">
          {isLoading && <Loader />}
          <h2 className="p-5 b text-2xl">Choose Your Slots</h2>
        </div>
        {appointments?.length > 0 ? (
          <div className="all-courses">
            {appointments?.map((c) => (
              <Slot appointment={c} key={c.id} />
            ))}
          </div>
        ) : (
          <p className="tc">No Appointments present</p>
        )}
      </div>
    </>
  );
};

export default Slots;
