import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Loader from '../../common/components/Loader';

import {
  getParameterByName,
  reactQuillFormats,
  reactQuillModules,
} from '../../common/functions';

import agent from '../../agent';
import { ERROR_MSG } from '../../constants/otherConstants';

import './UserMessages.scss';
import { pathNames } from '../../constants/navigationPath';
import ReactQuill from 'react-quill';

function UserMessages({ location: { search } }) {
  const [loading, setLoading] = useState(false);

  const courses = useSelector((state) => state.CoursesReducers.courses);
  const [assignments, setAssignments] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedAssignment, setSelectedAssignment] = useState({});
  const [selectedTask, setSelectedTask] = useState({});
  const [message, setMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    const CourseId = getParameterByName('CourseId');
    const TaskId = getParameterByName('AssignmentTaskId');
    const AssignmentId = getParameterByName('AssignmentId');
    (async () => {
      let course,
        assignment,
        task,
        selectCourseAssignments = assignments;
      if (CourseId) {
        course = courses?.find((c) => Number(c.id) === Number(CourseId));
        if (course && course.id !== selectedCourse.id) {
          // the course hs changes so take the new assignmetns of that course
          try {
            const res = await agent.Assignments.getAllAssignments(
              `?CourseId=${CourseId}`
            );
            setAssignments(res.data.data);
            selectCourseAssignments = res.data.data;
          } catch (err) {
            console.log(err, err.response);
            toast.error(ERROR_MSG);
          }
          setSelectedCourse(course);
        }
      }

      if (
        AssignmentId &&
        selectCourseAssignments &&
        selectCourseAssignments.length > 1
      ) {
        assignment = selectCourseAssignments.find(
          (c) => Number(c.id) === Number(AssignmentId)
        );
        setSelectedAssignment(assignment);
      } else {
        setSelectedAssignment({ id: '-1' });
      }

      if (TaskId && assignment) {
        task = assignment.AssignmentTasks.find(
          (c) => Number(c.id) === Number(TaskId)
        );
        if (task) {
          setSelectedTask(task);
        }
      } else {
        setSelectedTask({ id: '-1' });
      }
    })();
  }, [search]);

  const filterQuestions = (e, key) => {
    const { value } = e.target;
    if (value === '-1' && key === 'CourseId') {
      return;
    }

    const filters = {};

    filters.CourseId = getParameterByName('CourseId');

    // on selecting course we need only course parameter
    // so only get taskId when changing taskId or status
    // else we lose the taskId
    if (key === 'AssignmentTaskId' || key === 'status') {
      filters.AssignmentTaskId = getParameterByName('AssignmentTaskId');
    }

    // on selecting course we need only course parameter
    // so only get assignmentId when changing assignmentid or taskId or status
    // else we lose the assignmentId
    if (
      key === 'AssignmentId' ||
      key === 'AssignmentTaskId' ||
      key === 'status'
    ) {
      filters.AssignmentId = getParameterByName('AssignmentId');
    }

    filters.status = getParameterByName('status');
    filters[key] = value;

    let query = '';
    if (filters.CourseId) {
      query = '?CourseId=' + filters.CourseId;
    }
    if (filters.status) {
      if (query === '') {
        query = '?status=' + filters.status;
      } else {
        query += `&&status=${filters.status}`;
      }
    }
    if (filters.AssignmentId) {
      // set assignmentId in parameter when changing assignmentId or taskId or status
      // else  assignmentId wil become -1
      // if value becomes -1 we dont need that
      if (
        (key === 'AssignmentId' && value != '-1') ||
        key === 'AssignmentTaskId' ||
        key === 'status'
      ) {
        if (query === '') {
          query = '?AssignmentId=' + filters.AssignmentId;
        } else {
          query += `&&AssignmentId=${filters.AssignmentId}`;
        }
      }
    }
    if (filters.AssignmentTaskId) {
      // set taskId in parameter when changing taskId or status
      // else  taskId wil become -1
      // if value becomes -1 we have to remove that
      if ((key === 'AssignmentTaskId' && value != '-1') || key === 'status') {
        if (query === '') {
          query = '?AssignmentTaskId=' + filters.AssignmentTaskId;
        } else {
          query += `&&AssignmentTaskId=${filters.AssignmentTaskId}`;
        }
      }
    }

    history.push(`${pathNames.MESSAGES}${query}`);
  };
  const sendMessage = () => {
    let data = {};
    // if (selectedTask && selectedTask.id && selectedTask.id !== "-1") {
    //   data = { TaskId: selectedTask.id }
    // } else
    if (
      selectedAssignment &&
      selectedAssignment.id &&
      selectedAssignment.id !== '-1'
    ) {
      data = { AssignmentId: selectedAssignment.id };
    } else if (
      selectedCourse &&
      selectedCourse.id &&
      selectedCourse.id !== '-1'
    ) {
      data = { CourseId: selectedCourse.id };
    }
    data = { ...data, notification: message };
    setLoading(true);
    agent.Messages.sendMessage(data)
      .then((res) => {
        console.log(res.data.data);
        toast.success('Message Sent');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="message-page">
        <div className="message-page-content">
          <div className="filters-div">
            <div className="filters">
              <div className="select-div">
                <h3>Select Course</h3>
                <select
                  value={selectedCourse.id}
                  onChange={(e) => filterQuestions(e, 'CourseId')}
                >
                  <option value="-1">Select a Course</option>
                  {courses?.map((c) => (
                    <option value={c.id}> {c.name} </option>
                  ))}
                </select>
              </div>
              <div className="select-div">
                <h3>Select Assignment</h3>
                <select
                  value={selectedAssignment.id}
                  onChange={(e) => filterQuestions(e, 'AssignmentId')}
                >
                  <option value="-1">Select an Assignment</option>
                  {assignments?.data?.map((c) => (
                    <option value={c.id}> {c.name} </option>
                  ))}
                </select>
              </div>
              {/* <div className='select-div'>
                <h3>Select Task</h3>
                <select
                  value={selectedTask.id}
                  onChange={(e) => filterQuestions(e, 'AssignmentTaskId')}
                >
                  <option value='-1'>Select a Task</option>
                  {selectedAssignment.AssignmentTasks &&
                    selectedAssignment.AssignmentTasks.map((c) => (
                      <option value={c.id}> {c.name} </option>
                    ))}
                </select>
              </div> */}
            </div>
          </div>

          <div className="content-div">
            <div className="heading">
              <h3>Send messages</h3>
              <div className="mt3">
                <p className="mt1">
                  Sending Message to all Students who are currently present in{' '}
                </p>
                <p className="mt1">
                  {selectedCourse && `Course: ${selectedCourse.name}`}
                </p>
                <p className="mt1">
                  {selectedAssignment.name &&
                    `Assignment: ${selectedAssignment.name}`}{' '}
                </p>
                <p className="mt1">
                  {selectedTask.name && `Task: ${selectedTask.name}`}{' '}
                </p>
              </div>
            </div>
            <div className="message-form">
              <h4 className="mb3">Write Your Message:</h4>
              <ReactQuill
                defaultValue={message}
                value={message}
                onChange={(value) => setMessage(value)}
                modules={reactQuillModules}
                formats={reactQuillFormats}
                style={{ backgroundColor: 'white' }}
              />
              <button className="primary-btn" onClick={sendMessage}>
                Send message
              </button>
            </div>
          </div>
        </div>

        {loading && <Loader />}
      </div>
    </>
  );
}

export default UserMessages;
