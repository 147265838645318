import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',

    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
    '&.MuiButtonBase-root': {
      padding: '10px 20px',
    },
  })
);

export default function BasicTabs({ components, children }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue !== undefined) setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}
      >
        <AntTabs value={value} onChange={handleChange}>
          {components.map((component, index) => (
            <AntTab
              key={index}
              label={component.title}
              id={index}
              {...a11yProps(index)}
            />
          ))}
          {children}
        </AntTabs>
      </Box>
      {components[value]?.component}
    </Box>
  );
}
