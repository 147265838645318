import React from 'react';
import mentorImg from '../../assets/Images/mentor.png';

const Content = () => {
  return (
    <div className="right">
      <div className="images fade-in">
        <h3>Welcome back!</h3>
        <img src={mentorImg} />
      </div>
    </div>
  );
};

export default Content;
