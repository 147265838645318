import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import 'tachyons';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import GroupsIcon from '@mui/icons-material/Groups';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BackupTableIcon from '@mui/icons-material/BackupTable';
// import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { push } from 'react-router-redux';

import { pathNames } from '../../constants/navigationPath';

import './NavBar.css';
import {
  colors,
  drawerWidth,
  APP_NAME,
  logo,
} from '../../constants/otherConstants';

import { LOGOUT, TOGEL_DRAWER } from '../../constants/actionTypes';

const { primaryColor, lightBackground, text } = colors.light;
let useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // position: 'absolute',
    // right: '-10px',
    // top: '80%',
    // zIndex: 50
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: lightBackground,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginTop: navBarHeight,
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: lightBackground,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    // marginTop: navBarHeight,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AuthNavBar() {
  const classes = useStyles();

  // const user = useSelector((state) => state.common.user);
  const drawerOpen = useSelector((state) => state.common.drawerOpen);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const links = [
    {
      icon: (
        <MenuBookIcon
          style={{ fill: pathname === '/courses' ? text : primaryColor }}
        />
      ),
      text: 'Courses',
      link: pathNames.COURSES,
      onClick: () => dispatch(push('/courses')),
    },
    // {
    // 	icon: (
    // 		<PersonIcon
    // 			style={{ fill: pathname === '/profile' ? text : primaryColor }}
    // 		/>
    // 	),
    // 	text: 'Profile',
    // 	link: '/profile',

    // 	// onClick: () => dispatch(push('/profile')),
    // },
    {
      icon: (
        <GroupsIcon
          style={{ fill: pathname === '/appointments' ? text : primaryColor }}
        />
      ),
      text: 'Appointments',
      link: pathNames.APPOINTMENTS,
      onClick: () => dispatch(push('/appointments')),
    },
    {
      icon: (
        <BackupTableIcon
          style={{ fill: pathname === '/slots' ? text : primaryColor }}
        />
      ),
      text: 'Slots',
      link: pathNames.SLOTS,
      onClick: () => dispatch(push('/slots')),
    },
    {
      icon: (
        <PeopleAltIcon
          style={{ fill: pathname === '/teams' ? text : primaryColor }}
        />
      ),
      text: 'Teams',
      link: pathNames.TEAMS,
      onClick: () => dispatch(push('/teams')),
    },
    {
      text: 'Logout',
      icon: <ExitToAppSharpIcon style={{ fill: primaryColor }} />,
      onClick: () => dispatch({ type: LOGOUT }),
    },
  ];

  const handleDrawerOpen = () => {
    dispatch({ type: TOGEL_DRAWER, payload: true });
  };

  const handleDrawerClose = () => {
    dispatch({ type: TOGEL_DRAWER, payload: false });
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position='fixed' className={clsx(classes.appBar, {})}>
				<div className='nav-bar'>
					<h6>Hey {user.name || 'Stranger'}!</h6>
					<button style={{ display: 'flex', alignItems: 'center' }}>
						<HelpIcon style={{ fontSize: '22px' }} /> <p>Help</p>
					</button>
				</div>
			</AppBar> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <ListItem>
          <ListItemIcon>
            <img className={classes.logo} src={logo} />
          </ListItemIcon>
          <h3 style={{ color: colors.light.primaryColor }}>{APP_NAME}</h3>
        </ListItem>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: drawerOpen,
          })}
        >
          <ChevronRightIcon style={{ fill: colors.light.primaryColor }} />
        </IconButton>
        {drawerOpen && (
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        )}
        <Divider />

        <Divider />
        <List>
          {links.map((l) => (
            <ListItem
              button
              key={l.text}
              onClick={l.onClick}
              style={{
                backgroundColor: l.link === pathname ? primaryColor : '',
              }}
            >
              <ListItemIcon>{l.icon}</ListItemIcon>
              <p
                style={{
                  color: l.link === pathname ? 'white' : '',
                }}
              >
                {l.text}
              </p>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}

export default AuthNavBar;
