import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonTitle,
  cancelButtonTitle,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        <h2 className="text-xl b">{title}</h2>
        <p className="mt-1">{message}</p>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} style={{ color: '#e60000' }}>
          {cancelButtonTitle}
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#1a73e8',
            color: 'white',
            fontWeight: '500',
          }}
          className="new-btn-1   "
          onClick={onConfirm}
        >
          {confirmButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonTitle: PropTypes.string.isRequired,
  cancelButtonTitle: PropTypes.string.isRequired,
};

export default ConfirmationDialog;
