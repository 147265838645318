import React, { useEffect, useState } from 'react';
import { FaSyringe } from 'react-icons/fa';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Close, Delete } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import agent from '../../agent';
import { getErrorMessage } from '../../constants/helperFunctions';
import { subjectiveStrikeComments } from '../../constants/otherConstants';

function UserStrikes({ user, setUser }) {
  const [loading, setLoading] = useState(false);
  const [showUserStrikeDialog, setShowUserStrikeDialog] = useState(false);
  const [strikeReason, setStrikeReason] = useState('');
  const [strikeSubject, setStrikeSubject] = useState('');
  const [strikes, setStrikes] = useState([]);
  const loggedInUser = useSelector((state) => state.common.user);

  useEffect(() => {
    if (!user?.id || !showUserStrikeDialog) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const { data } = await agent.Strike.getUserStrikes(
          `?UserId=${user?.id}`
        );
        setStrikes(data.data);
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();
  }, [user, showUserStrikeDialog]);

  // Function to toggle user strike dialog
  const toggleUserStrikeDialog = () => {
    setShowUserStrikeDialog((prev) => !prev);
  };

  // Function to add new strike
  const addNewStrike = async () => {
    if (!user?.id) {
      return toast.error('No User Selected');
    }

    if (strikeReason.length === 0) {
      return toast.error('Enter a reason for strike');
    }
    setLoading(true);
    try {
      const { data } = await agent.Strike.addUserStrike({
        UserId: user?.id,
        strikeReason,
        strikeSubject,
      });

      setStrikes((prev) => [...prev, data.data]);
      setUser((prev) => {
        const updatedUser = {
          ...prev,
          UserDetail: {
            ...prev.UserDetail,
            strikes: prev.UserDetail.strikes + 1,
          },
        };
        return updatedUser;
      });

      toast.success('Added new strike');
      setStrikeReason('');
      setStrikeSubject('');
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  // Function to delete user strike
  const deleteStrike = async (strikeId) => {
    setLoading(true);
    try {
      await agent.Strike.deleteUserStrike(
        `?strikeId=${strikeId}&UserId=${user?.id}`
      );
      setStrikes((prev) => prev.filter((s) => s.id !== strikeId));
      setUser((prev) => {
        const updatedUser = {
          ...prev,
          UserDetail: {
            ...prev.UserDetail,
            strikes: prev.UserDetail.strikes - 1,
          },
        };
        return updatedUser;
      });
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Badge color="error" badgeContent={user?.UserDetail?.strikes ?? 0}>
        <FaSyringe
          onClick={toggleUserStrikeDialog}
          className="red text-xl cursor-pointer"
        />
      </Badge>

      <Dialog
        onClose={toggleUserStrikeDialog}
        fullWidth
        maxWidth="md"
        open={showUserStrikeDialog}
      >
        <DialogTitle>
          <div className="flex justify-between items-center w-100">
            <h3>Add Strike</h3>
            <Close
              onClick={toggleUserStrikeDialog}
              className="cursor-pointer"
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-column gap-10 addStrikes">
            <h3>Add New Strikes</h3>
            {/* ADD STRIKE INPUT */}
            <div className="flex items-center justify-between gap-10">
              <input
                onChange={(e) => {
                  setStrikeReason(e.target.value);
                }}
                value={strikeReason}
                placeholder="Reason for the strike"
                className="pa2 br2  w-70 globalInput "
              />

              <select
                onChange={(e) => {
                  setStrikeSubject(e.target.value);
                }}
                value={strikeSubject}
                style={{ height: '48px' }}
                className="pa2 br2 w-30 globalSelect "
              >
                <option>Select Subjective Comment</option>
                {Object.keys(subjectiveStrikeComments).map((k) => (
                  <option value={k} key={k}>
                    {subjectiveStrikeComments[k]}
                  </option>
                ))}
              </select>

              <Button
                disabled={loading}
                variant="contained"
                color="error"
                onClick={addNewStrike}
              >
                {loading ? <p>Loading...</p> : <p>Add</p>}
              </Button>
            </div>

            {/* CURRENT STRIKES */}
            <h3>Current Strikes</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Given By</TableCell>
                    <TableCell className="w-60">Reason</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Given At</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {strikes.map((strike) => (
                    <TableRow key={strike.id} className="clearHoverEffect">
                      <TableCell>{strike?.Mentor?.name}</TableCell>
                      <TableCell>{strike.reason}</TableCell>
                      <TableCell>{strike.subject}</TableCell>
                      <TableCell>
                        {moment(strike.createdAt).format('DD-MMM-yy hh:mm a')}
                      </TableCell>
                      <TableCell>
                        {loggedInUser.id === strike?.Mentor?.id && (
                          <div className="flex justify-center">
                            <Delete
                              className="red cursor-pointer"
                              onClick={() => deleteStrike(strike.id)}
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserStrikes;
