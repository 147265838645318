/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import './Courses.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import CourseStudentsFilter from './CourseStudentsFilter';
import moment from 'moment';

import { toast } from 'react-toastify';
import agent from '../../agent';
import { convertToLink, getParameterByName } from '../../common/functions';

import { useSelector } from 'react-redux';
import Loader from '../../common/components/Loader';
import { ERROR_MSG, OPERATOR_DELIMITER } from '../../constants/otherConstants';
import NavBar from '../NavBar/NavBar';

import { Link } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';

let timeout = null;
const filterKeyToString = {
  reviewPendingTasks: '# of pending review tasks',
  reviewPendingAssignment: '# of pending review assignments',
  last7days: '# of last 7 days tasks',
  last3days: '# of last 3 days tasks',
  last1day: '# of last 1 days tasks',
  totalPoints: 'User Points',
  inCourse: 'In Course (Days)',
  isCompleted: 'Is Course Completed',
  lastAssignment: 'Last Assignment',
  currentAssignment: 'Current Assignment',
};

export default function CourseStudents({ location: { search } }) {
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [courseStudents, setCourseStudents] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ isCompleted: false });
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchString, setSearchString] = useState('');
  const [searchByOption, setSearchByOption] = useState('name');
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [courseAssignments, setCourseAssignments] = useState([]);

  // const dispatch = useDispatch();
  const courses = useSelector((state) => state.CoursesReducers.courses);

  const getFilterString = (filterOptions) => {
    // compulsory conditions
    const courseId = getParameterByName('CourseId', search);
    filterOptions.CourseId = courseId;

    let optionString = '';

    Object.keys(filterOptions).forEach((k) => {
      if (filterOptions[k] != null) {
        switch (k) {
          case 'reviewPendingTasks': {
            // the review pending task will search for the any number greater then the provided value
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }
          case 'reviewPendingAssignment': {
            // the review pending task will search for the any number greater then the provided value
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }

          case 'last7days': {
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }
          case 'last3days': {
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }
          case 'last1day': {
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }
          case 'totalPoints': {
            optionString += `${k}=${filterOptions[k]}${OPERATOR_DELIMITER}>=&`;
            break;
          }
          case 'inCourse': {
            // for caluculating the in course filter we will take all the students who have joined before the today - day in course
            // for example the filter is "inCourse=5"
            // this means that i want all the students who have joined before today - 5 days
            const thresoldDate = moment().subtract(filterOptions[k], 'days');
            optionString += `createdAt=${thresoldDate}${OPERATOR_DELIMITER}<=&`;
            break;
          }
          default: {
            optionString += `${k}=${filterOptions[k]}&`;
            break;
          }
        }
      }
    });

    optionString = optionString.substring(0, optionString.length - 1);

    if (optionString.length > 0) {
      optionString = `?${optionString}`;
    }

    return optionString;
  };

  const getFilteredUsers = async (filterOptions, showLoading = true) => {
    if (showLoading) setLoading(true);

    // compulsory conditions
    const courseId = getParameterByName('CourseId', search);
    filterOptions.CourseId = courseId;

    let optionString = getFilterString(filterOptions);
    try {
      const res = await agent.Courses.getStudentsOfaCourse(optionString);

      setLoading(false);
      const { totalItems, data } = res.data.data;

      setTotalCount(totalItems);

      setCourseStudents(data);
    } catch (err) {
      setLoading(false);
      toast.error(ERROR_MSG);
      console.log(err, err.response);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const newFilterOptions = {
          ...filterOptions,
          page: page + 1,
          size: rowsPerPage,
        };
        setPage(page);
        getFilteredUsers(newFilterOptions);
        const courseId = getParameterByName('CourseId', search);
        const course = courses?.find((c) => c.id === Number(courseId));
        setCourse(course);
        if (course) {
          const res = await agent.Assignments.getAllAssignments(
            `?CourseId=${course.id}`
          );
          setCourseAssignments(res.data.data);
        }
      } catch (err) {
        console.log(err, err.response);
        toast.error(ERROR_MSG);
      }
    })();
  }, [search]);

  useEffect(() => {
    const page = 0;

    if (timeout) {
      clearTimeout(timeout);
    }
    if (!searchString) {
      const newFilterOptions = {
        ...filterOptions,
        page: page + 1,
        size: rowsPerPage,
      };
      getFilteredUsers(newFilterOptions, false);
      clearTimeout(timeout);

      return;
    }
    setPage(page);
    timeout = setTimeout(() => {
      // After the operator delimited we have the oprator defined here like is an operator for matching the name
      // the '%' represent the any number of character matching
      const newFilterOptions = {
        ...filterOptions,
        [searchByOption]: `%${searchString}%${OPERATOR_DELIMITER}like`,
        page: page + 1,
        size: rowsPerPage,
      };
      getFilteredUsers(newFilterOptions, false);
    }, 1000);
  }, [searchByOption, searchString]);

  useEffect(() => {
    // if the filter opttion chnages then reset the page  to the 0 iundex page and reset the seach string as it is not necessary
    const page = 0;
    // setSearchString("")
    const newFilterOptions = {
      ...filterOptions,
      name: `%${searchString}%${OPERATOR_DELIMITER}like`,
      page: page + 1,
      size: rowsPerPage,
    };
    setPage(page);
    getFilteredUsers(newFilterOptions);
  }, [filterOptions]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newFilterOptions = {
      ...filterOptions,
      page: newPage + 1,
      size: rowsPerPage,
      name: `%${searchString}%${OPERATOR_DELIMITER}like`,
    };
    getFilteredUsers(newFilterOptions);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    const newFilterOptions = {
      ...filterOptions,
      page: page + 1,
      size: event.target.value,
      name: `%${searchString}%${OPERATOR_DELIMITER}like`,
    };
    getFilteredUsers(newFilterOptions);
  };

  const removeFilter = (k) => {
    let state = { ...filterOptions };
    delete state[k];
    setFilterOptions(state);
  };

  const downloadUserDetails = async () => {
    try {
      const newFilterOptions = {
        ...filterOptions,
        name: `%${searchString}%${OPERATOR_DELIMITER}like`,
        page: page + 1,
        size: rowsPerPage,
      };
      const optionString = getFilterString(newFilterOptions);
      setDownloadingFile(true);
      const res = await agent.User.downloadUserReport(optionString);
      const fileName = `Course Student Overviews ${moment().format(
        'Do MMM YY, hh:mm a'
      )}.csv`;
      await convertToLink(res.data, true, fileName);
      setDownloadingFile(false);
    } catch (err) {
      console.log(err, err.response);
      if (err && err.response && err.response.status === 404) {
        toast.error('No User present for download');
      } else {
        toast.error(ERROR_MSG);
      }
      setDownloadingFile(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="course-students">
        <div className="flex items-center justify-between">
          <Link to={`${pathNames.COURSE_DETAILS}?CourseId=${course.id}`}>
            <h2 className="block  mb3 mt3">Students in {course.name}</h2>
          </Link>
          <button className="primary-btn" onClick={downloadUserDetails}>
            {downloadingFile ? 'Downloading...' : 'Download CSV'}
          </button>
        </div>
        <div className="header">
          <div className="flex items-center w-100">
            <div className="flex ba br2 w-60">
              <select
                style={{ border: 'none' }}
                className="f6 bg-black-10 pr3"
                value={searchByOption}
                onChange={(e) => setSearchByOption(e.target.value)}
              >
                <option value="name">Name</option>
                <option value="email">Email</option>
                <option value="mobileNo">Mobile No</option>
              </select>
              <input
                className="pa2 br2 f6 w-100"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder={`Search User `}
                style={{ border: 'none' }}
              />
            </div>
          </div>
          <CourseStudentsFilter
            filterKeyToString={filterKeyToString}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            removeFilter={removeFilter}
            courseAssignments={courseAssignments}
          />
        </div>
        <div className="flex items-center justify-between pl2 pr2 bt mt2">
          <div className="flex w-60 flex-wrap mt3 mb3">
            {Object.keys(filterOptions).map(
              (k) =>
                k !== 'RoleId' && (
                  <button
                    onClick={() => removeFilter(k)}
                    className="ba br2 pa1 mt2 mr3 f6 flex items-center "
                  >
                    {k === 'currentAssignment' || k === 'lastAssignment' ? (
                      <p className="mr2 f6">
                        {filterKeyToString[k]} :{' '}
                        {`${
                          courseAssignments.find(
                            (a) => Number(a.id) === Number(filterOptions[k])
                          ).name
                        }`}
                      </p>
                    ) : (
                      <p className="mr2 f6">
                        {filterKeyToString[k]} : {`${filterOptions[k]}`}{' '}
                      </p>
                    )}

                    <p className="b">X</p>
                  </button>
                )
            )}
          </div>

          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div className="course-overview">
          <div className="table-div">
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th rowSpan={2}>Name</th>
                  <th rowSpan={2}>Email</th>
                  {/* <th rowSpan={2}>Current Assignment</th>
                  <th rowSpan={2}>Last Assignment</th> */}
                  <th rowSpan={2}>Points</th>
                  <th rowSpan={2}>In Course(days)</th>
                  <th rowSpan={2}>Strikes</th>
                  {/* <th className="bl br" colSpan={3}>
                    {' '}
                    Task Submitted in last
                  </th>
                  <th className="bl br" colSpan={2}>
                    No. of pending review
                  </th> */}

                  <th rowSpan={2}>Course Status</th>
                </tr>
                {/* <tr>
                  <th className="bl"> 7 days</th>
                  <th> 3 days</th>
                  <th className="br"> 1 days</th>
                  <th className="bl">Tasks</th>
                  <th className=" br">Assignments</th>
                </tr> */}
              </thead>
              <tbody>
                {courseStudents &&
                  courseStudents.map((student, i) => (
                    <tr key={i}>
                      {/* <td>{student.rank}</td> */}
                      <td>{i + 1}</td>
                      <td>
                        <Link
                          type="button"
                          target="_blank"
                          className="btn"
                          to={`${pathNames.USER_DETAILS}?UserId=${student.UserId}&&CourseId=${course.id}`}
                        >
                          {student.User.name}
                        </Link>
                      </td>
                      <td>{student.User.email}</td>

                      {/* <td>
                        {student.currentAssignment
                          ? student.currentAssignment.name
                          : 'No Assignment'}
                      </td>
                      <td>
                        {student.lastAssignment
                          ? student.lastAssignment.name
                          : 'No Assignment'}
                      </td> */}
                      <td>{student.User.totalPoints}</td>
                      <td>
                        {moment(new Date()).diff(student.createdAt, 'days')}
                      </td>
                      {/* <td>{student.last7days}</td>
                      <td>{student.last3days}</td>
                      <td>{student.last1day}</td>
                      <td>{student.reviewPendingTasks}</td>
                      <td>{student.reviewPendingAssignment}</td> */}
                      <td>{student?.User?.UserDetail?.strikes ?? 0}</td>
                      <td className={student.isCompleted ? '' : 'danger'}>
                        {student.isCompleted ? 'Completed' : 'Not Completed'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}
