import React, { useState } from 'react';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';

function AppointmentRatingBtn({ onClick, rating }) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleOnConfirm = async () => {
    await onClick(rating.text);
    toggleConfirmationDialog();
  };

  const toggleConfirmationDialog = () => {
    setShowConfirmationDialog((prev) => !prev);
  };

  return (
    <>
      <button
        className="bg-gray-100 w-24 h-24 rounded hover:bg-green-300 transition-all"
        onClick={toggleConfirmationDialog}
      >
        <div>
          <p className="text-5xl">{rating.emoji}</p>
          <p className="text-2xl">{rating.text}</p>
        </div>
      </button>

      <ConfirmationDialog
        title="Confirm Add Feedback"
        message="Once you add the feedback you cannot change that"
        confirmButtonTitle="Confirm"
        cancelButtonTitle="Cancel"
        onConfirm={handleOnConfirm}
        open={showConfirmationDialog}
        onCancel={toggleConfirmationDialog}
      />
    </>
  );
}

export default AppointmentRatingBtn;
