export const amplitudeEventProperties = {
  error: {
    response: {
      status: 'Error status',
      data: {
        data: 'Error data',
        message: 'Error message',
      },
    },
  },
  answer: {
    id: 'Answer ID',
    hasDownVoted: 'Answer has a downvote',
    hasUpVoted: 'Answer has a upvote',
    QuestionId: 'Question ID',
    createdAt: 'Answer created date',
    updatedAt: 'Answer updated date',
  },
  question: {
    createdAt: 'Question created date',
    hasUpVoted: 'Question has an upvote',
    id: 'Question ID',
    status: 'Question status',
    updatedAt: 'Question updated date',
    Assignment: {
      id: 'Question Assignment ID',
      name: 'Assignment name',
      CourseId: 'Course ID',
    },
    AssignmentTask: { id: 'Task ID', name: 'Task name' },
    Course: { id: 'Course ID', name: 'Course name' },
  },
  blog: {
    createdAt: 'Blog created date',
    link: 'Blog link',
    title: 'Blog title',
  },
  event: {
    createdAt: 'Event created date',
    link: 'Event link',
    name: 'Event name',
    summary: 'Event title',
    type: 'Event type',
  },
  assignment: {
    id: 'Assignment ID',
    name: 'Assignment name',
    points: 'Assignment points',
    isTimeBounded: 'Assignment is Time Bound',
    maximumTime: 'Assignment maximum time',
    status: 'Assignment status',
    courseType: 'Course type',
    assignmentIndex: 'Assignment index',
    CourseId: 'Course ID',
    isFreeTrialAssignment: 'Is assignment pre-qualification',
    isCompletedAllAttempts: 'All attempts completed',
    isPro: 'Is Pro',
    isScoreSubmitted: 'Is score submitted',
    isTest: 'Is test',
    completedTasks: 'Tasks completed in assignment',
    createdAt: 'Assignment created date',
    totalTask: 'Assignment total tasks',
    attemptCount: 'Count of attempts',
    attemptsRemaining: 'Attempts remaining in assignment',
    tryAgain: 'Trying again assignment',
    assignmentStatus: {
      AssignmentId: 'Assignment ID',
      AttemptId: 'Assignment Attempt ID',
      startDateTime: 'Assignment status start date',
      endDateTime: 'Assignment status end date',
      isCompleted: 'Assignment is completed',
      isMainAttempt: 'Assignment is main attempt',
      isReviewed: 'Assignment is reviewed',
      numberOfRulesBroken: 'Count of rules broken',
      pointsAwarded: 'Points awarded',
      scorePercentage: 'Score percentage',
      submissionDate: 'Assignment Submission date',
      createdAt: 'Assignment status created date',
      updatedAt: 'Assignment status updated date',
    },
  },
  class: {
    meetingLink: 'Meeting link of class',
    startDateTime: 'Class start date',
    startTime: 'Class start time',
    type: 'Class type',
    joinedLate: 'Joined late for class',
    User: {
      id: 'User ID',
      email: 'User email',
    },
  },
  spiritGuide: {
    Team: 'Team',
    AllotedTeam: 'Alloted team',
    MembersCount: 'Count of members',
    AllotedMembersCount: 'Count of alloted members',
    PreviousMembersCount: 'Previous members count',
    Guide: {
      email: 'Leader email',
      id: 'Leader ID',
      mobileNo: 'Guide mobile number',
      name: 'Leader name',
      passingYear: 'Guide passout year',
      totalPoints: 'Guide total points',
    },
    User: {
      id: 'User ID',
      email: 'User email',
      DegreeCategory: 'User degree category',
      whatsappNumber: 'Whatsapp number',
    },
    ClickedBy: {
      email: 'Clicked by email',
    },
    ClickedOnLeader: {
      email: 'Leader email',
    },
    ClickedOnMember: {
      email: 'Member email',
    },
    team: {
      team: {
        name: 'Team name',
        leader: {
          email: 'Team leader email',
          name: 'Team leader name',
          UserDetail: {
            currentAssignment: {
              name: 'Team leader current assignment name',
            },
            currentCourse: {
              name: 'Team leader current course name',
            },
            DegreeCategory: {
              name: 'Team leader current degree category',
            },
          },
        },
        teamMembers: 'Members in the team',
      },
    },
  },
  task: {
    id: 'Task ID',
    name: 'Task name',
    points: 'Task points',
    status: 'Task status',
    taskIndex: 'Task index',
    TaskTestCases: 'Task test cases',
    type: 'Task type',
    taskStatus: {
      isCompleted: 'Task is completed',
      isReviewed: 'Is task reviewed',
      pointsAwarded: 'Task points awarded',
      result: 'Task result',
    },
    selectedLanguage: 'Coding language selected',
    AssignmentId: 'Assignment ID',
    isRapidAPI: 'Is Rapid API used',
    totalResult: 'Total test cases',
    passedTestCases: 'Test cases passed',
  },
  hint: {
    isLocked: 'Hint is locked',
    pointsToRedeem: 'Hint points needed to redeem',
  },
  isa: {
    userId: 'User ID',
    isaCutOffPoints: 'ISA Cutoff points',
    isaEndDate: 'ISA End date',
    isaStartDate: 'ISA Start date',
    isaStatus: 'ISA status',
    isaExpiry: 'ISA expiry',
    studentIsaDetails: {
      isaDetails: {
        email: 'Email in ISA',
        College: {
          name: 'College name in ISA',
        },
        Deegree: {
          name: 'Degree name in ISA',
        },
      },
      alreadyPlaced: 'ISA student already placed',
    },
    userIsaDetails: {
      alreadyPlaced: 'ISA student already placed',
      currentCTC: 'ISA CTC of student',
      email: 'Email in ISA',
      isaStatus: 'ISA status',
      mobileNo: 'ISA Mobile number',
      name: 'Name in ISA',
    },
    daysExtended: 'Number of days ISA Extended',
    alreadyPlaced: 'Is student already placed',
    companyName: 'ISA Company Name',
    currentCTC: 'Current CTC',
  },
  login: {
    emailOrMobile: 'User Email or Mobile number',
    User: {
      id: 'User ID',
      email: 'User email',
    },
  },
  sourceHook: 'Source hook',
  user: {
    id: 'User ID',
    email: 'Email ID',
    mobileNo: 'Mobile number',
    whatsappNumber: 'Whatsapp number',
    mobileNumberStatus: 'Mobile number status',
    totalPoints: 'Total points',
    userStatus: 'User status',
    isaStatus: 'ISA status',
    isaCutOffPoints: 'ISA Cutoff points',
    isaEndDate: 'ISA End date',
    isaStartDate: 'ISA Start date',
    isProUser: 'Is the user Pro user',
    lastActivity: 'Last activity time',
    onHold: 'Is user on hold',
    OwnerId: 'Owner ID',
    createdAt: 'Registered date',
    College: {
      name: 'College name',
    },
    Deegree: {
      name: 'Degree name',
    },
    Plan: {
      id: 'Plan ID',
      title: 'Plan name',
      enableISA: 'Plan has ISA enabled',
      enableBilling: 'Plan has billing enabled',
      isaQualificationPeriod: 'Plan Qualification Period',
      isaQualificationPoints: 'Plan Qualification Points',
      createdAt: 'Plan created date',
    },
    Role: {
      id: 'Role ID',
      name: 'Role name',
    },
    UserDetail: {
      isCurrentlyStudying: 'Is user currently studying',
      DegreeCategoryId: 'Degree category of the user',
      hasWorkExperience: 'Do user has work experience',
      codingExperience: 'Coding experience of user',
      nonCodingExperience: 'Non-coding experience of user',
      currentTrack: {
        id: 'Current Track ID',
        title: 'Track name',
      },
      currentCourse: {
        id: 'Current Course ID',
        name: 'Current course name',
      },
      currentAssignment: {
        id: 'Current Assignment ID',
        name: 'Current assignment name',
      },
    },
    billingDate: 'Billing date',
    currentSalary: 'Current CTC',
    fees: 'Fees',
    HoldType: {
      type: 'Hold type',
    },
    noticePeriod: 'Notice period',
    onHoldReason: 'On Hold reason',
    owner: {
      email: 'Owner email',
      mobileNo: 'Owner mobile number',
      name: 'Owner name',
      Role: {
        name: 'Owner role',
      },
    },
    Resume: {
      name: 'Resume',
    },
    UserCRM: {
      note: 'CRM note',
      leadStatus: 'Lead status',
      rejectionReason: 'Rejection reason',
    },
    branch: 'Education branch',
    chatUserId: 'Chat user ID',
    name: 'Name',
    passingYear: 'Graduation year',
    dateOfBirth: 'Date Of Birth',
    proPlan: 'Pro plan name',
    proStatus: 'Pro status',
    SpiritGuideTeam: {
      name: `Guide's team name`,
      membersCount: `Guide's team member count`,
    },
  },
  sharpenerPro: {
    proDetails: {
      proStatus: 'User Sharpener Pro status',
      proPlan: 'User Sharpener Pro plan',
      isEligible: 'Is user eligible for Sharpener Pro',
      eligiblePlan: {
        planName: 'Eligible Plan for Sharpener Pro',
      },
      isCompletedSharpenerProAssignments:
        'User completed Sharpener Pro assignments',
      proScore: 'Sharpener Pro score of user',
      maxProScore: 'Max pro score for Sharpener Pro',
      eligibilityUnlockEndDate: 'Sharpener Pro eligibility unlock end date',
      isEligibilityUnlockPeriodOver: 'Is Sharpener Pro Eligibility period over',
    },
    sourceHook: 'Source hook',
    User: {
      id: 'User ID',
      email: 'User email',
      mobileNo: 'Mobile number',
    },
    outcome: {
      isEligible: 'Is user eligible for Sharpener Pro',
      plan: 'Eligible Plan for Sharpener Pro',
      proBeansAchieved: 'Pro Score achieved',
    },
  },
  progressGraph: {
    days: 'Progress graph changed days',
  },
  route: {
    newRoute: 'New route',
    prevRoute: 'Previous route',
  },
  jobOpeningAmplitudeEvent: {
    numberOfCompanies: 'Count of companies',
    action: 'Action',
    timeLeft: 'Time left',
    jobOpening: {
      companyName: 'Company name',
      id: 'Job opening id',
    },
    activeInterviews: [
      {
        ArrayCustomName: 'Active interviews',
        status: 'Application status',
        companyName: 'Company name',
        id: 'Job opening id',
        date: 'Scheduled date',
      },
    ],
    interviewLimit: 'Interview limit',
  },
};

export const userProperties = {
  id: 'id',
  email: 'Email ID',
  name: 'Name',
  branch: 'Education branch',
  passingYear: 'Graduation year',
  mobileNo: 'Mobile number',
  whatsappNumber: 'Whatsapp number',
  totalPoints: 'Total points',
  chatUserId: 'Chat user ID',
  createdAt: 'Registered date',
  isaEndDate: 'ISA last date',
  isaStartDate: 'ISA start date',
  isaStatus: 'ISA status',
  proStatus: 'Pro status',
  proPlan: 'Pro plan name',
  userStatus: 'User status',
  readyForPlacements: 'Ready for placements',
  placed: 'Placed',
  Role: {
    name: 'Role name',
  },
  College: {
    name: 'College name',
  },
  Deegree: {
    name: 'Degree name',
  },
  owner: {
    name: 'Lead owner',
  },
  Plan: {
    title: 'Plan name',
  },
  SpiritGuideTeam: {
    name: `Guide's team name`,
    membersCount: `Guide's team member count`,
  },
  UserCRM: {
    note: 'CRM note',
  },
  UserDetail: {
    isCurrentlyStudying: 'Is user currently studying',
    DegreeCategoryId: 'Degree category of the user',
    hasWorkExperience: 'Do user has work experience',
    codingExperience: 'Coding experience of user',
    nonCodingExperience: 'Non-coding experience of user',
    currentAssignment: {
      name: 'Current assignment name',
    },
    currentCourse: {
      name: 'Current course name',
    },
  },
};
