import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TextInput from '../../common/components/TextInput';
import Loader from '../../common/components/Loader';
import agent from '../../agent';
import {
  assignmentStatus,
  AssignmentType,
  colors,
  ERROR_MSG,
  taskStatus,
} from '../../constants/otherConstants';
import { GET_COURSE_ASSIGNMENTS } from '../../constants/actionTypes';

import CreateTask from './CreateTask';
import {
  getParameterByName,
  reactQuillModules,
  reactQuillFormats,
} from '../../common/functions';
import './Courses.scss';
import { toast } from 'react-toastify';
import CreateQuestionBank from './CreateQuestionBank';

const CreateAssignment = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [assignment, setAssignment] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [showTask, setShowTasks] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const [filter, setFilter] = useState(taskStatus.LIVE);

  const courseAssignments = useSelector(
    (state) => state.CoursesReducers.courseAssignments
  );
  const dispatch = useDispatch();
  const courseId = getParameterByName('CourseId');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTaskDialogOpen = () => {
    setShowTasks(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTaskDialogClose = () => {
    setShowTasks(false);
  };

  useEffect(() => {
    if (props.isUpdate) {
      setIsUpdate(props.isUpdate);
    }
  }, []);

  useEffect(() => {
    if (props.assignment) {
      setAssignment(props.assignment);
    }
  }, [props.assignment]);

  useEffect(() => {
    if (!showTask) return;

    getFilteredTasks();
  }, [filter, showTask]);

  const getFilteredTasks = () => {
    setLoading(true);
    const query = `?status=${filter}&&AssignmentId=${assignment.id}`;
    agent.Assignments.getAllTasks(query)
      .then((res) => {
        const newAssignment = { ...assignment, AssignmentTasks: res.data.data };

        const newAssignments = courseAssignments?.map((c) => {
          if (c.id === newAssignment.id) {
            return newAssignment;
          }
          return c;
        });
        dispatch({
          type: GET_COURSE_ASSIGNMENTS,
          payload: newAssignments,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
      });
  };

  const handleDescriptionChange = (value) => {
    setAssignment({ ...assignment, description: value });
  };

  const createAssignment = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    let tempAllAssignments;

    try {
      if (isUpdate) {
        const res = await agent.Assignments.updateAssignment(
          assignment,
          assignment.id
        );
        tempAllAssignments = courseAssignments?.map((a) => {
          if (a.id === assignment.id) {
            return {
              ...assignment,
              ...res.data.data,
            };
          }
          return a;
        });
      } else {
        const res = await agent.Assignments.createAssignment({
          ...assignment,
          assignmentIndex: props.index,
          CourseId: courseId,
        });
        tempAllAssignments = [
          ...courseAssignments,
          { ...res.data.data, AssignmentTasks: [] },
        ];
        setAssignment({ description: '' });
      }
      dispatch({
        type: GET_COURSE_ASSIGNMENTS,
        payload: tempAllAssignments,
      });
      setOpen(false);
    } catch (err) {
      toast.error(ERROR_MSG);
    }
    setLoading(false);
  };

  const updateQuestionBank = (newQuestionBank, index) => {
    const updatedQuestionBank = assignment.AssignmentQuestionBanks.map(
      (question, i) => {
        if (index === i) {
          return newQuestionBank;
        }
        return question;
      }
    );
    setAssignment({
      ...assignment,
      AssignmentQuestionBanks: updatedQuestionBank,
    });
  };

  const deleteQuestionBank = (newQuestionBank, index) => {
    const updatedQuestionBank = assignment.AssignmentQuestionBanks.filter(
      (question, i) => index !== i
    );
    setAssignment({
      ...assignment,
      AssignmentQuestionBanks: updatedQuestionBank,
    });
  };

  const SimpleDialog = () => {
    const { name, description, points, videoUrl } = assignment;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth>
        <DialogTitle>Create Assignment</DialogTitle>
        <DialogContent>
          <form onSubmit={createAssignment} className="create-assignmnet-form">
            <TextInput
              label="Title"
              value={name}
              required
              onTextChange={(value) =>
                setAssignment({ ...assignment, name: value })
              }
              disabled
            />
            {/* <TextInput label="Description" value={description} required onTextChange={(value) => setAssignment({ ...assignment, description: value })} /> */}
            <TextInput
              label="Points"
              value={points}
              type="number"
              required
              disabled
              onTextChange={(value) =>
                setAssignment({ ...assignment, points: value })
              }
            />
            <TextInput
              label="Primary video url"
              type="text"
              value={videoUrl}
              onTextChange={(value) =>
                setAssignment({ ...assignment, videoUrl: value })
              }
              disabled
            />
            <div className="flex items-center justify-between mt2 mb2">
              <TextInput
                label="Test Maximum Time"
                type="number"
                required
                className="w-50 mr2"
                value={assignment.maximumTime}
                onTextChange={(value) =>
                  setAssignment({ ...assignment, maximumTime: value })
                }
                disabled
              />
              <select
                className="w-50 pa2 br1"
                onChange={(e) =>
                  setAssignment({ ...assignment, unitTime: e.target.value })
                }
                value={assignment.unitTime}
                required
                disabled
              >
                <option value={''}> Select type </option>
                <option value="Minutes">Minutes </option>
                <option value="Hours"> Hours </option>
                <option value="Days"> Days</option>
              </select>
            </div>

            <div className="flex items-center">
              <p className="w-50">Assignment Type:</p>
              <select
                onChange={(e) =>
                  setAssignment({ ...assignment, type: Number(e.target.value) })
                }
                value={assignment.type}
                required
                className="w-50 pa2 br1"
                disabled
              >
                <option value={''}> Select type </option>
                {Object.keys(AssignmentType).map((type) => (
                  <option value={AssignmentType[type]}>{type}</option>
                ))}
              </select>
            </div>
            {(assignment.type === AssignmentType.Test ||
              assignment.type === AssignmentType.ProTest ||
              assignment.type === AssignmentType.Interview) && (
              <div>
                <p className="b">Question Bank:</p>
                {assignment.AssignmentQuestionBanks &&
                  assignment.AssignmentQuestionBanks.map((q, i) => (
                    <CreateQuestionBank
                      isUpdate={true}
                      key={q.id}
                      questionBank={q}
                      onUpdate={updateQuestionBank}
                      onDelete={deleteQuestionBank}
                      index={i}
                      addQuestionBank={(questionBank) =>
                        setAssignment({
                          ...assignment,
                          AssignmentQuestionBanks: [
                            ...assignment.AssignmentQuestionBanks,
                            questionBank,
                          ],
                        })
                      }
                    />
                  ))}
                <CreateQuestionBank
                  addQuestionBank={(questionBank) =>
                    setAssignment({
                      ...assignment,
                      AssignmentQuestionBanks: [
                        ...assignment.AssignmentQuestionBanks,
                        questionBank,
                      ],
                    })
                  }
                />
              </div>
            )}
            <p className="mt2 mb2">Assignment Description:</p>
            <ReactQuill
              defaultValue={description}
              onChange={handleDescriptionChange}
              modules={reactQuillModules}
              formats={reactQuillFormats}
              className="mb2"
              readOnly
            />
            <div className="flex items-center">
              <p className="w-50">Skip users started the assignment:</p>
              <select
                onChange={(e) =>
                  setAssignment({
                    ...assignment,
                    skipUserAssignmentId: Number(e.target.value),
                  })
                }
                value={assignment.skipUserAssignmentId}
                className="w-50 pa2 br1"
                disabled
              >
                <option value={''}> Select Assignment </option>
                {courseAssignments?.map((a) => (
                  <option value={a.id}>{a.name}</option>
                ))}
              </select>
            </div>

            {/* {isUpdate && (
              <div className="image-edit-div">
                <p>Assignment Icon: </p>
                <div
                  onMouseEnter={() => setIsEditImage(true)}
                  onMouseLeave={() => setIsEditImage(false)}
                >
                  {isEditImage ? (
                    <input
                      type="file"
                      accept="images/*"
                      className="w-30"
                      src={assignment.icon}
                      onChange={(e) => uploadAssignmentImage(e.target.files[0])}
                    />
                  ) : assignment.icon ? (
                    <img src={assignment.icon} />
                  ) : (
                    <small>Hover to add image</small>
                  )}
                </div>
                <div className=" w-20">
                  <button
                    className="primary-btn pa2"
                    onClick={() => deleteAssignmentImage()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )} */}
            {/* <div className="btns-div">
              <button type="submit">
                {' '}
                {isUpdate ? 'Update Assignment' : 'Create Assignment'}
              </button>
              {isUpdate && (
                <button
                  type="button"
                  className="red-btn"
                  onClick={() => changeStatusOfAssignment()}
                >
                  {assignment.status == assignmentStatus.LIVE
                    ? 'Archive Assignment'
                    : 'Unarchive Assignment'}
                </button>
              )}
            </div> */}
          </form>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  const TasksDialog = () => {
    const { AssignmentTasks, id, name } = assignment;

    return (
      <Dialog
        onClose={handleTaskDialogClose}
        open={showTask}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Assignment Tasks: {name}</DialogTitle>
        <DialogContent>
          <div>
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value={taskStatus.LIVE}>Live</option>
              <option value={taskStatus.ARCHIVE}>Archive</option>
            </select>
          </div>
          <CreateTask
            isUpdate={false}
            AssignmentId={id}
            assignment={assignment}
            moveToArchiveTab={() => setFilter(taskStatus.ARCHIVE)}
          />

          <table>
            <tbody>
              <tr>
                <th>Index</th>
                <th>Name</th>
                <th>Points</th>
                <th>Maximum Time</th>
                <th>Type</th>
                <th>Is Question Locked?</th>
                <th>Hints</th>
              </tr>
              {AssignmentTasks &&
                AssignmentTasks.map((task) => (
                  <CreateTask
                    isUpdate={true}
                    task={task}
                    key={task.id}
                    AssignmentId={id}
                    assignment={assignment}
                  />
                ))}
            </tbody>
          </table>
          {loading && <Loader />}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {isUpdate ? (
        <div className="flex">
          <button type="button" onClick={handleClickOpen}>
            <EditIcon style={{ fill: colors.light.secondaryColor }} />
          </button>
          {assignment.type !== AssignmentType.Interview && (
            <button
              type="button"
              className="secondary-btn pa2 f6 br2 "
              onClick={handleTaskDialogOpen}
            >
              See tasks
            </button>
          )}
        </div>
      ) : (
        // <button
        //   type="button"
        //   onClick={handleClickOpen}
        //   className="primary-btn pa2 f6 br2 mr2"
        // >
        //   Create Assignment
        // </button>
        <></>
      )}

      {SimpleDialog()}
      {TasksDialog()}
    </>
  );
};

export default CreateAssignment;
