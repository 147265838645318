import React, { useState, useEffect } from 'react';
import './TaskQuestions.scss';
import agent from '../../agent';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import { taskQuestionTypes } from '../../constants/otherConstants';
import { toast } from 'react-toastify';

let timeout = null;
function TaskQuestionAnswer({
  type,
  onAnswerAdd,
  question,
  answer: propsAnswer,
  onAnswerDelete,
  createNewAnswer,
  answerIndex,
  onAnswerUpdate,
  isEditing: propsIsEditing,
}) {
  const [answer, setAnswer] = useState(propsAnswer || {});
  const [isEditing, setIsEditing] = useState(propsIsEditing);

  const addAnswer = (newAnswer) => {
    if (!newAnswer.answer) {
      toast.error('Please add valid text in answer');
      return;
    }

    if (!createNewAnswer) {
      onAnswerUpdate(newAnswer);
    } else {
      onAnswerAdd({ ...newAnswer, index: answerIndex });
      setAnswer({ answer: '' });
    }
  };

  const deleteAnswer = async () => {
    if (!createNewAnswer) {
      // make an api call to delete the answer.
      try {
        await agent.Assignments.deleteTaskQuestionAnswer({
          id: answer.id,
        });
        onAnswerDelete(answerIndex);
      } catch (err) {
        console.log(err);
      }
    } else {
      onAnswerDelete(answerIndex);
    }
  };

  useEffect(() => {
    setIsEditing(propsIsEditing);
  }, [propsIsEditing]);

  useEffect(() => {
    if (propsAnswer) {
      setAnswer(propsAnswer);
    }
  }, [propsAnswer]);

  const onAnswerTextChange = (e) => {
    const newAnswer = { ...answer, answer: e.target.value };
    setAnswer(newAnswer);
    if (createNewAnswer) {
      return;
    }

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      addAnswer(newAnswer);
    }, 1000);
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      addAnswer(answer);
    }
  };

  return (
    <div className="task-question-answer">
      <div className="flex items-center mb2">
        {taskQuestionTypes[type] === taskQuestionTypes[1] ? (
          <input
            type="radio"
            defaultChecked={answer.isCorrect}
            name={question.questionText}
            value={answerIndex}
            disabled={!isEditing}
          />
        ) : (
          <input
            type="checkbox"
            defaultChecked={answer.isCorrect}
            name={question.questionText}
            value={answerIndex}
            disabled={!isEditing}
          />
        )}
        {isEditing ? (
          <>
            <input
              placeholder="Answer Text"
              className="ml2 mr2"
              onKeyDown={handleKeypress}
              onChange={onAnswerTextChange}
              value={answer.answer}
            />

            {createNewAnswer ? (
              <button onClick={addAnswer}>
                <DoneIcon />
              </button>
            ) : (
              <>
                <button onClick={deleteAnswer}>
                  <DeleteForeverIcon className="red" />
                </button>
                {/* <button onClick={() => setIsEditing(false)}>
                    <CloseIcon className="red" />
                  </button> */}
              </>
            )}
          </>
        ) : (
          <>
            <p className="ml2 mr2"> {answer.answer} </p>
          </>
        )}
      </div>
    </div>
  );
}
export default TaskQuestionAnswer;
