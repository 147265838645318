import React, { useEffect, useState } from 'react';
import agent from '../../agent';
import {
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  Paper,
  TableBody,
} from '@mui/material';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../constants/helperFunctions';
import Loader from '../../common/components/Loader';
import Team from './Team';

function TeamList({ teamType }) {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const query = `?teamType=${teamType}`;
        const response = await agent.Team.getAllTeams(query);
        setTeams(response.data.data);
        setLoading(false);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    })();
  }, [teamType]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <p style={{ marginBottom: '25px' }} className=" b text-right">
        There are {teams.length} members in your team.
      </p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="b"> id</p>
              </TableCell>
              <TableCell>
                <p className="b"> Name</p>
              </TableCell>
              <TableCell>
                <p className="b"> Email</p>
              </TableCell>
              <TableCell>
                <p className="b"> Mobile Number</p>
              </TableCell>
              <TableCell>
                <p className="b"> Whatsapp Number</p>
              </TableCell>
              <TableCell>
                <p className="b">Tech stack</p>
              </TableCell>
              <TableCell>
                <p className="b text-center"> Actions</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <Team key={team.id} team={team} setTeams={setTeams} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {teams.length === 0 && (
        <p className="mt4 b text-center">No team member found</p>
      )}
    </div>
  );
}

export default TeamList;
