import React, { useState } from 'react';
import {
  FaWhatsappSquare,
  FaPhoneSquare,
  FaEnvelope,
  FaCalendarAlt,
  FaClock,
  FaSave,
  FaEye,
  FaUpload,
  FaHistory,
} from 'react-icons/fa';
import moment from 'moment';
import appointmentIcon from '../../assets/Images/interview-icon.png';
import './Appointments.scss';
import {
  AppointmentFeedbackStatus,
  InterviewStatus,
  appointmentTypeColors,
  appointmentTypes,
  weekDays,
} from '../../constants/otherConstants';
import { SiGooglemeet } from 'react-icons/si';
import { toast } from 'react-toastify';
import { Close } from '@material-ui/icons';
import { getErrorMessage } from '../../constants/helperFunctions';
import agent from '../../agent';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import { useHistory } from 'react-router';
import { Chip } from '@material-ui/core';
import IconButton from '../../common/components/IconButton';
import { pathNames } from '../../constants/navigationPath';

function Appointment({ appointment, handleChangeStatus, setAppointments }) {
  const history = useHistory();
  const [showUploadMeetingURLInput, setShowUploadMeetingURLInput] =
    useState(false);

  const [meetingRecordingURL, setMeetingRecordingURL] = useState(
    appointment?.meetingRecordingURL ?? ''
  );

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  /**
   * Opens the meeting link in a new tab
   * @param {string} meetingLink The meeting link to be opened
   */
  const joinMeeting = async () => {
    if (!appointment?.meetingLink) {
      return toast.error("This appointment doesn't have meeting link.");
    }
    window.open(appointment?.meetingLink, '_blank');
  };

  /**
   * Toggle the visibility of the input to update meeting URL
   */
  const toggleUploadMeetingURLInput = () => {
    setShowUploadMeetingURLInput((prev) => !prev);
  };

  const uploadMeetingRecordingURL = async () => {
    if (!meetingRecordingURL) {
      return toast.error('Enter meeting recording url');
    }

    try {
      await agent.Appointments.updateMeetingRecordingURL({
        meetingRecordingURL,
        id: appointment?.id,
      });

      setAppointments((prev) => {
        return prev.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              meetingRecordingURL,
            };
          }
          return item;
        });
      });

      toast.success('Meeting recording url updated');
      toggleUploadMeetingURLInput();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const startTheAppointment = async () => {
    try {
      if (!appointment) {
        return toast.error('Appointment not found');
      }

      if (!appointment.meetingLink) {
        return toast.error("This appointment doesn't have meeting link.");
      }

      // Start the appointment
      await agent.Appointments.startAppointment({
        AppointmentId: appointment?.id,
      });

      setAppointments((prev) => {
        return prev.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              appointmentStatus: InterviewStatus.Started,
            };
          }
          return item;
        });
      });

      setShowConfirmationDialog(false);

      // Opening appointment report page
      window.open(
        pathNames.APPOINTMENT_REPORT.replace(':appointmentId', appointment?.id),
        '_blank'
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const navigateToMockHistory = () => {
    window.open(
      pathNames.MOCK_HISTORY.replace(':userId', appointment?.User.id),
      '_blank'
    );
  };

  const { Assignment } = appointment;

  const appointmentType =
    Assignment?.AppointmentSetup?.type || appointment?.AppointmentSetup?.type;
  const appointmentName = `${appointmentType} : ${
    Assignment?.AppointmentSetup?.title || appointment?.AppointmentSetup?.title
  }`;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="flex items-center gap-2">
            <img
              src={appointmentIcon}
              width={30}
              height={30}
              alt="Appointment Icon"
            />
            {appointment?.Assignment ? (
              <Link
                type="button"
                target="_blank"
                className="btn"
                to={`${pathNames.USER_DETAILS}?UserId=${appointment?.UserId}&&CourseId=${appointment?.Assignment?.CourseId}`}
              >
                <p className="hover:text-blue-700 transition-all">
                  {appointment?.User?.name}
                </p>
              </Link>
            ) : (
              <p className="hover:text-blue-700 transition-all">
                {appointment?.User?.name}
              </p>
            )}
            <Chip
              color={appointmentTypeColors[appointmentType]}
              size="small"
              label={appointmentName}
            />
          </div>

          <div className="flex justify-center items-center gap-3">
            {(appointment?.appointmentStatus === InterviewStatus.Started ||
              appointment?.appointmentStatus === InterviewStatus.Completed) && (
              <button>
                <Link
                  to={pathNames.APPOINTMENT_REPORT.replace(
                    ':appointmentId',
                    appointment?.id
                  )}
                  type="button"
                  target="_blank"
                >
                  <p className="report-btn">
                    {appointment?.appointmentStatus ===
                    InterviewStatus.Completed
                      ? 'View Report'
                      : 'Questions'}
                  </p>
                </Link>
              </button>
            )}

            {appointment?.appointmentStatus !== InterviewStatus.Started &&
              appointment?.appointmentStatus !== InterviewStatus.Completed && (
                <button
                  onClick={() => setShowConfirmationDialog(true)}
                  className="join-btn"
                >
                  <p className="mr2">Start</p>
                </button>
              )}

            {appointment?.appointmentStatus !== InterviewStatus.Completed && (
              <button
                onClick={() => joinMeeting(appointment?.meetingLink)}
                className="join-btn"
              >
                <p className="mr2">Join</p> <SiGooglemeet />
              </button>
            )}

            {appointment?.appointmentStatus === InterviewStatus.Completed && (
              <p className="text-xs">
                {appointment?.meetingLink.replace(
                  'https://meet.google.com/',
                  ''
                )}
              </p>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="date-time">
            <p className="day">
              <FaCalendarAlt size="1em" color="#85c1e9" className="icon" />
              {appointment?.date &&
                moment(appointment?.date).format('DD/MM/YYYY, ')}
              {weekDays[appointment?.day]}{' '}
            </p>
            <p className="time">
              <FaClock size="1em" color="#f1948a" className="icon" />
              {moment(appointment?.time, 'HH:mm:ss').format('hh:mm a')} -{' '}
              {moment(appointment?.time, 'HH:mm:ss')
                .add(appointment?.AppointmentSetup?.slotsLength, 'minutes')
                .format('hh:mm a')}
            </p>
          </div>
          <div className="contact-info">
            <p className="whatsapp">
              <FaWhatsappSquare
                size="1.5em"
                color="mediumseagreen"
                className="icon"
              />
              {appointment?.User?.whatsappNumber}
            </p>
            <p className="phone">
              <FaPhoneSquare size="1.5em" color="royalblue" className="icon" />
              {appointment?.User?.mobileNo}
            </p>
            <p className="email">
              <FaEnvelope size="1.5em" color="royalblue" className="icon" />
              {appointment?.User?.email}
            </p>
          </div>
          <div className="action-buttons">
            <button
              onClick={() => {
                handleChangeStatus(
                  AppointmentFeedbackStatus.passed,
                  appointment
                );
              }}
              className={`${
                appointment.status ? 'disabled-button' : 'passed-button'
              }`}
              disabled={appointment.status}
            >
              Passed
            </button>
            <button
              className={`${
                appointment.status ? 'disabled-button' : 'failed-button'
              }`}
              disabled={appointment.status}
              onClick={() => {
                handleChangeStatus(
                  AppointmentFeedbackStatus.failed,
                  appointment
                );
              }}
            >
              Failed
            </button>
            <button
              className={`${
                appointment.status ? 'disabled-button' : 'absent-button'
              }`}
              disabled={appointment.status}
              onClick={() => {
                handleChangeStatus(
                  AppointmentFeedbackStatus.absent,
                  appointment
                );
              }}
            >
              Absent
            </button>

            <button
              className={`${
                appointment.status ? 'disabled-button' : 'reallot-button'
              }`}
              disabled={appointment.status}
              onClick={() => {
                handleChangeStatus(
                  AppointmentFeedbackStatus.reallot,
                  appointment
                );
              }}
            >
              Re allot
            </button>

            <button
              className={`${
                appointment.status ? 'disabled-button' : 'bg-yellow'
              }`}
              disabled={appointment.status}
              onClick={() => {
                handleChangeStatus(
                  AppointmentFeedbackStatus.warning,
                  appointment
                );
              }}
            >
              Warning
            </button>
          </div>
        </div>

        <div className=" mt-1 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <IconButton
              onClick={navigateToMockHistory}
              icon={<FaHistory className="text-base" />}
              title="Mock History"
            />
            <p>
              Status :{' '}
              {appointment?.status ? appointment.status : 'Status not given'}
            </p>
          </div>

          {appointment?.appointmentStatus === InterviewStatus.Completed && (
            <div className=" flex justify-center items-center gap-10">
              {showUploadMeetingURLInput && (
                <input
                  className="w-[300px] mr-3"
                  placeholder="Enter the recording URL"
                  type="text"
                  onChange={(e) => setMeetingRecordingURL(e.target.value)}
                  value={meetingRecordingURL}
                />
              )}
              {showUploadMeetingURLInput ? (
                <div className="flex gap-5">
                  <IconButton
                    className="text-xl"
                    onClick={uploadMeetingRecordingURL}
                    icon={<FaSave />}
                    title="Save"
                  />
                  <IconButton
                    className="text-xl"
                    onClick={toggleUploadMeetingURLInput}
                    icon={<Close />}
                    title="Close"
                  />
                </div>
              ) : (
                <IconButton
                  className="text-xl"
                  onClick={toggleUploadMeetingURLInput}
                  icon={<FaUpload />}
                  title="Upload Meeting Recording"
                />
              )}
              {appointment?.meetingRecordingURL && (
                <IconButton
                  className="text-xl"
                  onClick={() => window.open(appointment?.meetingRecordingURL)}
                  icon={<FaEye />}
                  title="View Recording"
                />
              )}
            </div>
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={showConfirmationDialog}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirm={startTheAppointment}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Start The Appointment"
        title="Start The Appointment"
        message="Once you start the appointment it cannot be cancelled. So confirm the student is joined or not before starting the appointment."
      />
    </>
  );
}

export default Appointment;
