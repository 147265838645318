import React, { useEffect, useState } from 'react';

import './Courses.scss';

import { Chip } from '@mui/material';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { toast } from 'react-toastify';
import agent from '../../agent';
import { groupBy } from '../../common/functions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COURSES } from '../../constants/actionTypes';
import Loader from '../../common/components/Loader';
import {
  ERROR_MSG,
  courseClassStatus,
  scheduledClassStatus,
} from '../../constants/otherConstants';
import { Archive, Unarchive } from '@material-ui/icons';
import { Switch } from '@material-ui/core';
const classType = [
  { name: 'Live Class' },
  { name: 'Doubt Class' },
  { name: 'Live + Doubt' },
  { name: 'Theory Class' },
];
const days = [
  {
    day: 'Monday',
  },
  {
    day: 'Tuesday',
  },
  {
    day: 'Wednesday',
  },
  {
    day: 'Thursday',
  },
  {
    day: 'Friday',
  },
  {
    day: 'Saturday',
  },
  {
    day: 'Sunday',
  },
];
const TimeComponent = ({ classTime, course, courseAssignments }) => {
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState({ day: classTime.day, assignments: [] });
  const courses = useSelector((state) => state.CoursesReducers.courses);

  const [availableAssignments, setAvailableAssignments] = useState([]);

  const dispatch = useDispatch();

  const validateClass = (time) => {
    const {
      day,
      startTime: classTime,
      type,
      assignments,
      meetingLink,
      scheduleStatus,
      postponedTime,
    } = time;
    if (!day) {
      toast.error('Day is required');
      return false;
    }

    if (!classTime) {
      toast.error('Select Class Time');
      return false;
    }
    if (!type) {
      toast.error('Select Class Type');
      return false;
    }
    if (!assignments || assignments.length === 0) {
      toast.error('Select Assignmetns');
      return false;
    }
    if (!meetingLink) {
      toast.error('Add MeetingLink');
      return false;
    }
    if (!scheduleStatus) {
      toast.error('Select Class Status');
      return false;
    }
    if (
      scheduleStatus === scheduledClassStatus.POSTPONED.text &&
      !postponedTime
    ) {
      toast.error('Select New Class Time');
      return false;
    }
    if (
      moment(postponedTime, 'HH:mm:ss').isBefore(moment(classTime, 'HH:mm:ss'))
    ) {
      toast.error('Select class time after the Current One');
      return false;
    }
    return true;
  };

  useEffect(() => {
    setAvailableAssignments(courseAssignments);
  }, [courseAssignments]);
  const addTime = async () => {
    const data = { ...time, CourseId: course.id };

    if (!validateClass(data)) {
      return;
    }
    try {
      setLoading(true);
      const res = await agent.Courses.addOrUpdateCourseClass(data);
      if (data.id) {
        const newCourses = courses?.map((c) => {
          if (c.id === course.id) {
            const newClasses = course.classes.map((cl) => {
              if (cl.id === data.id) {
                return data;
              }
              return cl;
            });
            return { ...course, classes: newClasses };
          }
          return c;
        });

        dispatch({ type: GET_COURSES, payload: newCourses });
        toast.success('Class updated');
        setShowInput(false);
      } else {
        const newCourses = courses?.map((c) => {
          if (c.id === course.id) {
            return {
              ...course,
              classes: [
                ...(course.classes || []),
                { ...data, ...res.data.data },
              ],
            };
          }
          return c;
        });
        setTime({
          day: classTime.day,
          assignments: [],
          time: null,
          meetingLink: '',
        });

        dispatch({ type: GET_COURSES, payload: newCourses });
        toast.success('Class added');
      }
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      toast.error(ERROR_MSG);
      setLoading(false);
    }
  };
  const showTime = (value) => {
    if (!value) {
      setTime({ day: classTime.day, assignments: [] });
    }
    setShowInput(value);
  };
  const editTime = (time) => {
    setTime(time);

    const availableAssignments = courseAssignments.filter(
      (ca) => !time.assignments.find((a) => a.id === ca.id)
    );
    setAvailableAssignments(availableAssignments);
    setShowInput(true);
  };
  const archiveClass = async (time) => {
    try {
      setLoading(true);
      await agent.Courses.archiveCourseClass(time.id, {
        status:
          time.status == courseClassStatus.LIVE
            ? courseClassStatus.ARCHIVE
            : courseClassStatus.LIVE,
      });

      const newCourses = courses?.map((c) => {
        if (c.id === course.id) {
          const newClasses = c.classes.filter((cl) => cl.id !== time.id);
          return { ...course, classes: newClasses };
        }
        return c;
      });
      dispatch({ type: GET_COURSES, payload: newCourses });

      toast.success(
        `Class ${
          time.status == courseClassStatus.LIVE
            ? courseClassStatus.ARCHIVE
            : courseClassStatus.LIVE
        }`
      );

      setShowInput(false);
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      toast.error(ERROR_MSG);
      setLoading(false);
    }
  };
  const addAssignment = (e) => {
    const { value } = e.target;
    if (!value) {
      return;
    }
    const assignment = availableAssignments.find((a) => a.id === Number(value));
    if (assignment) {
      setAvailableAssignments(
        availableAssignments.filter((a) => a.id !== Number(value))
      );
      const tempTime = {
        ...time,
        assignments: [...time.assignments, assignment],
      };
      setTime(tempTime);
    }
  };
  const removeAssignemnt = (assignment) => {
    const { id } = assignment;
    if (!id) {
      return;
    }
    const assignments = time.assignments.filter((a) => a.id !== id);
    if (assignment) {
      setAvailableAssignments([assignment, ...availableAssignments]);
      const tempTime = { ...time, assignments };
      setTime(tempTime);
    }
  };
  return (
    <div className="date-time-div">
      <div className="day b tc ">{classTime.day}</div>
      {showInput ? (
        <div className="time-input flex flex-column ">
          <input
            type="time"
            className="w-50 center"
            style={{ margin: '10px auto' }}
            value={time.startTime}
            onChange={(e) => setTime({ ...time, startTime: e.target.value })}
          />
          <input
            value={time.meetingLink}
            placeholder="Meeting Link"
            onChange={(e) => setTime({ ...time, meetingLink: e.target.value })}
            type="url"
          />
          <select
            value={time.scheduleStatus}
            onChange={(e) =>
              setTime({ ...time, scheduleStatus: e.target.value })
            }
          >
            <option value="">Select class status</option>
            {Object.values(scheduledClassStatus).map((status, index) => (
              <option key={index} value={status.text}>
                {status.text}
              </option>
            ))}
          </select>
          {time.scheduleStatus === scheduledClassStatus.POSTPONED.text && (
            <div className="flex flex-wrap  w-50 gap-2 items-center ">
              <label>New Timing:</label>
              <input
                type="time"
                style={{ margin: '10px' }}
                value={time?.postponedTime}
                onChange={(e) =>
                  setTime({ ...time, postponedTime: e.target.value })
                }
              />
            </div>
          )}
          <select onChange={addAssignment}>
            <option value="">Select an Assignment</option>

            {availableAssignments.map((a) => (
              <option key={a.id} value={a.id}>
                {a.name}
              </option>
            ))}
          </select>
          <div className="mt2 mb2 bb pb2">
            <b>Assignments</b>
            {time.assignments.length > 0 ? (
              time.assignments.map((assignment) => (
                <div className="flex items-center justify-between">
                  <p>{assignment.name}</p>
                  <button
                    type="button"
                    onClick={() => removeAssignemnt(assignment)}
                  >
                    <CancelIcon className="danger" />
                  </button>
                </div>
              ))
            ) : (
              <p className="danger f6">Select an assignment</p>
            )}
          </div>
          <div
            onChange={(e) => setTime({ ...time, type: e.target.value })}
            className="flex flex-column "
          >
            {classType.map((c) => (
              <div className="flex items-center" key={c.name}>
                <input
                  type="radio"
                  checked={c.name === time.type}
                  value={c.name}
                  name="class-type"
                />
                <p className="f6 ml2"> {c.name} </p>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-between">
            <button type="button" onClick={() => showTime(!showInput)}>
              <CancelIcon className="danger" />
            </button>
            <button type="button" onClick={addTime}>
              <CheckCircleIcon className="success" />
            </button>
          </div>
        </div>
      ) : (
        <div className="time flex justify-center">
          <button type="button  center" onClick={() => showTime(!showInput)}>
            <ControlPointIcon />
          </button>
        </div>
      )}
      <div className="classes">
        {classTime?.classes.map((classItem) => {
          const classStartTime =
            classItem.scheduleStatus === scheduledClassStatus.POSTPONED.text
              ? classItem.postponedTime
              : classItem.startTime;
          return (
            <div key={classItem.id} className="class">
              <div className="">
                <p> {classItem.type}:</p>
                <p>{moment(classStartTime, 'HH:mm:ss').format('hh:mm A')}</p>
                <a href={classItem.meetingLink} className="underline blue">
                  {classItem.meetingLink}
                </a>
                <div className="mt2 mb2 pb2">
                  <b>Assignments</b>
                  {classItem.assignments.length > 0 ? (
                    classItem.assignments.map((assignment) => (
                      <div className="flex items-center justify-between">
                        <p>{assignment.name}</p>
                      </div>
                    ))
                  ) : (
                    <p className="danger f6">Select an assignment</p>
                  )}
                </div>
              </div>
              <div className="actions">
                {classItem.status === courseClassStatus.LIVE ? (
                  <Archive
                    style={{ cursor: 'pointer' }}
                    onClick={() => archiveClass(classItem)}
                    className="danger"
                  />
                ) : (
                  <Unarchive
                    style={{ cursor: 'pointer' }}
                    onClick={() => archiveClass(classItem)}
                    className="primary"
                  />
                )}
                <Chip
                  label={classItem.scheduleStatus}
                  color={
                    Object.values(scheduledClassStatus).filter(
                      (status) => status.text === classItem.scheduleStatus
                    )[0]?.color
                  }
                />
                <button type="button" onClick={() => editTime(classItem)}>
                  {' '}
                  <EditIcon className="success" />{' '}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {loading && <Loader />}
    </div>
  );
};
export default function CourseTime({ course }) {
  const [courseClasses, setClasses] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);

  const [isLive, setIsLive] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const classesRes = await agent.Courses.getAllCourseClass(
          `?CourseId=${course.id}&status=${
            isLive ? courseClassStatus.LIVE : courseClassStatus.ARCHIVE
          }`
        );

        const classes = classesRes?.data?.data;

        const groupClasses = classes ? groupBy(classes, 'day') : {};
        const courseClasses = days.map((d) => ({
          ...d,
          classes: groupClasses[d.day]
            ? groupClasses[d.day].sort((a, b) =>
                moment(a.startTime, 'HH:mm:ss').subtract(
                  moment(b.startTime, 'HH:mm:ss')
                )
              )
            : [],
        }));

        setClasses(courseClasses);
        const assignmentRes = await agent.Assignments.getAllAssignments(
          `?CourseId=${course.id}`
        );
        setCourseAssignments(assignmentRes.data.data);
      } catch (err) {
        console.log(err, err.response);
      }
    })();
  }, [course, isLive]);

  return (
    <div className="course-time-div">
      <div className="flex justify-between mt3">
        <h3 className="globalSubTitle">Classes Timings:</h3>
        <div className="flex items-center gap-10">
          <p>Archive</p>
          <Switch
            checked={isLive}
            onChange={() => {
              setIsLive((p) => !p);
            }}
            size="small"
          />
          <p>Live</p>
        </div>
      </div>
      <div className="content">
        {courseClasses.map((d) => (
          <TimeComponent
            classTime={d}
            key={d.day}
            course={course}
            courseAssignments={courseAssignments}
          />
        ))}
      </div>
    </div>
  );
}
