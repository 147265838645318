import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link, useHistory } from 'react-router-dom';
import agent from '../../../agent';
import { trimAndLowercase } from '../../../constants/helperFunctions';
import { pathNames } from '../../../constants/navigationPath';
import { logo, WEB_TOKEN } from '../../../constants/otherConstants';
import '../Auth.scss';
import Content from '../Content';

let failedLogins = 0;
let allowedFailedRetries = 3;

const ForgotPassword = () => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enableResend, setEnableResend] = useState(false);
  const [message, setmessage] = useState(null);

  useEffect(() => {
    const webToken = window.localStorage.getItem(WEB_TOKEN);
    if (webToken) {
      history.push(pathNames.COURSES);
    }
  }, []);

  const sendLink = async (e) => {
    if (e) {
      e.preventDefault();
    }
    // if (showCaptch && !captchaVerified) {
    //   setError(`Please Complete the reCAPTCHA`);
    //   return;
    // }

    setLoading(true);
    setError(null);
    setmessage(null);

    let tempUser = {
      ...user,
      email: trimAndLowercase(user.email),
    };

    try {
      await agent.Auth.forgotPassword(tempUser);
      setLoading(false);
      setError(null);
      setEnableResend(true);
      setmessage('A mail has been sent to your inbox.');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message.error);
      } else {
        setError('Error occured. Please try again after some time');
      }
      setLoading(false);
    }
  };
  return (
    <>
      <div className="login-page">
        <div className="form">
          <div className="left">
            <form onSubmit={sendLink}>
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="forgot">
                <div className="head-div">
                  <div className="head-div-left">
                    <p className="login-title">Forgot Password</p>
                    <p className="sub-head">
                      Oops... Looks like you forgot something!
                    </p>
                  </div>
                </div>
                <div className="input-div">
                  <div className="forgot-input">
                    <p className="email-head">
                      Enter the email with which you have registered
                    </p>
                    <TextField
                      required
                      variant="outlined"
                      type="email"
                      className="email-in w-80"
                      InputProps={{
                        startAdornment: <EmailOutlinedIcon />,
                      }}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </div>
                  {message && (
                    <>
                      <p className="green b pa1 mt1 green">{message}</p>
                      <p className=" f5 pa1">
                        Note: It normally take a minute for the mail to arrive,
                        check spam and promotion folder for the mail.
                      </p>
                    </>
                  )}
                  {error && <p className="error f6 pa1"> {error}</p>}
                  {/* {showCaptch && (
                    <div className="flex justify-center">
                      <ReCaptcha
                        onSuccessFunc={(res) => setCaptchaVerified(res.success)}
                      />
                    </div>
                  )} */}
                  <div className="btn-div">
                    <button className="new-btn auth-btn mt4" type="submit">
                      {loading ? (
                        <CircularProgress color="white" size={20} />
                      ) : enableResend ? (
                        'Resend'
                      ) : (
                        'Send Link'
                      )}
                    </button>
                  </div>
                  <div className="dont-have-acc-footer">
                    Know your password? {''}
                    <Link
                      className="sign-up-link sharpener-gradient-text"
                      to={pathNames.LOGIN}
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Content />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
