import React, { Component } from 'react';
import 'tachyons';
import './styles.scss';
import TextInput from './TextInput';
import { Chip, MenuItem, Select, Button } from '@material-ui/core';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { assignmentTaskTagsType } from '../../constants/otherConstants';
import WorkIcon from '@mui/icons-material/Work';
import SelectInput from './SelectInput';
import { toast } from 'react-toastify';

class DropDownTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      data: [],
      tags: [],
      value: '',
      type: assignmentTaskTagsType.TOPIC,
      required: false,
      searchResult: [],
    };
    this.selectOption = this.selectOption.bind(this);
    this.handleTagTypeChange = this.handleTagTypeChange.bind(this);
  }

  componentWillMount() {
    const { label, data, value, tags, required, ...misc } = this.props;
    this.setState({
      label,
      data,
      tags: tags.map((value) => ({ tag: value.tag, type: value.type })),
      value,
      misc,
      searchResult: data,
    });
  }

  selectOption(tag) {
    const { setOption } = this.props;
    const { tags } = this.state;
    if (!tags.some((t) => t.tag === tag.tag && t.type === tag.type)) {
      this.setState((prevState) => ({
        tags: [...prevState.tags, tag],
        value: '',
      }));
      setOption([...this.state.tags, tag]);
    }
  }

  handleTagTypeChange(event) {
    this.setState({ type: event.target.value });
  }

  removeOption(tag) {
    const { setOption } = this.props;
    const { tags } = this.state;
    const updatedTags = tags.filter(
      (t) => !(t.tag === tag.tag && t.type === tag.type)
    );
    this.setState({ tags: updatedTags });
    setOption(updatedTags);
  }

  filterData(text) {
    const { data } = this.state;
    let searchResult = data.filter((c) =>
      c.tag.toLowerCase().includes(text.toLowerCase())
    );
    if (text !== '') {
      if (searchResult.length > 0 && searchResult[0].tag !== text) {
        searchResult = [{ tag: text }, ...searchResult];
      }
    }
    this.setState({ searchResult, value: text });
    this.props.onChangeText(text);
  }

  renderTags() {
    const { tags } = this.state;

    return (
      <div className="tagsContainer ">
        {tags.map((tag, index) => (
          <Chip
            color={
              tag.type === assignmentTaskTagsType.TOPIC
                ? 'primary'
                : 'secondary'
            }
            key={index}
            label={tag.tag}
            onDelete={() => this.removeOption(tag)}
            onClick={() => this.selectOption(tag)}
            icon={
              tag.type === assignmentTaskTagsType.TOPIC ? (
                <AssignmentIcon fontSize="small" />
              ) : (
                <WorkIcon fontSize="small" />
              )
            }
          />
        ))}
      </div>
    );
  }

  renderSearchBox() {
    const { searchResult, value } = this.state;
    const filteredResults = searchResult.filter(
      (s) => s.type && s.tag.toLowerCase().includes(value.trim().toLowerCase())
    );

    return (
      <div className="flex-col-gap-10 ">
        <div>
          <p>Assignmemt Tags : </p>
          <div className="search-box mt3">
            {filteredResults.length > 0 ? (
              filteredResults.map(
                (s, i) =>
                  s.type === assignmentTaskTagsType.TOPIC && (
                    <div key={i} className="search-entry">
                      <div
                        className="pointer flex-gap-10"
                        onClick={() => this.selectOption(s)}
                      >
                        <p>{s.tag}</p>
                        <AssignmentIcon fontSize="small" />
                      </div>
                    </div>
                  )
              )
            ) : (
              <p className="ml2 mt2">No matching tags found</p>
            )}
          </div>
        </div>
        <div>
          <p>Company Tags : </p>
          <div className="search-box mt3">
            {filteredResults.length > 0 ? (
              filteredResults.map(
                (s, i) =>
                  s.type === assignmentTaskTagsType.COMPANY && (
                    <div key={i} className="search-entry">
                      <div
                        className="pointer flex-gap-10"
                        onClick={() => this.selectOption(s)}
                      >
                        <p>{s.tag}</p>
                        <WorkIcon fontSize="small" />
                      </div>
                    </div>
                  )
              )
            ) : (
              <p className="ml2 mt2">No matching tags found</p>
            )}
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { label, value, required, type, searchResult } = this.state;

    return (
      <div>
        <div className="flex flex-column ">
          {this.renderTags()}
          <div className="tag-input-container">
            <TextInput
              label={label}
              value={value}
              type="text"
              className="tag-text-input"
              onTextChange={(text) => this.filterData(text)}
              autoComplete={false}
              required={required}
            />
            <SelectInput
              value={type}
              onChange={this.handleTagTypeChange}
              className="tag-type-selector"
              size={'Small'}
              label={'Tag Type'}
            >
              <MenuItem value={assignmentTaskTagsType.TOPIC}>Topic</MenuItem>
              <MenuItem value={assignmentTaskTagsType.COMPANY}>
                Company
              </MenuItem>
            </SelectInput>

            <button
              onClick={() => {
                if (value.trim() === '') {
                  toast.error('Tag input cannot be empty!');
                  return;
                }
                this.selectOption({ tag: value.trim(), type });
              }}
              type="button"
              className="add-tag-button"
            >
              Add Tag
            </button>
          </div>
        </div>
        <div>
          {searchResult.length > 0 ? (
            this.renderSearchBox()
          ) : (
            <p className="ml2 mt2">No tags found</p>
          )}
        </div>
      </div>
    );
  }
}

export default DropDownTextInput;
