import React from 'react';
import './SolveAssignment.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import { useSelector } from 'react-redux';

const AppointmentTimingSubmitted = ({
  appointmentSlots,
  setActiveStep,
  slotsLength,
}) => {
  return (
    <div className="interview-timing-confirmation">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between mr5">
          <div>
            <CheckCircleIcon
              style={{ width: '110px', height: '110px', fill: '#668C9C' }}
            />
          </div>
          <div className="pl2">
            <p className="f6">
              You will be informed when a student requests for an appointment.
            </p>
          </div>
        </div>
      </div>
      <div className="time-slots">
        <p className="w-50">Available time slot(s) entered:</p>
        <div className="flex items-center flex-wrap">
          {appointmentSlots.map((c, index) => (
            <p key={index} className="time">{`${moment(
              c.time,
              'hh:mm:ss A'
            ).format('hh:mm A')} - ${moment(c.time, 'hh:mm:ss A')
              .add(slotsLength, 'm')
              .format('hh:mm A')}`}</p>
          ))}
        </div>
      </div>
      <div className="flex items-center mt3">
        <button className="blue-btn-light" onClick={() => setActiveStep(1)}>
          Edit
        </button>
        <button
          className="blue-btn ml3"
          onClick={() => {
            setActiveStep(4);
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default AppointmentTimingSubmitted;
