import React from 'react';
import { Link } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';
import './Courses.scss';
import CreateAssignment from './CreateAssignment';

function Assignment({ assignment, index }) {
  return (
    <div className="assignment" index={index}>
      <div className="flex-div">
        <h3>{assignment.name}</h3>
        <p>{assignment.points} assignment points</p>
      </div>
      <div className="flex-div">
        <div className="flex">
          <button className="">
            <CreateAssignment assignment={assignment} isUpdate />
          </button>
        </div>
        <Link
          to={`${pathNames.QNA_PAGE}?CourseId=${assignment.CourseId}&&AssignmentId=${assignment.id}`}
          className="secondary-btn pa2 f6 br2 w-30"
        >
          Q/A Forum
        </Link>
        <Link
          to={`${pathNames.MESSAGES}?CourseId=${assignment.CourseId}&&AssignmentId=${assignment.id}`}
          className="secondary-btn pa2 f6 br2 w-50"
        >
          Send Message
        </Link>
      </div>
    </div>
  );
}

export default Assignment;
