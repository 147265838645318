import amplitude from 'amplitude-js';
import {
  amplitudeEventProperties,
  userProperties,
} from '../constants/amplitudeProperties';
import { getAmplitudeEndpoint } from './functions';
const env = process.env.REACT_APP_ENV?.trim();

/**
 * Initializes the amplitude. This needs to be called only once.
 */

export const initAmplitude = async () => {
  if (env === 'staging' || env === 'production') {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, null, {
      apiEndpoint: await getAmplitudeEndpoint(),
    });
  }
};

/**
 * Sets the User Id for all the amplitude requests.
 * @param {String} userId - User id.
 */
export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

/**
 * Sets the User properties.
 * @param {Object} properties - properties of user that needs to go to amplitude.
 */
export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserId(properties.id);

  const result = {};
  changeAmplitudeEventProperties(userProperties, properties, result);
  amplitude.getInstance().setUserProperties(result);
};

/**
 * Sends the data to the amplitude.
 * @param  {String} eventType - Event type for the data.
 * @param {Object} eventProperties - properties of events that needs to go to amplitude.
 */
export const sendAmplitudeData = (eventType, eventProperties) => {
  if (!eventType) {
    throw new Error('eventType is required');
  }
  if (!eventProperties) {
    throw new Error('eventProperties is required');
  }

  const result = {};
  Object.keys(eventProperties).forEach((prop) => {
    changeAmplitudeEventProperties(
      amplitudeEventProperties[prop],
      eventProperties[prop],
      result
    );
  });

  amplitude.getInstance().logEvent(eventType, {
    ...result,
    ...mandatoryAmplitudeProperties,
  });
};

/**
 * Sends the data to the amplitude, as other user.
 * @param  {object} userProperties - user for which the event will be sent.
 * @param  {String} eventType - Event type for the data.
 * @param {Object} eventProperties - properties of events that needs to go to amplitude.
 * @param {Object} currentUser - properties of current user.
 */
export const sendAmplitudeDataAsOtherUser = (
  userProperties,
  eventType,
  eventProperties,
  currentUser
) => {
  if (!eventType) {
    throw new Error('eventType is required');
  }
  if (!eventProperties) {
    throw new Error('eventProperties is required');
  }
  setAmplitudeUserProperties(userProperties);
  amplitude.getInstance().logEvent(eventType, {
    ...mandatoryAmplitudeProperties,
    ...eventProperties,
  });
  setAmplitudeUserProperties(currentUser);
};

const getDeviceType = () => {
  let deviceType = 'desktop';
  if (0 <= window.innerWidth && window.innerWidth < 650) {
    deviceType = 'mobile';
  }

  if (650 <= window.innerWidth && window.innerWidth < 1000) {
    deviceType = 'tablet';
  }

  return { 'Device type': deviceType };
};

const mandatoryAmplitudeProperties = {
  ...getDeviceType(),
};

/**
 * Changes amplitude properties based on parameter definitions.
 * @param  {Object} eventPropertiesDefinition - Object from amplitude property defintion
 * @param  {Object} eventProperties - Object from event property
 * @param  {Object} result - Altered properties based on parameter definition
 */
function changeAmplitudeEventProperties(
  eventPropertiesDefinition,
  eventProperties,
  result
) {
  if (!eventPropertiesDefinition || !eventProperties) return result;

  if (typeof eventPropertiesDefinition === 'string') {
    return (result[eventPropertiesDefinition] = eventProperties);
  }

  let eventPropertiesDefinitionKeys = Object.keys(eventPropertiesDefinition);

  for (let i = 0; i < eventPropertiesDefinitionKeys.length; i++) {
    let key = eventPropertiesDefinitionKeys[i];
    if (!Object.prototype.hasOwnProperty.call(eventProperties, key)) {
      continue;
    }

    if (
      Array.isArray(eventPropertiesDefinition[key]) &&
      Array.isArray(eventProperties[key])
    ) {
      const arrayObjects = eventProperties[key].map((propertyObject) => {
        const data = {};
        changeAmplitudeEventProperties(
          eventPropertiesDefinition[key][0],
          propertyObject,
          data
        );
        return data;
      });

      if (!eventPropertiesDefinition[key][0].ArrayCustomName) {
        throw new Error(
          `Please provide the name of the array under key 'ArrayCustomName' in object ${JSON.stringify(
            eventPropertiesDefinition[key][0]
          )}
            e.g.activeInterviews: [
            {
              ArrayCustomName: 'Active interviews',
              companyName: 'Company name',
              maxCTC: 'Maximum CTC',
              JobApplicants: [
                ArrayCustomName: 'Job applications',
                {
                  JobApplicantStatuses: [
                    {
                      ArrayCustomName: 'Job applications status',
                      status: 'Application status',
                    },
                  ],
                },
              ],
            },
          ],`
        );
      }
      result[eventPropertiesDefinition[key][0].ArrayCustomName] = arrayObjects;
      continue;
    }

    if (
      typeof eventPropertiesDefinition[key] === 'object' &&
      typeof eventProperties[key] === 'object'
    ) {
      // If current value of key is object then recurse deeper
      changeAmplitudeEventProperties(
        eventPropertiesDefinition[key],
        eventProperties[key],
        result
      );
      continue;
    }

    // If current value of key is not object then add to result
    // Adding the value to refactored amplitude property
    // Ex: {'name': 'value'}
    result[eventPropertiesDefinition[key]] = eventProperties[key];
  }
}
