import { GET_ALL_APPOINTMENTS_SLOTS } from '../../constants/actionTypes';

const defaultState = {
  appointments: [],
};

const CoursesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_APPOINTMENTS_SLOTS:
      return { ...state, appointments: action.payload };
    default:
      return state;
  }
};

export default CoursesReducers;
