import React, { useState } from 'react';
import { toast } from 'react-toastify';
import agent from '../../agent';
import { getErrorMessage } from '../../constants/helperFunctions';
import AppointmentRatingBtn from './AppointmentRatingBtn';
import { appointmentRatings } from '../../constants/otherConstants';

function AppointmentQuestion({
  currentQuestionIndex,
  setCurrentQuestionIndex,
  allQuestions,
  setAllQuestions,
}) {
  const [feedback, setFeedback] = useState('');
  const question = allQuestions[currentQuestionIndex];
  const assignment = question?.AssignmentTask;

  // Function to submit the question feedback
  const submitQuestionFeedback = async (rating) => {
    if (feedback?.trim() === '') {
      return toast.error('Please provide feedback');
    }

    const payload = {
      questionId: question?.id,
      feedback: feedback,
      rating: rating,
    };

    try {
      await agent.Appointments.submitQuestionFeedback(payload);

      setAllQuestions((prev) => {
        prev[currentQuestionIndex].feedback = feedback;
        prev[currentQuestionIndex].rating = rating;
        return [...prev];
      });

      setFeedback('');
      setCurrentQuestionIndex((p) => {
        if (p < allQuestions.length - 1) {
          return p + 1;
        }
        return p;
      });
    } catch (error) {
      return toast.error(getErrorMessage(error));
    }
  };

  // If no question is found then return nothing
  if (!question) {
    return null;
  }

  return (
    <>
      <div
        key={question.id}
        className="w-[80%] flex flex-col gap-5 mb3 overflow-y-auto  h-[calc(100vh-7rem)] pr-1 appointmentScrollbar"
      >
        <div className="bg-gray-100 p-4 rounded-md flex flex-col gap-2">
          <h2 className="text-3xl">{assignment.name}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: assignment.description }}
          ></div>
        </div>

        {question.feedback && (
          <div className=" text-white flex flex-col gap-5  p-2 bg-emerald-700 rounded-md">
            <div className="flex text-2xl justify-between items-center">
              <p>Your Feedback</p>
              <div
                className={`flex gap-2 ${
                  appointmentRatings[question?.rating]?.color
                } p-3 justify-between items-center rounded-md`}
              >
                <p className="text-base">{question?.rating}</p>
                <p>{appointmentRatings[question?.rating]?.emoji}</p>
              </div>
            </div>
            <p className="p-3 bg-emerald-200 text-black">{question.feedback}</p>
          </div>
        )}

        {!question.feedback && (
          <div className="flex flex-shrink-1 p-2 bg-emerald-800 rounded-md ">
            <textarea
              className="w-[80%]  resize-none p-2"
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Enter your feedback here"
            ></textarea>
            <div className="w-[30%] flex justify-evenly items-center">
              <AppointmentRatingBtn
                rating={appointmentRatings.Good}
                onClick={submitQuestionFeedback}
              />
              <AppointmentRatingBtn
                rating={appointmentRatings.Bad}
                onClick={submitQuestionFeedback}
              />
              <AppointmentRatingBtn
                rating={appointmentRatings.Poor}
                onClick={submitQuestionFeedback}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AppointmentQuestion;
