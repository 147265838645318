export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const SET_USER = 'SET_USER';

//custome action types
export const GET_USERS = 'GET_USERS';
export const GET_USERS_COURSES = 'GET_USERS_COURSES';
export const GET_BATCHES = 'GET_BATCHES';
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS';
export const GET_ENQUIRIES = 'GET_ENQUIRIES';
export const GET_HOLDTYPES = 'GET_HOLDTYPES';
export const GET_ASSIGNMENTS_TASK_TAGS = 'GET_ASSIGNMENTS_TASK_TAGS';
export const GET_CATEGORIES = 'GET_CATEGORIES';

// error action  types
export const GET_ERRORS = 'GET_ERRORS';
export const TOGEL_DRAWER = 'TOGEL_DRAWER';

// courses
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_STUDENTS = 'GET_COURSES_STUDENTS';
export const GET_COURSE_ASSIGNMENTS = 'GET_COURSE_ASSIGNMENTS';
export const GET_COURSE_OVERVIEW = 'GET_COURSE_OVERVIEW';

//quesions
export const GET_QUESTIONS = 'GET_QUESTIONS';

export const GET_COLLEGES = 'GET_COLLEGES';
export const GET_DEEGREES = 'GET_DEEGREES';

export const GET_ALL_APPOINTMENTS_SLOTS = 'GET_ALL_APPOINTMENTS_SLOTS';
