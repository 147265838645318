import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Loader from '../../common/components/Loader';

import TextInput from '../../common/components/TextInput';

import './AssignmentSubmission.scss';
import agent from '../../agent';
import UserFlags from './UserFlags';

import ReviewQuestions from './ReviewQuestions';
import {
  InterviewerRatingText,
  taskTypes,
} from '../../constants/otherConstants';
import TaskQuestion from './TaskQuestion';

const AssignmentSubmission = (props) => {
  const [assignment, setAssignment] = useState({ assignmentStatus: {} });
  const [user, setUser] = useState({});
  const [selectedTask, setSelectedTask] = useState({
    taskStatus: [],
    reviewingUser: {},
  });

  const [commitId, setCommitId] = useState('');
  const [pointsAwarded, setPointsAwarded] = useState(0);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openUndoConfirm, setUndoConfirmOpen] = useState(false);
  const [openUndoTaskSubmission, setOpenUndoTaskSubmission] = useState(false);
  let { userId } = useParams();

  useEffect(() => {
    if (props.selectedTask && props.selectedTask !== selectedTask) {
      if (props.selectedTask) {
        setCommitId(
          props.selectedTask.taskStatus.length > 0 &&
            props.selectedTask.taskStatus?.[0]?.commitId
        );
        // setPointsAwarded(props.selectedTask.taskStatus.length > 0 &&props.selectedTask.taskStatus?.[0]?.pointsAwarded);
        setResult(
          props.selectedTask.taskStatus.length > 0 &&
            props.selectedTask.taskStatus?.[0]?.result
        );
      }
      setError(null);

      setSelectedTask(props.selectedTask);
    }
    if (props.assignment && props.assignment !== assignment) {
      setAssignment(props.assignment);
    }
    if (props.user && props.user !== user) {
      setUser(props.user);
    }
  }, [props.selectedTask, props.assignment, props.user]);

  const validatePointsAndResult = (data) => {
    if (data.pointsAwarded > selectedTask.points) {
      setError('Points can not be more than the maximum points!!!');
      return false;
    }
    if (
      selectedTask.taskStatus.length &&
      data.result > selectedTask.taskStatus?.[0]?.totalResult
    ) {
      setError('Result can not be more than the maximum result!!!');
      return false;
    }
    return true;
  };

  const reviewTask = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      !selectedTask.type === taskTypes.demo &&
      (!selectedTask.taskStatus.length ||
        !selectedTask.taskStatus?.[0]?.submitDateTime)
    ) {
      toast.error('Task not submitted. You cannot review it');
      return;
    }

    const data = {
      UserId: userId,
      TaskId: selectedTask.id,
      AssignmentId: assignment.id,
      pointsAwarded,
      result,
      AttemptId: selectedTask.taskStatus?.[0]?.AttemptId,
    };
    if (!validatePointsAndResult(data)) {
      return;
    }

    setError(null);
    setLoading(true);
    try {
      await agent.AssignmentSubmissions.reviewTask(data);
      toast.success('Task reviewed');
      setLoading(false);
      props.getTaskStatusOfAnAssignment(userId, assignment.id, true);
    } catch (err) {
      console.log('err in the sumitting the assignment', err, err.response);
      setLoading(false);
      if (err && err.response && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('some error occured please try again later');
      }
    }
  };

  const undoReviewTask = async () => {
    try {
      const data = {
        UserId: user.id,
        AssignmentId: assignment.id,
        TaskId: selectedTask.id,
        pointsAwarded: null,
        result,
        AttemptId: selectedTask.taskStatus?.[0]?.AttemptId,
      };
      setLoading(true);

      await agent.AssignmentSubmissions.undoReviewTask(data);
      setLoading(false);

      props.undoReviewAssignment();
      props.getTaskStatusOfAnAssignment(user.id, assignment.id);

      setUndoConfirmOpen(false);
      setPointsAwarded(0);
      setError(null);
      toast.success('Task review Cancelled');
    } catch (err) {
      console.log(
        'error in the undoing review of assignment',
        err,
        err.response
      );
      setLoading(false);
      if (err && err.response && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('some error occured please try again later');
      }
    }
  };
  const UndoAssignmentTaskSubmission = async () => {
    const data = {
      UserId: user.id,
      TaskId: selectedTask.id,
      AssignmentId: assignment.id,
      AttemptId: selectedTask.taskStatus?.[0]?.AttemptId,
    };

    setLoading(true);
    try {
      await agent.AssignmentSubmissions.undoAssignmentTaskSubmission(data);
      setOpenUndoTaskSubmission(false);
      setLoading(false);

      // if the task submissiuon is not undone then the assignment canno t be reviewed
      props.undoReviewAssignment();
      toast.success('User task submission Cancelled !!!');
      props.getTaskStatusOfAnAssignment(user.id, assignment.id);
    } catch (err) {
      setLoading(false);
      if (err.response.data) {
        toast.error(err.response.data.message);
      }
      console.log('Err in undo task submission', err, err.response);
    }
  };
  const handleConfirmDialog = () => {
    setUndoConfirmOpen(!openUndoConfirm);
  };
  const handleUndoTaskSubmissionDialog = () => {
    setOpenUndoTaskSubmission(!openUndoTaskSubmission);
  };

  const UndoTaskReviewDialog = () => {
    return (
      <>
        <DialogTitle>Undo review of {selectedTask.name}</DialogTitle>
        <DialogContent>
          <div>
            <h4>Do you want to undo the review of task.</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={undoReviewTask}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo Review
          </button>
          <button
            type="button"
            onClick={handleConfirmDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </>
    );
  };

  const UndoTaskSubmissionDialog = () => {
    return (
      <Dialog
        open={openUndoTaskSubmission}
        onClose={handleUndoTaskSubmissionDialog}
      >
        <DialogTitle>Undo submission of {selectedTask.name}</DialogTitle>
        <DialogContent>
          <div>
            <h4>Do you want to undo the submission of task.</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={UndoAssignmentTaskSubmission}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo submission
          </button>
          <button
            type="button"
            onClick={handleUndoTaskSubmissionDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const getAnswerSectionBasedOnType = (type) => {
    switch (type) {
      case taskTypes.coding: {
        return (
          <textarea
            className="ql-editor"
            style={{
              backgroundColor: 'beige',
              width: '100%',
              minHeight: '350px',
            }}
            value={commitId}
            disabled
          />
        );
      }
      case taskTypes.communication: {
        let aiFeedback = {
          message: '',
        };

        try {
          aiFeedback = JSON.parse(selectedTask.taskStatus?.[0]?.feedback);
        } catch (error) {
          return toast.error('Error on showing the AI feedback.');
        }

        return (
          <div
            className="ql-editor"
            style={{
              backgroundColor: 'beige',
              width: '100%',
              minHeight: '50px',
            }}
          >
            <h3>{selectedTask.communicationQuestion}</h3>
            <p>{commitId}</p>
            <div>
              {Object.keys(aiFeedback).map((feedback) => {
                return (
                  <div className="flex gap-10 mt2" key={feedback}>
                    <p className="b">{feedback}:</p>
                    <p>{aiFeedback[feedback]}</p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
      case taskTypes.mcq:
        return selectedTask.TaskQuestions.map((question, index) => (
          <TaskQuestion
            question={question}
            key={question.id}
            index={index}
            alreadySubmitted={true}
          />
        ));

      default:
        return (
          <div
            className="ql-editor"
            style={{ backgroundColor: 'beige' }}
            dangerouslySetInnerHTML={{
              __html: commitId,
            }}
          />
        );
    }
  };

  return (
    selectedTask && (
      <div className="middle-div">
        <div className="flex items-center justify-between">
          <h3> {selectedTask.name} </h3>
          <div className="flex items-center flex-end">
            <h3>
              {' '}
              Interviewer rating:{' '}
              {
                InterviewerRatingText[
                  selectedTask?.taskStatus?.[0]?.interviewerRating
                ]
              }{' '}
            </h3>
            <UserFlags
              user={user}
              setUser={setUser}
              task={selectedTask}
              assignment={assignment}
            />
          </div>
        </div>
        <div className="details">
          <div className="points">
            <b> Maximum Points </b>
            <p>
              {selectedTask.taskStatus.length > 0 &&
              selectedTask.taskStatus?.[0]?.endDateTime ? (
                new Date(
                  selectedTask.taskStatus?.[0]?.submitDateTime || new Date()
                ) > new Date(selectedTask.taskStatus?.[0]?.endDateTime) ? (
                  <>
                    {`${selectedTask.points / 2}`}
                    <strike>{selectedTask.points}</strike>
                  </>
                ) : (
                  `${selectedTask.points}`
                )
              ) : (
                `${selectedTask.points}`
              )}
            </p>
          </div>
          {selectedTask.type === 'coding' && (
            <div className="points">
              <b> Total Test Cases </b>
              <p>{selectedTask.totalResult}</p>
            </div>
          )}
        </div>
        {selectedTask.isTimeBounded && (
          <div className="details">
            <div className="points">
              <b> Maximum time: </b>
              <p>{`${selectedTask.maximumTime} ${selectedTask.unitTime}`}</p>
            </div>
            <div className="points">
              <b> Started on: </b>
              <p>
                {selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.startDateTime
                  ? moment(selectedTask.taskStatus?.[0]?.startDateTime).format(
                      'MMM Do YY - hh:mm:ss a'
                    )
                  : 'Task not started'}
              </p>
            </div>

            <div className="points">
              <b> Ends on: </b>
              <p>
                {selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.endDateTime
                  ? moment(selectedTask.taskStatus?.[0]?.endDateTime).format(
                      ' MMM Do YY - hh:mm:ss a'
                    )
                  : 'Task not started'}
              </p>
            </div>
            <div className="points">
              <b> Submitted on: </b>
              <p>
                {selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.submitDateTime
                  ? moment(selectedTask.taskStatus?.[0]?.submitDateTime).format(
                      ' MMM Do YY - hh:mm:ss a'
                    )
                  : 'Task not submitted'}
              </p>
            </div>
          </div>
        )}

        <div className="details">
          <div className="points">
            <b> Task Status </b>
            <p
              className={
                selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.isReviewed === true
                  ? 'b green'
                  : ' b red'
              }
            >
              {selectedTask.taskStatus.length > 0 &&
              selectedTask.taskStatus?.[0]?.isCompleted === true
                ? selectedTask.taskStatus?.[0]?.isReviewed === true
                  ? 'Task reviewed'
                  : 'Review Pending'
                : 'Not Completed'}
            </p>
          </div>
          <div className="points">
            <b> Points Awarded </b>
            <p
              className={
                selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.isReviewed === true
                  ? 'b green'
                  : ' b red'
              }
            >
              {selectedTask.taskStatus.length > 0 &&
              selectedTask.taskStatus?.[0]?.isReviewed === true
                ? `${selectedTask.taskStatus?.[0]?.pointsAwarded}/${selectedTask.points}`
                : 'Review is pending'}
            </p>
          </div>
          {selectedTask.type === 'coding' && (
            <div className="points">
              <b>Test Cases Passed </b>
              <p
                className={
                  selectedTask.taskStatus.length > 0 &&
                  selectedTask.taskStatus?.[0]?.isReviewed === true
                    ? 'b green'
                    : ' b red'
                }
              >
                {selectedTask.taskStatus.length > 0 &&
                selectedTask.taskStatus?.[0]?.result != null
                  ? `${selectedTask.taskStatus?.[0]?.result}/ ${selectedTask.totalResult}`
                  : 'Review is pending'}
              </p>
            </div>
          )}
        </div>
        <div className="actions">
          {new Date(assignment.assignmentStatus.startDate) > new Date() ? (
            <p className="red mt1 center b tc">
              This assignment is not started.
            </p>
          ) : (
            <div className="">
              <form onSubmit={reviewTask} className="flex items-center">
                <TextInput
                  label={'Points'}
                  type="number"
                  value={pointsAwarded}
                  onTextChange={(value) => setPointsAwarded(value)}
                  required
                  className="w-50"
                />
                <button
                  type="submit"
                  className="secondary-btn pa2 br2 center mt15"
                >
                  {selectedTask.taskStatus.length > 0 &&
                  selectedTask.taskStatus?.[0]?.isReviewed
                    ? 'Update Review'
                    : 'Review Task'}
                </button>
                {selectedTask.taskStatus.length > 0 &&
                  selectedTask.taskStatus?.[0]?.isReviewed && (
                    <button
                      type="button"
                      onClick={handleConfirmDialog}
                      className="secondary-btn pa2 br2 center mt15 btn-red"
                    >
                      Undo Review
                    </button>
                  )}
                {selectedTask.taskStatus.length > 0 && (
                  <button
                    type="button"
                    onClick={handleUndoTaskSubmissionDialog}
                    className="secondary-btn pa2 br2 center mt15 btn-red"
                  >
                    Undo Submission
                  </button>
                )}
              </form>
              {error && <p className="error"> {error}</p>}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="mt2 mb2 flex-auto">
            <h3>Answer:</h3>
            {selectedTask.taskStatus.length > 0 &&
            selectedTask.taskStatus?.[0]?.isCompleted ? (
              getAnswerSectionBasedOnType(selectedTask.type)
            ) : (
              <p className="red "> Submission Pending </p>
            )}
          </div>
          {selectedTask.solution && (
            <div className="mt2 mb2  w-50 ml4">
              <h3> Solution </h3>
              {selectedTask.type === 'Coding' ? (
                <textarea
                  className="ql-editor"
                  style={{
                    backgroundColor: 'beige',
                    width: '100%',
                    minHeight: '500px',
                  }}
                  value={selectedTask.solution}
                  disabled
                />
              ) : (
                <div
                  className="ql-editor"
                  style={{ backgroundColor: 'beige' }}
                  dangerouslySetInnerHTML={{
                    __html: selectedTask.solution,
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="description">
          <h4> Task Description </h4>
          <div
            className="ql-editor ql-snow"
            dangerouslySetInnerHTML={{
              __html: selectedTask.description,
            }}
          />
        </div>
        {selectedTask.enablePeerReview && (
          <ReviewQuestions
            task={selectedTask}
            answers={
              selectedTask.taskStatus.length > 0 &&
              selectedTask.taskStatus?.[0]?.UserTaskReviewAnswers
            }
            reviewingUser={
              selectedTask.taskStatus.length > 0 &&
              selectedTask.taskStatus?.[0]?.reviewingUser
            }
          />
        )}
        {loading && <Loader />}
        <Dialog open={openUndoConfirm} onClose={handleConfirmDialog}>
          {UndoTaskReviewDialog()}
        </Dialog>
        {UndoTaskSubmissionDialog()}
      </div>
    )
  );
};

export default AssignmentSubmission;
