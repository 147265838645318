import moment from 'moment';
import {
  GOOGLE_AD_URL,
  AMPLITUDE_PROXY_SERVER_ENDPOINT,
  AMPLITUDE_ENDPOINT,
} from '../constants/otherConstants';
export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const reactQuillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const reactQuillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],

    ['clean'],
  ],
};

export const getObjectFromQueryString = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const convertToLink = async (
  bufferData,
  download = false,
  fileName = ''
) => {
  const theBlob = new Blob([bufferData]);

  const file = new File([theBlob], fileName);

  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  if (download) {
    link.download = fileName;
  } else {
    link.target = '_blank';
  }

  document.body.appendChild(link);
  link.click();
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getQueryStringByFilterOptions = (filterOptions) => {
  let optionString = '';
  Object.keys(filterOptions).forEach((k) => {
    if (filterOptions[k] != null) optionString += `${k}=${filterOptions[k]}&`;
  });
  optionString = optionString.substring(0, optionString.length - 1);
  if (optionString.length > 0) {
    optionString = `?${optionString}`;
  }
  return optionString;
};

export function generateSeriesForInterval(
  interval,
  startDate = moment(new Date(1970, 0, 1, 10, 0, 0))
) {
  const rc = [];
  const initialDate = startDate.get('date');
  while (startDate.get('date') === initialDate) {
    const data = {
      from: moment(startDate),
      to: moment(startDate.add(interval, 'minutes')),
    };
    rc.push(data);
  }
  return rc;
}

async function detectAdBlock() {
  let adBlockEnabled = false;
  try {
    await fetch(new Request(GOOGLE_AD_URL)).catch(
      (_) => (adBlockEnabled = true)
    );
  } catch (e) {
    adBlockEnabled = true;
  }
  return adBlockEnabled;
}

export const getAmplitudeEndpoint = async () => {
  const isBraveBrowser =
    (navigator.brave && (await navigator.brave.isBrave())) || false;
  const adBlockDetected = await detectAdBlock();
  if (isBraveBrowser || adBlockDetected) {
    return AMPLITUDE_PROXY_SERVER_ENDPOINT;
  }
  return AMPLITUDE_ENDPOINT;
};

export const isValidGoogleMeetLink = (str) => {
  const googleMeetRegex = /^(https?:\/\/)?meet.google.com\/[a-zA-Z0-9-]+$/;
  return googleMeetRegex.test(str);
};
