import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControl from '@mui/material/FormControl';
import './Courses.scss';
import { MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const CreateQuestionBank = ({
  isUpdate,
  onUpdate,
  onDelete,
  index,
  ...props
}) => {
  const [questionBank, setQuestionBank] = useState({});
  const categories = useSelector((state) => state.CoursesReducers.categories);

  useEffect(() => {
    if (props.questionBank) {
      setQuestionBank(props.questionBank);
    }
  }, [props.questionsBank]);

  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    const category = categories.find((c) => c.id === Number(value));
    const updatedQuestionBank = { ...questionBank, Category: category };
    if (isUpdate) {
      onUpdate(updatedQuestionBank, index);
    }
    setQuestionBank(updatedQuestionBank);
  };

  const handleChangeNumberOfQuestions = (event) => {
    const {
      target: { value },
    } = event;

    const updatedQuestionBank = {
      ...questionBank,
      numberOfQuestions: value,
    };
    if (isUpdate) {
      onUpdate(updatedQuestionBank, index);
    }
    setQuestionBank(updatedQuestionBank);
  };

  const addQuestionBank = () => {
    if (!questionBank.Category) {
      toast.error('Please Select a category');
      return;
    }
    if (
      !questionBank.numberOfQuestions ||
      questionBank.numberOfQuestions <= 0
    ) {
      toast.error('Please add a positive number of questions');
      return;
    }
    props.addQuestionBank(questionBank);
    setQuestionBank({ Category: { id: '' }, numberOfQuestions: 0 });
  };

  return (
    <>
      <div className="flex justify-between mt2 items-center">
        <select
          value={questionBank.Category?.id}
          onChange={handleChangeCategory}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {`${category.category} - ${category.dificulty} - ${category.points}`}
            </option>
          ))}
        </select>
        <input
          value={questionBank.numberOfQuestions}
          placeholder="Number of questions"
          type="number"
          className="pa2"
          onChange={handleChangeNumberOfQuestions}
          //   (e) =>
          //   setQuestionBank({
          //     ...questionBank,
          //     numberOfQuestions: e.target.value,
          //   })
          // }
        />
        {!isUpdate && (
          <button
            style={{ width: '10%' }}
            type="button"
            onClick={addQuestionBank}
            className="ml2"
          >
            Add
          </button>
        )}

        {isUpdate && (
          <button
            style={{ width: '10%' }}
            type="button"
            onClick={() => onDelete(questionBank, index)}
            className="red-btn ml2"
          >
            <DeleteForeverIcon />
          </button>
        )}
      </div>
    </>
  );
};

export default CreateQuestionBank;
