import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import React from 'react';

function AddNoteDialog({ open, note, setNote, onSubmit, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <p className="text-xl">Add note for this member</p>
        <p className="text-sm">
          This note will be visible to this member until they got removed from
          the team
        </p>
        <p className="text-sm">This will not visible to the student</p>
      </DialogTitle>
      <DialogContent>
        <textarea
          rows={4}
          className="w-100 border p-2"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add note"
        ></textarea>
      </DialogContent>
      <DialogActions>
        <button className="red-btn f6" onClick={onClose}>
          Cancel
        </button>
        <button className="primary-btn f6" onClick={onSubmit}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNoteDialog;
