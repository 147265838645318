/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { Link, useHistory } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Courses.scss';
import Loader from '../../common/components/Loader';
import { Switch } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COURSES } from '../../constants/actionTypes';
import {
  getParameterByName,
  reactQuillModules,
  reactQuillFormats,
} from '../../common/functions';
import CourseTime from './CourseTime';
import agent from '../../agent';
import ReactQuill from 'react-quill';
import { courseStatus, ERROR_MSG } from '../../constants/otherConstants';
import PasswordPersmission from '../../common/components/PasswordPersmission';
import { courseTypes } from '../../constants/data';

function CreateCourse({ location: { search } }) {
  const [course, setCourse] = useState({
    staggeredAdmission: false,
    prerequisites: [],
  });
  const [courseDescription, setCourseDescription] = useState('-');
  const [isUpdate, setIsUpdate] = useState(false);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const courses = useSelector((state) => state.CoursesReducers.courses);
  const user = useSelector((state) => state.common.user);

  const dispatch = useDispatch();
  useEffect(() => {
    let course = { prerequisites: [] };
    const courseId = getParameterByName('CourseId');
    if (courseId) {
      course = courses?.find((c) => c.id === Number(courseId));
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    if (course) {
      setCourse(course);
      setCourseDescription(course.description);
      const courseIds = new Set(course.prerequisites?.data?.map((c) => c.id));
      const availableCourses = courses?.filter((c) => !courseIds.has(c.id));
      setAvailableCourses(availableCourses);
    }
  }, [search, courses]);
  const validateCourse = (course) => {
    const { name, description, assignmentThreshold } = course;
    if (!name) {
      toast.error('Name is required');
      return false;
    }
    if (!description) {
      toast.error('Description is required');
      return false;
    }

    if (!assignmentThreshold || assignmentThreshold < 1) {
      toast.error('Assignment to show needs to be at least 1');
      return false;
    }

    return true;
  };
  const createCourse = async (course) => {
    let data = { ...course, description: courseDescription, price: 0 };
    if (!validateCourse(data)) {
      return;
    }
    if (isUpdate) {
      setLoading(true);

      await agent.Courses.updateCourse(data.id, data);

      const newCourses = courses?.map((c) => {
        if (c.id === data.id) {
          return data;
        }
        return c;
      });

      const newData = {
        ...courses,
        data: newCourses,
      };
      dispatch({ type: GET_COURSES, payload: newData });
      setLoading(false);
      toast.success('Course Updated');
    } else {
      setLoading(true);
      const res = await agent.Courses.addCourse(data);

      const newCourses = {
        ...courses,
        data: [
          {
            ...res.data.data,
            prerequisites: course.prerequisites,
            UserId: user.id,
            creator: user,
          },
          ...courses.data,
        ],
        totalItems: courses.totalItems + 1,
      };
      dispatch({ type: GET_COURSES, payload: newCourses });
      toast.success('Course Created');
      history.replace(
        `${pathNames.COURSE_DETAILS}?CourseId=${res.data.data.id}`
      );
      setLoading(false);
    }
  };
  const addCoursePreerquisite = (e) => {
    const preCourseId = Number(e.target.value);
    const preCourse = courses?.find((c) => c.id === preCourseId);
    if (isUpdate) {
      const data = { CourseId: course.id, prerequisiteId: preCourseId };
      setLoading(true);
      agent.Courses.addCoursePrerequisite(data)
        .then(() => {
          const newCourse = {
            ...course,
            prerequisites: [preCourse, ...course.prerequisites],
          };
          const newCourses = courses?.map((c) => {
            if (c.id === newCourse.id) return newCourse;
            return c;
          });
          const newData = {
            ...courses,
            data: newCourses,
          };
          dispatch({ type: GET_COURSES, payload: newData });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, err.response);
        });
    } else {
      const newCourse = {
        ...course,
        prerequisites: [preCourse, ...course.prerequisites],
      };
      setCourse(newCourse);
      const courseIds = new Set(newCourse.prerequisites.map((c) => c.id));
      const availableCourses = courses?.filter((c) => !courseIds.has(c.id));
      setAvailableCourses(availableCourses);
    }
  };

  const deleteCoursePrerequisite = (preCourse) => {
    if (isUpdate) {
      const data = { CourseId: course.id, prerequisiteId: preCourse };

      setLoading(true);
      agent.Courses.deleteCoursePrerequisite(data)
        .then(() => {
          const pre = course.prerequisites.filter(
            (c) => Number(c.id) !== Number(preCourse)
          );
          console.log(pre);
          const newCourse = { ...course, prerequisites: pre };

          const newCourses = courses?.map((c) => {
            if (c.id === newCourse.id) {
              return newCourse;
            }
            return c;
          });
          const newData = {
            ...courses,
            data: newCourses,
          };
          dispatch({ type: GET_COURSES, payload: newData });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err, err.response);
        });
    } else {
      const pre = course.prerequisites.filter(
        (c) => Number(c.id) !== Number(preCourse)
      );

      const newCourse = { ...course, prerequisites: pre };
      setCourse(newCourse);
      const courseIds = new Set(newCourse.prerequisites.map((c) => c.id));
      const availableCourses = courses?.filter((c) => !courseIds.has(c.id));
      setAvailableCourses(availableCourses);
    }
  };

  const changeStatusOfCourse = (value) => {
    if (!isUpdate) {
      return;
    }
    setLoading(true);
    const data = { id: course.id, status: value };
    agent.Courses.updateCourse(data.id, data)
      .then(() => {
        setCourse({
          ...course,
          status: value,
        });
        const newCourse = { ...course, status: value };

        const newCourses = courses?.map((c) => {
          if (c.id === newCourse.id) {
            return newCourse;
          }
          return c;
        });

        const newData = {
          ...courses,
          data: newCourses,
        };
        dispatch({ type: GET_COURSES, payload: newData });
        setLoading(false);
        toast.success('Course status updated');
      })
      .catch((err) => {
        console.log(err);
        toast.error(ERROR_MSG);
      });
  };

  return (
    <>
      <NavBar />
      <div className="create-course-page">
        <div className="header">
          <h3>Course Details</h3>
          <Link to={pathNames.COURSES}>
            Go Back to Courses <ArrowBackIcon />
          </Link>
        </div>
        <div className="body">
          <div className="course-description">
            <fieldset>
              <legend>Course Name</legend>
              <p>{course.name}</p>
            </fieldset>
          </div>
          <div className="course-description">
            <fieldset>
              <legend>Course Description</legend>
              <div dangerouslySetInnerHTML={{ __html: courseDescription }} />
            </fieldset>
          </div>
          <div className="select flex ">
            <label>Course Pre-Requisites: </label>
            <div className="ml2">
              <div className="flex ">
                {course.prerequisites?.length > 0 ? (
                  course.prerequisites?.map((c) => (
                    <div className="ba br4 ml1 mr2 pa2 flex" key={c.id}>
                      <p>{c.name}</p>
                      <button onClick={() => deleteCoursePrerequisite(c.id)}>
                        <DeleteForeverIcon
                          className="danger"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No course added as pre-requisite</p>
                )}
              </div>
            </div>
          </div>
          <div className="select flex ">
            <label>Course Type: </label>
            <div className="ml2">
              <p>{courseTypes[course?.type] || 'No course type selected'}</p>
            </div>
          </div>

          <div className="switch">
            <label>Allow Staggered Admission: </label>
            <p className={course.staggeredAdmission ? 'success' : 'danger'}>
              {course.staggeredAdmission ? 'Enabled' : 'Disabled'}
            </p>
          </div>
          <div className="flex items-center">
            <p className="mr2">Assignment To Show at one time: </p>
            <p>{course.assignmentThreshold}</p>
          </div>

          {isUpdate && <CourseTime course={course} />}
        </div>

        {loading && <Loader />}
      </div>
    </>
  );
}

export default CreateCourse;
