import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-min-noconflict/mode-java';
import 'ace-builds/src-min-noconflict/mode-javascript';
import 'ace-builds/src-min-noconflict/mode-c_cpp';
import 'ace-builds/src-min-noconflict/mode-python';
// import "ace-builds/src-min-noconflict/mode-mysql";
// import "ace-builds/src-min-noconflict/mode-typescript";
import 'ace-builds/src-min-noconflict/theme-monokai';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import { boilerPlateCodeDelimeter } from '../../constants/otherConstants';
import './Compiler.scss';

const removeBoilerPlateCode = (codestring) => {
  if (!codestring || codestring.indexOf(boilerPlateCodeDelimeter) === -1)
    return codestring;

  var code = codestring.substring(
    codestring.indexOf(boilerPlateCodeDelimeter) +
      boilerPlateCodeDelimeter.length,
    codestring.lastIndexOf(boilerPlateCodeDelimeter)
  );

  return code.replaceAll(boilerPlateCodeDelimeter, '');
};

const CodeEditor = ({
  codeString,
  onCodeChange,
  language,
  isTemplateEditor,
}) => {
  const [mode, setMode] = useState('');
  // const [code, setCode] = useState("");

  useEffect(() => {
    if (language) setMode(language.editorLanguage);
  }, [language]);

  return (
    <AceEditor
      placeholder="Start writing your code..."
      mode={mode}
      theme="monokai"
      name="code-editor"
      style={{ width: '100%', color: null }}
      onChange={onCodeChange}
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      value={!isTemplateEditor ? removeBoilerPlateCode(codeString) : codeString}
      style={{ height: '65vh', width: '100%' }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 4,
      }}
    />
  );
};

export default CodeEditor;
