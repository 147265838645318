import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';

import TextInput from '../../common/components/TextInput';
import Loader from '../../common/components/Loader';
import agent from '../../agent';

import { GET_COURSE_ASSIGNMENTS } from '../../constants/actionTypes';
import './Courses.scss';
import { ERROR_MSG, taskStatus } from '../../constants/otherConstants';

const reviewingQuestions = [
  { question: 'What is the time complexity of the code?' },
  { question: 'What is the space complexity of the code?' },
  { question: 'Rate the design out of 5?' },
  { question: 'Rate the coding style out of 5.' },
  { question: 'Report bugs if any.' },
];

const CreateReviewQuestion = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewQuestion, setReviewQuestion] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      setIsUpdate(props.isUpdate);
    }
  }, []);

  useEffect(() => {
    if (props.reviewQuestion && props.reviewQuestion !== reviewQuestion) {
      setReviewQuestion(props.reviewQuestion);
    }
  }, [props.reviewQuestion]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateReviewQuestion = () => {
    return true;
  };
  const createReviewQuestion = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!validateReviewQuestion(reviewQuestion)) {
      return;
    }
    setLoading(true);
    // console.log(reviewQuestion)
    if (isUpdate) {
      console.log(reviewQuestion);
      agent.Assignments.updateReviewQuestion(reviewQuestion)
        .then(() => {
          const { courseAssignments, setCourseAssignments, task } = props;
          const tempAllAssignments = courseAssignments?.map((a) => {
            if (a.id === task.AssignmentId) {
              const tasks = a.AssignmentTasks.map((t) => {
                if (t.id === task.id) {
                  const tempTaskHint = t.AssignmentTaskReviewQuestions.map(
                    (hint) => {
                      if (hint.id === reviewQuestion.id) {
                        return reviewQuestion;
                      }
                      return hint;
                    }
                  );

                  return { ...t, AssignmentTaskReviewQuestions: tempTaskHint };
                }
                return t;
              });
              return { ...a, AssignmentTasks: tasks };
            }
            return a;
          });
          setCourseAssignments(tempAllAssignments);
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err, err.response);
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setError(ERROR_MSG);
          }

          setLoading(false);
        });
    } else {
      const tempReviewQuestion = {
        AssignmentTaskId: props.task.id,
        ...reviewQuestion,
        ReviewQuestions: [],
      };
      console.log(tempReviewQuestion);
      agent.Assignments.createReviewQuestion(tempReviewQuestion)
        .then((res) => {
          console.log(res);

          const { courseAssignments, setCourseAssignments, task } = props;
          const tempAllAssignments = courseAssignments?.map((a) => {
            if (a.id === task.AssignmentId) {
              const tasks = a.AssignmentTasks.map((t) => {
                if (t.id === task.id) {
                  const tempTask = {
                    ...t,
                    AssignmentTaskReviewQuestions: [
                      ...t.AssignmentTaskReviewQuestions,
                      res.data.data,
                    ],
                  };
                  return tempTask;
                }
                return t;
              });
              return { ...a, AssignmentTasks: tasks };
            }
            return a;
          });

          setCourseAssignments(tempAllAssignments);
          setLoading(false);
          setOpen(false);
          setReviewQuestion({ question: '' });
        })
        .catch((err) => {
          console.log(err, err.response);
          setLoading(false);
        });
    }
  };
  const deleteReviewQuestion = () => {
    setLoading(true);
    agent.Assignments.deleteReviewQuestion({ id: reviewQuestion.id })
      .then(() => {
        const { courseAssignments, setCourseAssignments, task } = props;
        setLoading(false);

        setOpen(false);
        const tempAllAssignments = courseAssignments?.map((a) => {
          if (a.id === task.AssignmentId) {
            const tasks = a.AssignmentTasks.map((t) => {
              if (t.id === task.id) {
                const tempTask = {
                  ...t,
                  AssignmentTaskReviewQuestions:
                    t.AssignmentTaskReviewQuestions.filter(
                      (hint) => hint.id !== reviewQuestion.id
                    ),
                };
                return tempTask;
              }
              return t;
            });
            return { ...a, AssignmentTasks: tasks };
          }
          return a;
        });
        setCourseAssignments(tempAllAssignments);
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
      });
  };

  const SimpleReviewQuestionDialog = () => {
    const { question } = reviewQuestion;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth>
        <DialogTitle>Create Review Question</DialogTitle>
        <DialogContent>
          <form onSubmit={createReviewQuestion} className="create-task-form">
            <select
              onChange={(e) =>
                setReviewQuestion({
                  ...reviewQuestion,
                  question: e.target.value,
                })
              }
              disabled={isUpdate && props.task.status == taskStatus.LIVE}
            >
              <option value={''}>Select From List</option>
              {reviewingQuestions.map((q) => (
                <option value={q.question}>{q.question} </option>
              ))}
            </select>
            <h3>Or</h3>
            <TextInput
              label="Review Question"
              value={question}
              required
              onTextChange={(value) =>
                setReviewQuestion({ ...reviewQuestion, question: value })
              }
              disabled={isUpdate && props.task.status == taskStatus.LIVE}
            />

            {error && <p className="error"> {error} </p>}
            <div className="btns-div">
              {isUpdate && props.task.status === taskStatus.ARCHIVE && (
                <button type="submit">Update ReviewQuestion</button>
              )}
              {!isUpdate && (
                <button type="submit">Create ReviewQuestion</button>
              )}
              {isUpdate &&
                props.task.status ==
                  taskStatus.ARCHIVE(
                    <button
                      type="button"
                      className="red-btn"
                      onClick={() => deleteReviewQuestion()}
                    >
                      {' '}
                      Delete Review Question{' '}
                    </button>
                  )}
            </div>
          </form>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  return (
    <>
      {isUpdate ? (
        <tr className="task">
          <td>
            <button type="button" onClick={handleClickOpen}>
              {reviewQuestion.question}
            </button>
          </td>
        </tr>
      ) : (
        <>
          {props.task.status == taskStatus.ARCHIVE && (
            <button
              type="button"
              className="create-task"
              onClick={handleClickOpen}
            >
              Create Review Question
            </button>
          )}
        </>
      )}

      {SimpleReviewQuestionDialog()}
    </>
  );
};

const mapStateToProps = (state) => ({
  courseAssignments: state.CoursesReducers.courseAssignments,
});

const mapDispatchToProps = (dispatch) => ({
  setCourseAssignments: (assignments) =>
    dispatch({ type: GET_COURSE_ASSIGNMENTS, payload: assignments }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReviewQuestion);
