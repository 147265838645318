import React, { useEffect, useState } from 'react';
import PageWrapper from '../../common/components/PageWrapper';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../constants/helperFunctions';
import agent from '../../agent';
import MockHistory from './MockHistory';
import Loader from '../../common/components/Loader';

function PreviousMockHistory() {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [allMockHistory, setAllMockHistory] = useState([]);

  useEffect(() => {
    fetchAllAppointments();
  }, [userId]);

  const fetchAllAppointments = async () => {
    try {
      const response = await agent.Appointments.getAppointmentHistory(
        `?UserId=${userId}`
      );
      setAllMockHistory(response?.data?.data ?? []);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const { User } = allMockHistory[0] ?? {};
  const { name, email, whatsappNumber, mobileNo } = User ?? {};

  if (loading) {
    return <Loader />;
  }

  if (allMockHistory.length === 0) {
    return (
      <PageWrapper>
        <p className="text-3xl b">Previous Mock History</p>
        <p>No History Found</p>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <p className="text-3xl b">Previous Mock History</p>
      <div className="mt-5 flex flex-col gap-2">
        <p>
          <span className="b">Name:</span> {name}
        </p>
        <p>
          <span className="b">Email:</span> {email}
        </p>
        <p>
          <span className="b">Whatsapp:</span> {whatsappNumber}
        </p>
        <p>
          <span className="b">Mobile:</span> {mobileNo}
        </p>
      </div>
      <div className=" flex flex-col gap-5 mt-5">
        {allMockHistory?.map((mock) => (
          <MockHistory key={mock.id} mock={mock} />
        ))}
      </div>
    </PageWrapper>
  );
}

export default PreviousMockHistory;
