import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

import Loader from '../../common/components/Loader';
import CollapseTransition from '../../common/components/CollapseTransition';
import TextInput from '../../common/components/TextInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './AssignmentSubmission.scss';
import agent from '../../agent';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import TaskSection from './TaskSection';
import { store } from '../../store';
import { push } from 'connected-react-router';
// import UserFlags from './UserFlags';
import { getParameterByName } from '../../common/functions';

const AssignmentSubmission = (props) => {
  const [assignment, setAssignment] = useState({ assignmentStatus: {} });
  const [user, setUser] = useState({});
  const [assignmentTasks, setAssignmentTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({
    taskStatus: [],
  });
  const [showDescreption, setShowDesreption] = useState(false);
  const [assignmentPointsAwarded, setAssignmentPointsAwarded] = useState(null);
  const [loading, setLoading] = useState(false);

  const [assignmentError, setAssignmentError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [pendingTasks, setPedingTasks] = useState([]);
  const [openUndoConfirm, setUndoConfirmOpen] = useState(false);
  const [openUndoSubmission, setOpenUndoSubmission] = useState(false);

  const getTaskStatusOfAnAssignment = async (
    userId,
    assignmentId,
    getAssignmentDetails = false
  ) => {
    const taskId = getParameterByName('taskId', window.location.search);
    try {
      const res = await agent.AssignmentSubmissions.getTaskStatusOfAnAssignment(
        userId,
        assignmentId
      );
      const {
        data: { data: assignmentTasks },
      } = res;
      setAssignmentTasks(assignmentTasks);

      if (assignmentTasks && assignmentTasks.length) {
        if (taskId) {
          assignmentTasks.forEach((task) => {
            if (task.id === Number(taskId)) {
              setSelectedTask(task);
            }
          });
        } else {
          const pendingTasks = assignmentTasks.filter(
            (task) => !task.taskStatus || !task.taskStatus[0].isReviewed
          );
          if (pendingTasks.length > 0) {
            selectTask(pendingTasks[0]);
          } else {
            if (getAssignmentDetails) {
              agent.AssignmentSubmissions.getAnUserAssignment(
                userId,
                assignmentId
              )
                .then((res) => {
                  setAssignment(res.data.data);

                  if (res.data.data.assignmentStatus) {
                    setAssignmentPointsAwarded(
                      res.data.data.assignmentStatus.pointsAwarded
                    );
                  }
                })
                .catch((err) => {
                  console.log(err, err.response);
                });
            }
            selectTask(assignmentTasks[0]);
          }
        }
      } else {
        setShowDesreption(true);
      }
    } catch (err) {
      console.log(err, err.response);
    }
  };

  useEffect(() => {
    const { userId, assignmentId } = props.match.params;
    if (props.location.state) {
      setUser(props.location.state.user);
    }
    getTaskStatusOfAnAssignment(userId, assignmentId);
    agent.AssignmentSubmissions.getAnUserAssignment(userId, assignmentId)
      .then((res) => {
        setAssignment(res.data.data);

        if (res.data.data.assignmentStatus) {
          setAssignmentPointsAwarded(
            res.data.data.assignmentStatus.pointsAwarded
          );
        }
      })
      .catch((err) => {
        console.log(err, err.response);
      });
  }, []);

  const selectTask = (taskStatus) => {
    const taskId = getParameterByName('taskId', window.location.search);
    if (taskId) {
      store.dispatch(push(props.match.url, { user }));
    }
    setSelectedTask(taskStatus);
  };

  const checkPendingTasks = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (assignmentPointsAwarded > assignment.points) {
      setAssignmentError(
        'Assignment Points can not be more than maximum assignment points'
      );
      return;
    }
    const pendingTasks = assignmentTasks.filter(
      (task) => !task.taskStatus || !task.taskStatus[0].isReviewed
    );

    setPedingTasks(pendingTasks);
    setShowAlert(true);
  };

  const reviewAssignment = (
    assignmentTasks,
    calculateBasedOnTaskScore = true
  ) => {
    if (!assignment.assignmentStatus.submissionDate) {
      toast.error('Assignment not submitted. You cannot review it');
      return;
    }

    // we will take the the pcentage of the points got by the user in the task
    let totalTaskPoints = 0;
    let totalTaskAcquiredPoints = 0;

    assignmentTasks.forEach((t) => {
      totalTaskPoints += t.points;
      if (t.taskStatus.length) {
        totalTaskAcquiredPoints += t.taskStatus[0].pointsAwarded;
      }
    });

    // assigning the percentage of the maximum assignment points
    const percentageRatio = totalTaskPoints
      ? totalTaskAcquiredPoints / totalTaskPoints
      : 0;

    const {
      assignmentStatus: { submissionDate, endDateTime },
    } = assignment;

    let maximumAssignmentPoints = assignment.points;
    if (
      moment(submissionDate || new Date())
        .startOf('day')
        .isAfter(moment(endDateTime))
    ) {
      maximumAssignmentPoints = 0;
    }

    const pointsAwarded = calculateBasedOnTaskScore
      ? Math.ceil(maximumAssignmentPoints * percentageRatio)
      : assignmentPointsAwarded;

    const data = {
      AssignmentId: assignment.id,
      UserId: user.id,
      pointsAwarded: pointsAwarded,
      AttemptId: assignment.assignmentStatus.AttemptId,
    };

    setLoading(true);
    setAssignmentError(null);
    agent.AssignmentSubmissions.reviewAssignment(data)
      .then(() => {
        setLoading(false);
        toast.success('Assignment reviewed!!!');
        const { assignmentId, userId } = props.match.params;
        setShowAlert(false);

        agent.AssignmentSubmissions.getAnUserAssignment(userId, assignmentId)
          .then((res) => {
            setAssignment(res.data.data);
            if (res.data.data.assignmentStatus) {
              setAssignmentPointsAwarded(
                res.data.data.assignmentStatus.pointsAwarded
              );
            }
          })
          .catch((err) => {
            console.log(err, err.response);
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data) {
          toast.error(err.response.data.message);
        }

        console.log('Err in reviewing assignment', err, err.response);
      });
  };
  const undoReviewAssignment = async () => {
    const data = {
      AssignmentId: assignment.id,
      UserId: user.id,
      pointsAwarded: null,
      AttemptId: assignment.assignmentStatus.AttemptId,
    };

    setLoading(true);
    setAssignmentError(null);
    try {
      await agent.AssignmentSubmissions.undoReviewAssignment(data);

      const { assignmentId, userId } = props.match.params;

      const res = await agent.AssignmentSubmissions.getAnUserAssignment(
        userId,
        assignmentId
      );

      setAssignment(res.data.data);
      setAssignmentPointsAwarded(0);
      setLoading(false);
      setUndoConfirmOpen(false);
      setShowAlert(false);
      toast.success('Assignment review Cancelled !!!');
    } catch (err) {
      setLoading(false);
      if (err.response.data) {
        toast.error(err.response.data.message);
      }

      console.log('Err in undo reviewing assignment', err, err.response);
    }
  };

  const UndoAssignmentSubmission = () => {
    const data = {
      UserId: user.id,
      AssignmentId: assignment.id,
    };

    setLoading(true);
    setAssignmentError(null);
    agent.AssignmentSubmissions.undoParticularAssignmentSubmission(data)
      .then(() => {
        setOpenUndoSubmission(false);
        const { assignmentId, userId } = props.match.params;
        toast.success('Assignment submission Cancelled !!!');
        setShowAlert(false);
        setLoading(false);

        agent.AssignmentSubmissions.getAnUserAssignment(userId, assignmentId)
          .then((res) => {
            setAssignment(res.data.data);
          })
          .catch((err) => {
            console.log(err, err.response);
          });
        getTaskStatusOfAnAssignment(user.id, assignment.id);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data) {
          toast.error(err.response.data.message);
        }
        console.log('Err in undo assignment submission', err, err.response);
      });
  };

  const handleConfirmDialog = () => {
    setUndoConfirmOpen(!openUndoConfirm);
  };
  const handleUndoSubmissionDialog = () => {
    setOpenUndoSubmission(!openUndoSubmission);
  };

  const UndoAssignmentSubmissionDialog = () => {
    return (
      <Dialog open={openUndoSubmission} onClose={handleUndoSubmissionDialog}>
        <DialogTitle>Undo submission of {assignment.name}</DialogTitle>
        <DialogContent>
          <div>
            <h4>Do you want to undo the submission of assignment.</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={UndoAssignmentSubmission}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo Submission
          </button>
          <button
            type="button"
            onClick={handleUndoSubmissionDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const UndoReviewDialog = () => {
    return (
      <>
        <DialogTitle>Undo review of {assignment.name}</DialogTitle>
        <DialogContent>
          <div>
            <h4>Do you want to undo the review of assignment.</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={undoReviewAssignment}
            className="secondary-btn w-50 pa2 br2"
          >
            Undo Review
          </button>
          <button
            type="button"
            onClick={handleConfirmDialog}
            className="tertiary-btn w-50 pa2"
          >
            Close
          </button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <div className="solve-assignment">
        <nav className="nav">
          <h2> {assignment.name} </h2>
          <div className="flex items-center ">
            {/* <UserFlags user={user} setUser={setUser} assignment={assignment} /> */}
            {/* <button type='button' className='secondary-btn pa2 br2'>
            <Link to={`/admin-dashboard/user/${user.id}`} className='white'>
              Go to All assignments
            </Link>
          </button> */}
          </div>
        </nav>
        <div className="assignment-details">
          <div className="details">
            <div>
              <b> {`Assigned On`} </b>
              <p>
                {`${moment(assignment.assignmentStatus.startDate).format(
                  'MMMM Do YYYY'
                )}`}
              </p>
            </div>
            <div>
              <b> {`Start Date`} </b>
              <p>
                {`${moment(assignment.assignmentStatus.startDateTime).format(
                  'MMMM Do YYYY'
                )}`}
              </p>
            </div>
            <div>
              <b> {`Last Date`} </b>
              <p>
                {`${moment(assignment.assignmentStatus.endDateTime).format(
                  'MMMM Do YYYY'
                )}`}
              </p>
            </div>
            <div>
              <b> {`Maximum points`} </b>
              <p>
                {moment(
                  assignment.assignmentStatus.submissionDate || new Date()
                ).isAfter(moment(assignment.assignmentStatus.endDateTime)) ? (
                  <>
                    0 <strike>{assignment.points}</strike>
                  </>
                ) : (
                  `${assignment.points}`
                )}
              </p>
            </div>
          </div>

          <div className="submission-details">
            <div className="details">
              {assignment.isTimeBounded && (
                <>
                  <div>
                    <b> {`Test Started On:`} </b>
                    <p
                      className={
                        assignment.assignmentStatus.startDateTime ? '' : 'red'
                      }
                    >
                      {assignment.assignmentStatus.startDateTime
                        ? moment(
                            assignment.assignmentStatus.startDateTime
                          ).format('DD-MMM-yy hh:mm a')
                        : 'Not started'}
                    </p>
                  </div>
                  <div>
                    <b> {`Test submitted on: `} </b>
                    <p
                      className={
                        assignment.assignmentStatus.submissionDate ? '' : 'red '
                      }
                    >
                      {assignment.assignmentStatus.submissionDate ? (
                        <>
                          {moment(
                            assignment.assignmentStatus.submissionDate
                          ).format('DD-MMM-yy hh:mm a')}
                          <span
                            className={
                              moment(
                                assignment.assignmentStatus.submissionDate
                              ).isBefore(
                                moment(assignment.assignmentStatus.endDateTime)
                              )
                                ? 'green b'
                                : `red b`
                            }
                          >
                            {moment(
                              assignment.assignmentStatus.submissionDate
                            ).isBefore(
                              moment(assignment.assignmentStatus.endDateTime)
                            )
                              ? '(In time)'
                              : ` (overdue) `}
                          </span>
                        </>
                      ) : (
                        'Not submitted'
                      )}
                    </p>
                  </div>
                  <div>
                    <b> {`Test Expired on: `} </b>
                    <p
                      className={
                        assignment.assignmentStatus.endDateTime ? '' : 'red'
                      }
                    >
                      {assignment.assignmentStatus.endDateTime
                        ? moment(
                            assignment.assignmentStatus.endDateTime
                          ).format('DD-MMM-yy hh:mm a')
                        : 'Not started'}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="submission-details">
            <div>
              <b> {`Submitted by`} </b>
              <Link
                to={`/admin-dashboard/user?UserId=${user.id}&&CourseId=${assignment.CourseId}`}
              >
                {' '}
                {user.name}{' '}
              </Link>
            </div>
            <div>
              <b> {`Status`} </b>
              <p
                className={
                  assignment.assignmentStatus.isCompleted ? 'b green' : ' b red'
                }
              >
                {`${
                  assignment.assignmentStatus.isCompleted
                    ? assignment.assignmentStatus.isReviewed
                      ? 'Reviewed'
                      : 'Review pending'
                    : 'Not completed'
                }`}
              </p>
            </div>
            <div>
              <b> {`Submission Date`} </b>
              <p
                className={
                  assignment.assignmentStatus.submissionDate
                    ? 'b green'
                    : ' b red'
                }
              >
                {assignment.assignmentStatus.submissionDate
                  ? moment(assignment.assignmentStatus.submissionDate).format(
                      'MMMM Do YYYY'
                    )
                  : 'Submission pending'}
              </p>
            </div>
            <div>
              <b> {`Points awarded`} </b>
              <p
                className={
                  assignment.assignmentStatus.isReviewed ? 'b green' : ' b red'
                }
              >
                {assignment.assignmentStatus.isReviewed
                  ? `${assignment.assignmentStatus.pointsAwarded}/ ${assignment.points}`
                  : 'Review is pending'}
              </p>
            </div>
          </div>
          <div className="description">
            <div className="header">
              <h4>Description And Actions</h4>
              <button
                type="button"
                onClick={() => setShowDesreption(!showDescreption)}
              >
                {showDescreption ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
            <CollapseTransition visible={showDescreption}>
              <>
                <div>
                  {new Date(assignment.assignmentStatus.startDate) >
                  new Date() ? (
                    <p className="red mt1 center b tc">
                      This assignment is not yet assigned.
                    </p>
                  ) : (
                    <div className="grid">
                      <form
                        onSubmit={checkPendingTasks}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <TextInput
                          label="Assignment Points"
                          value={assignmentPointsAwarded}
                          onTextChange={(value) =>
                            setAssignmentPointsAwarded(value)
                          }
                          required
                          type="number"
                          style={{
                            width: '75%',
                          }}
                        />
                        <button
                          type="submit"
                          className="center secondary-btn pa2 br2"
                        >
                          {assignment.assignmentStatus.isReviewed
                            ? ' Update Review'
                            : 'Review Assignment'}
                        </button>
                      </form>
                      <div className="btns-div">
                        {assignment.assignmentStatus.isReviewed && (
                          <button
                            onClick={handleConfirmDialog}
                            className="center secondary-btn pa2 br2 btn-red mt15"
                          >
                            Undo Review
                          </button>
                        )}
                        <button
                          onClick={handleUndoSubmissionDialog}
                          className="center secondary-btn pa2 br2 btn-red mt15"
                        >
                          Undo Submission
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {assignmentError && <p className="error">{assignmentError} </p>}

                <h5>Description</h5>
                <div
                  className="ql-editor ql-snow"
                  dangerouslySetInnerHTML={{ __html: assignment.description }}
                />
              </>
            </CollapseTransition>
          </div>
        </div>
        {assignment?.UserInterviews?.length > 0 && (
          <div className="interviewer mt2 pa2">
            <h3>Interviewer feedback</h3>
            <p className="mt2">{assignment?.UserInterviews[0]?.feedback}</p>
          </div>
        )}
        {assignmentTasks && assignmentTasks.length > 0 && (
          <div className="body">
            <div className="left-div">
              <h3>Tasks</h3>
              {assignmentTasks.map((taskStatus) => (
                <button
                  type="button"
                  onClick={() => selectTask(taskStatus)}
                  className={
                    taskStatus.id === selectedTask.id ? 'selected task' : 'task'
                  }
                  key={taskStatus.id}
                >
                  {taskStatus.id === selectedTask.id && <ChevronRightIcon />}
                  <p> {`${taskStatus.taskIndex}. ${taskStatus.name}`} </p>
                  <p
                    className={
                      taskStatus.taskStatus.length > 0 &&
                      taskStatus.taskStatus[0].isReviewed
                        ? 'completed status'
                        : 'pending status'
                    }
                  >
                    {taskStatus.taskStatus.length > 0 &&
                    taskStatus.taskStatus[0].isReviewed
                      ? 'Reviewed'
                      : 'Pending'}
                  </p>
                </button>
              ))}
            </div>

            <TaskSection
              selectedTask={selectedTask}
              selectTask={selectTask}
              user={user}
              assignment={assignment}
              setAssignmentTasks={setAssignmentTasks}
              getTaskStatusOfAnAssignment={getTaskStatusOfAnAssignment}
              undoReviewAssignment={undoReviewAssignment}
            />
          </div>
        )}

        <Dialog open={showAlert} onClose={setShowAlert}>
          <DialogTitle> Submit {assignment.name} </DialogTitle>
          <DialogContent>
            {pendingTasks.length > 0 && (
              <div>
                <b className="mt1 mb1">
                  You have not reviewed the following taskStatus.
                </b>
                {pendingTasks.map((taskStatus, i) => (
                  <p key={taskStatus.id}>{`${i + 1}. ${taskStatus.name}`}</p>
                ))}
              </div>
            )}
            <b className="mt1 mb1">
              Are you sure you want to review this assignment.
            </b>
          </DialogContent>
          <DialogActions>
            <button
              className="secondary-btn pa2 br2"
              type="button"
              onClick={() => reviewAssignment(assignmentTasks, false)}
            >
              Review assignment
            </button>
            <button
              type="button"
              className="tertiary-btn pa2 br1"
              onClick={() => setShowAlert(false)}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={openUndoConfirm} onClose={handleConfirmDialog}>
          {UndoReviewDialog()}
        </Dialog>
        {UndoAssignmentSubmissionDialog()}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default AssignmentSubmission;
