import React, { useEffect, useState } from 'react';
import './SolveAssignment.scss';
import { weekDays } from '../../constants/otherConstants';

const SelectAppointmentDay = ({
  setSelectedDay,
  selectedDay,
  setActiveStep,
  days,
}) => {
  return (
    <div className="time-table">
      <div className="w-30">
        <p className="heading-1">Day</p>

        {Object.keys(weekDays).map((dayKey, index) => {
          const isHighlighted = days.has(parseInt(dayKey)); // Ensure dayKey is an integer
          return (
            <button
              className={`tc time w-100 ${isHighlighted ? 'selected' : ''}`}
              key={index}
              onClick={() => {
                setSelectedDay(dayKey);
                setActiveStep(1);
              }}
            >
              {weekDays[dayKey]}
            </button>
          );
        })}
      </div>
      <div>
        <p>Choose time slot according to the days.</p>
      </div>
    </div>
  );
};

export default SelectAppointmentDay;
