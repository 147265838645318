'use strict';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import FlagIcon from '@material-ui/icons/Flag';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { ERROR_MSG } from '../../constants/otherConstants';
import Loader from '../../common/components/Loader';
import CollapseTransition from '../../common/components/CollapseTransition';
import { GET_QUESTIONS } from '../../constants/actionTypes';
import agent from '../../agent';
import { toast } from 'react-toastify';
import './Questions.css';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import { questionStatus } from '../../constants/otherConstants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { reactQuillFormats, reactQuillModules } from '../../common/functions';

const Question = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [question, setQuestion] = useState({
    Answers: [],
    User: {},
    QuestionVotes: [],
  });
  const [answer, setAnswer] = useState('');
  const [confirmQuestionDialogOpen, setConfirmQuestionDialogOpen] =
    useState(false);
  const [confirmAnswerDialogOpen, setConfirmAnswerDialogOpen] = useState(false);
  const [deleteAnswerId, setDeleteAnswerId] = useState(null);

  useEffect(() => {
    if (props.question && question !== props.question) {
      setQuestion(props.question);
    }
  }, []);
  const user = useSelector((state) => state.common.user);
  const questions = useSelector((state) => state.QuestionsReducers.questions);
  const handleChange = (value) => {
    setAnswer(value);
  };

  const submitAnswer = () => {
    const data = { QuestionId: question.id, answer };

    setLoading(true);
    agent.Questions.submitAnswer(data)
      .then((res) => {
        setLoading(false);
        const tempQustion = {
          ...question,
          status: questionStatus.ANSWERED,
          Answers: [
            {
              answer,
              upVote: 0,
              downVote: 0,
              User: user,
              id: res.data.data.id,
            },
            ...question.Answers,
          ],
        };
        const tempAllQuestions = questions.rows.map((c) => {
          if (c.id == question.id) {
            return tempQustion;
          }
          return c;
        });
        dispatch({
          type: GET_QUESTIONS,
          payload: { ...questions, rows: tempAllQuestions },
        });
        setQuestion(tempQustion);
        setAnswer('');
        toast.success('Answer submitted');
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the submitAnswer');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };

  const markQuestionAsAnswerd = (e) => {
    const data = { id: question.id, isAnswered: e };

    setLoading(true);
    agent.Questions.markQuestionAsAnswerd(data)
      .then(() => {
        const tempQustion = {
          ...question,
          status: e ? questionStatus.ANSWERED : questionStatus.UNANSWERED,
        };
        const tempAllQuestions = questions.rows.map((c) => {
          if (c.id == question.id) {
            return tempQustion;
          }
          return c;
        });
        dispatch({
          type: GET_QUESTIONS,
          payload: { ...questions, rows: tempAllQuestions },
        });
        setQuestion(tempQustion);
        setLoading(false);
        // toast.success('Answer flagged');
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the flagAnswer');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };
  const flagAnswer = (answerId, e) => {
    const data = { id: question.id, flaggedAnswerId: answerId, isFlagged: e };

    setLoading(true);
    agent.Questions.flagAnswer(data)
      .then(() => {
        const tempQustion = {
          ...question,
          flaggedAnswerId: e ? answerId : null,
          status: e ? questionStatus.ANSWERED : questionStatus.UNANSWERED,
        };
        const tempAllQuestions = questions.rows.map((c) => {
          if (c.id == question.id) {
            return tempQustion;
          }
          return c;
        });
        dispatch({
          type: GET_QUESTIONS,
          payload: { ...questions, rows: tempAllQuestions },
        });
        setQuestion(tempQustion);
        setLoading(false);
        if (e) {
          toast.success('Answer flagged');
        } else {
          toast.success('Answer flag removed');
        }
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the flagAnswer');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };
  const handleQuestionConfirmDialog = () => {
    setConfirmQuestionDialogOpen(!confirmQuestionDialogOpen);
  };
  const confirmQuestionDeletionDialog = () => {
    return (
      <Dialog
        open={confirmQuestionDialogOpen}
        onClose={handleQuestionConfirmDialog}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Do you want to delete this question</DialogContent>
        <DialogActions>
          <button className="primary-btn" onClick={deleteQuestion}>
            Confirm
          </button>
          <button className="red-btn" onClick={handleQuestionConfirmDialog}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleAnswerConfirmDialog = () => {
    setConfirmAnswerDialogOpen(!confirmAnswerDialogOpen);
  };
  const handleAnswerDeletion = (id) => {
    setDeleteAnswerId(id);
    handleAnswerConfirmDialog();
  };

  const confirmAnswerDeletionDialog = () => {
    return (
      <Dialog
        open={confirmAnswerDialogOpen}
        onClose={handleAnswerConfirmDialog}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Do you want to delete this answer</DialogContent>
        <DialogActions>
          <button className="primary-btn" onClick={deleteAnswer}>
            Confirm
          </button>
          <button className="red-btn" onClick={handleAnswerConfirmDialog}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  const deleteAnswer = () => {
    const data = { AnswerId: deleteAnswerId, QuestionId: question.id };
    setLoading(true);
    agent.Questions.deleteAnswer(data)
      .then(() => {
        setLoading(false);
        const tempAnswers = question.Answers.filter(
          (c) => c.id != deleteAnswerId
        );
        const tempQustion = {
          ...question,
          flaggedAnswerId:
            question.flaggedAnswerId === deleteAnswerId
              ? null
              : question.flaggedAnswerId,
          Answers: tempAnswers,
          status: questionStatus.UNANSWERED,
        };
        setQuestion(tempQustion);
        const tempAllQuestions = questions.rows.map((c) => {
          if (c.id == question.id) {
            return tempQustion;
          }
          return c;
        });
        dispatch({
          type: GET_QUESTIONS,
          payload: { ...questions, rows: tempAllQuestions },
        });
        toast.success('Answer deleted');
        setConfirmAnswerDialogOpen(false);
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the deleteAnswer');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };

  const deleteQuestion = () => {
    setLoading(true);
    agent.Questions.deleteQuestion(question.id)
      .then(() => {
        setLoading(false);
        const tempAllQuestions = questions.rows.filter(
          (c) => c.id != question.id
        );
        dispatch({
          type: GET_QUESTIONS,
          payload: { ...questions, rows: tempAllQuestions },
        });
        toast.success('Question deleted');
        setConfirmQuestionDialogOpen(false);
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the deleteQuestion');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };

  return (
    <div key={question.question} className="question-div">
      <div className="question">
        <div className="flex-div">
          <div className="assignment-name">
            {question.Assignment && (
              <p>Assignment Name: {question.Assignment.name}</p>
            )}
            {question.AssignmentTask && (
              <p>Task Name: {question.AssignmentTask.name}</p>
            )}
          </div>
          {question.status == questionStatus.ANSWERED && (
            <div className="answered">
              <p>
                <CheckCircleIcon /> Answered
              </p>
            </div>
          )}
        </div>
        <div
          className="asked-by flex justify-between "
          style={{ overflowWrap: 'anywhere' }}
        >
          <h4 className="w-90">{`Question - ${question.question}`}</h4>
          <p>{`Asked by: ${question.User.name} `}</p>
        </div>
        <div className="btn-div">
          <div className="btn-div-content">
            <div className="votes">
              <button
                type="button"
                // onClick={upVoteQuestion}
                className={question.hasUpVoted ? 'active ' : ''}
                // disabled={question.hasUpVoted}
              >
                <ArrowDropUpIcon />
                {question.upVote || 0}
              </button>
            </div>
            {/* <p>{`${question.Answers.length} answers`}</p> */}
            <button
              type="button"
              className="answer"
              onClick={() => setShowInput(!showInput)}
            >
              <BorderColorIcon />
              Answer
            </button>{' '}
          </div>
          <div className="btns">
            {question.status === questionStatus.ANSWERED ? (
              // <div className='btns'>
              //   <DoneIcon />
              //   Marked as answered
              // </div>
              <button
                style={{ textAlign: 'right' }}
                onClick={() => markQuestionAsAnswerd(false)}
                className="primary-btn pa2 br3 red-btn"
              >
                Mark as Unanswered
              </button>
            ) : (
              <button
                style={{ textAlign: 'right' }}
                onClick={() => markQuestionAsAnswerd(true)}
                className="primary-btn pa2 br3"
              >
                Mark as Answered
              </button>
            )}
            <button
              style={{ textAlign: 'right' }}
              onClick={handleQuestionConfirmDialog}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
      <CollapseTransition visible={showInput}>
        <div className="answer-div">
          {question.flaggedAnswerId === null ? (
            <div className="ans-submit-div">
              <ReactQuill
                value={answer}
                onChange={handleChange}
                modules={reactQuillModules}
                formats={reactQuillFormats}
              />
              <button
                type="button"
                className="primary-btn pa2 mt2 mb2 ml-auto"
                onClick={submitAnswer}
              >
                {' '}
                Submit Answer
              </button>
            </div>
          ) : (
            <>
              <h4 style={{ textAlign: 'center', paddingBottom: '10px' }}>
                You cannot answer this question as the correct answer was
                already flagged
              </h4>
            </>
          )}
          <ul>
            <h3>Answers</h3>
            {question.Answers.length === 0 ? (
              <div> Be the first to answer this question. </div>
            ) : (
              question.Answers.map((a, i) => (
                <li
                  key={i}
                  className={
                    question.flaggedAnswerId === a.id
                      ? 'answer correct'
                      : 'answer'
                  }
                >
                  {question.flaggedAnswerId === a.id && (
                    <div className="mark">
                      Marked as correct
                      <DoneIcon />
                    </div>
                  )}
                  <div className="user">
                    <AccountCircleIcon />
                    {a.User.name}
                  </div>
                  <div className="content-div">
                    <div className="content">
                      <div className="votes">
                        <button
                          type="button"
                          // onClick={() => upOrDownVoteAnswer('up', a.id)}
                          className={a.hasUpVoted ? 'active' : ''}
                          disabled={a.hasUpVoted}
                        >
                          {a.upVote || 0}
                          <ArrowDropUpIcon />
                        </button>
                        <button
                          type="button"
                          // onClick={() => upOrDownVoteAnswer('down', a.id)}
                          className={a.hasDownVoted ? 'active' : ''}
                          disabled={a.hasDownVoted}
                        >
                          {a.downVote || 0}
                          <ArrowDropDownIcon />
                        </button>
                      </div>
                      <div
                        className="ql-editor"
                        key={a.id}
                        dangerouslySetInnerHTML={{ __html: a.answer }}
                      />
                    </div>
                    <div className="flex">
                      {(question.flaggedAnswerId === null ||
                        question.flaggedAnswerId !== a.id) && (
                        <button onClick={() => flagAnswer(a.id, true)}>
                          <FlagIcon style={{ fill: 'rgb(15, 110, 182)' }} />
                        </button>
                      )}
                      {question.flaggedAnswerId === a.id && (
                        <button
                          style={{ textAlign: 'right' }}
                          onClick={() => flagAnswer(a.id, false)}
                          className="primary-btn pa2 br3 red-btn"
                        >
                          Mark as Incorrect
                        </button>
                      )}
                      <button onClick={() => handleAnswerDeletion(a.id)}>
                        <DeleteIcon className="danger" />
                      </button>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      </CollapseTransition>
      {confirmQuestionDeletionDialog()}
      {confirmAnswerDeletionDialog()}
      {loading && <Loader />}
    </div>
  );
};
export default Question;
