import { pathNames } from './navigationPath';

export const userStatus = {
  NEW_JOINEE: 'New Joinee',
  IN_COURSE: 'In Course',
  REJECTED: 'Rejected',
  ON_HOLD: 'On hold',
  IN_BATCH: 'In Batch',
  HR_READY: 'Ready for HR',
  PLACED: 'Placed',
  IN_TRACK: 'In Track',
  IN_PLAN: 'In Plan',
  NOT_INTERESTED: 'Not Interested',
};

export const pointsTypes = {
  ASSIGNMENT_COMPLETED: 'Assignment Points',
  TASK_COMPLETED: 'Task Points',
  FORUM_POINTS: 'Forum Points',
  PENALTY_POINTS: 'Penalty Points',
  COMPETITION_POINTS: 'Competition Points',
  OVERDUE_ASSIGNMENT_POINTS: 'Overdue assignment Penalty',
  BONUS_POINTS: 'Bonus Points',
};
export const errorStatus = {
  RESOLVED: 'Resolved',
  UNRESOLVED: 'Un-resolved',
};

export const flagStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const eventTypes = {
  SUCCESS_STORY: 'SUCCESS_STORY',
  EVENT: 'EVENT',
};

export const courseTypes = {
  ONBOARDING: 'Onboarding',
  PREQUALIFICATION: 'Prequalification',
  POSTQUALIFICATION: 'Postqualification',
};

export const roleTypes = {
  ADMIN: 'ADMIN',
  SALES: 'SALES',
  MARKETING: 'MARKETING',
  USER: 'USER',
};

export const adminLinks = [
  { name: 'Tracks', link: pathNames.TRACKS },
  { name: 'Roles', link: pathNames.ROLES },
  { name: 'Mentors', link: pathNames.MENTORS },
  { name: 'Courses', link: pathNames.COURSES },
  { name: 'Colleges', link: pathNames.COLLEGES },
  { name: 'Degrees', link: pathNames.DEGREES },
  { name: 'Users', link: pathNames.USERS },
  { name: 'Blogs', link: pathNames.BLOGS },
  { name: 'Events', link: pathNames.EVENTS },
  { name: 'Testimonials', link: pathNames.TESTIMONIALS },
  { name: 'Gift Store', link: pathNames.GIFT_STORE },
  { name: 'Plans', link: pathNames.PLANS },
  { name: 'Enquiries', link: pathNames.ENQUIRIES },
  { name: 'UserDashboard', link: pathNames.USERDASHBOARD },
  { name: 'Task Categories', link: pathNames.TASK_CATEGORIES },
  { name: 'Guides', link: pathNames.SPIRIT_GUIDE_TEAMS },
];

export const salesLinks = [
  { name: 'Colleges', link: pathNames.COLLEGES },
  { name: 'Users', link: pathNames.USERS },
  { name: 'Degrees', link: pathNames.DEGREES },
  {
    name: 'User',
    link: '/user/isa/:userId',
    isNavBarLink: false,
  },
  {
    name: 'UserAssignment',
    link: '/user/:userId/assignment/:assignmentId',
    isNavBarLink: false,
  },
  {
    name: 'User',
    link: '/user',
    isNavBarLink: false,
  },
];

export const marketingLinks = [
  { name: 'Blogs', link: pathNames.BLOGS },
  { name: 'Testimonials', link: pathNames.TESTIMONIALS },
  { name: 'Events', link: pathNames.EVENTS },
];

export const sharpenerProPlans = ['gold', 'silver', 'bronze'];
