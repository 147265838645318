import {
  GET_ASSIGNMENTS,
  GET_ASSIGNMENTS_TASK_TAGS,
} from '../../constants/actionTypes';

const defaultState = {
  allAssignments: [],
  allAssignmentTaskTags: [],
};

const AssignmentsReducers = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTS:
      return { ...state, allAssignments: action.payload };
    case GET_ASSIGNMENTS_TASK_TAGS:
      return { ...state, allAssignmentTaskTags: action.payload };

    default:
      return state;
  }
};

export default AssignmentsReducers;
