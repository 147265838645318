import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import agent from '../../../agent';
// import { sendAmplitudeData } from "../../../common/amplitude";
import { LOGIN, SET_USER } from '../../../constants/actionTypes';
// import {
//   LOGIN_ATTEMPTED,
//   LOGIN_FAILED,
// } from "../../../constants/amplitudeConstants";
import {
  isMobileNumberValid,
  isEmail,
  trimAndLowercase,
} from '../../../constants/helperFunctions';
import { pathNames } from '../../../constants/navigationPath';
import { logo, WEB_TOKEN } from '../../../constants/otherConstants';
import '../Auth.scss';
import Content from '../Content';

const Login = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const webToken = window.localStorage.getItem(WEB_TOKEN);
    if (webToken) {
      history.push(pathNames.COURSES);
    }
  }, []);

  const login = async (e) => {
    if (e) {
      e.preventDefault();
    }

    // if input is not email check if it is a valid mobile number
    if (
      !isEmail(user?.emailOrMobile) &&
      !isMobileNumberValid(user.emailOrMobile)
    ) {
      setError('Please enter a valid mobile number');
      return;
    }

    // if (showCaptcha && !captchaVerified) {
    //   setError(`Please Complete the reCAPTCHA`);
    //   return;
    // }
    let tempUser = {};

    setError(null);
    tempUser = {
      ...user,
      emailOrMobile: trimAndLowercase(user.emailOrMobile),
    };

    try {
      setLoading(true);
      // sendAmplitudeData(LOGIN_ATTEMPTED, tempUser);
      const res = await agent.Auth.login(tempUser);
      setLoading(false);
      setError(null);

      dispatch({
        type: LOGIN,
        payload: res.data,
      });

      dispatch({
        type: SET_USER,
        payload: res.data.data,
      });

      history.push(pathNames.COURSES);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(`${err.response.data.message}`);
      } else {
        setError('Error occured. Please try again after some time');
      }

      // sendAmplitudeData(LOGIN_FAILED, {
      //   user: tempUser,
      //   error: err,
      // });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="form">
          <Content />
          <div className="left">
            <form onSubmit={login}>
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="login">
                <div className="head-div">
                  <div className="head-div-left">
                    <p className="login-title">Login</p>
                    <p className="sub-head">Sharpenerians in the house!</p>
                  </div>
                </div>
                <div className="input-div">
                  <div className="email-input">
                    <p className="email-head">Email or Mobile</p>
                    <TextField
                      required
                      variant="outlined"
                      type="text"
                      className="email-in w-80"
                      autoComplete
                      InputProps={{
                        startAdornment: <EmailOutlinedIcon />,
                      }}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          emailOrMobile: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="password-input">
                    <p className="password-head">Password</p>
                    <TextField
                      className="password-in w-80"
                      required
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        startAdornment: <GppGoodOutlinedIcon />,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{
                                height: '0px',
                                background: 'white',
                                width: '0px',
                                color: 'black',
                              }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="forget-password-link">
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div>
                  {error && <p className="error f6 pa1"> {error}</p>}
                  {/* {showCaptcha && (
                    <div className="flex justify-center">
                      <ReCaptcha
                        style={{ height: '200px' }}
                        onSuccessFunc={(res) => setCaptchaVerified(res.success)}
                      />
                    </div>
                  )} */}
                  <div className="btn-div" style={{ padding: '10px 0' }}>
                    <button
                      className="new-btn auth-btn f5 mt1"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        'Login'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* {loading && <Loader />} */}
      </div>
    </>
  );
};

export default Login;
