import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { taskQuestionTypes } from '../../constants/otherConstants';
import checkmark from '../../assets/Images/checkmark.png';

let timer = null;

const TaskQuestion = ({
  question,
  onAnswerChange,
  alreadySubmitted,
  index,
}) => {
  const [answer, setAnswer] = useState('');

  const optionRefs = useRef([]);

  useEffect(() => {
    optionRefs.current = optionRefs.current.slice(0, question.answers.length);
  }, [question.answers]);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (answer) {
      onAnswerChange(question.id, answer);
    }
  }, [answer]);

  const onOptionChange = (event) => {
    const value = event.target.value;

    // if the question type is mcq then it can have only one correct answer.
    if (taskQuestionTypes[question.type] === taskQuestionTypes[1]) {
      setAnswer(value);
    } else if (taskQuestionTypes[question.type] === taskQuestionTypes[2]) {
      const selectedOptions = optionRefs.current
        .map((item) => (item.checked ? item.value : null))
        .filter((item) => item);
      const ans = selectedOptions.join(',');
      setAnswer(ans);
    }
  };

  return (
    <div className="task-question mt3">
      <div className="flex items-center justify-between">
        <p className="fw5 f5">{`Q.${index + 1} ${question.questionText}`}</p>
        {alreadySubmitted ? (
          <p>
            {question.responses.length > 0
              ? question.responses[0].pointsAwarded
              : 0}
            /{question.points}
          </p>
        ) : (
          <p>
            {question.points} point{question.points > 1 && 's'}
          </p>
        )}
      </div>
      <div className="mt1">
        {taskQuestionTypes[question.type] === taskQuestionTypes[1] ||
        taskQuestionTypes[question.type] === taskQuestionTypes[2] ? (
          <div onChange={onOptionChange}>
            {question.answers.map((answer, index) => (
              <div key={index} className="mt1 flex items-center">
                <input
                  type={
                    taskQuestionTypes[question.type] === taskQuestionTypes[1]
                      ? 'radio'
                      : 'checkbox'
                  }
                  name={question.questionText}
                  value={answer.id}
                  ref={(el) => (optionRefs.current[index] = el)}
                  disabled={alreadySubmitted}
                  defaultChecked={
                    question.responses.length > 0 &&
                    question.responses[0].answer.indexOf(answer.id) !== -1
                  }
                />
                <div className="flex items-center justify-between">
                  <p className="ml2 mb1">{answer.answer}</p>
                  {answer.isCorrect && (
                    <img
                      src={checkmark}
                      style={{
                        width: '18px',
                        height: '18px',
                        marginLeft: '10px',
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <textarea
            placeholder="Write your answer here"
            className="w-100 mt2 pa1 br2 f6"
            value={
              alreadySubmitted
                ? question.responses.length > 0
                  ? question.responses[0].answer
                  : ' '
                : answer
            }
            onChange={(e) => setAnswer(e.target.value)}
            disabled={alreadySubmitted}
          ></textarea>
        )}
      </div>

      {alreadySubmitted && question.answerExplanation && (
        <div>
          <p className="mb1 mt2 b">Explanation:</p>
          <div
            dangerouslySetInnerHTML={{
              __html: question.answerExplanation,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TaskQuestion;
