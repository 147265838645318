import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import agent from '../../agent';
import Loader from '../../common/components/Loader';
import { GET_COURSES } from '../../constants/actionTypes';
import { courseStatus, ERROR_MSG } from '../../constants/otherConstants';
import NavBar from '../NavBar/NavBar';
import Course from './Course';
import './Courses.scss';

function Courses() {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.CoursesReducers.courses);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async (query = '') => {
    setLoading(true);
    try {
      const res = await agent.Courses.getAllCourses(query);
      dispatch({ type: GET_COURSES, payload: res.data.data });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(ERROR_MSG);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <div className="courses-page">
        <div className="head">
          <h2 className="p-5 b text-2xl">Your Courses</h2>
        </div>

        {courses?.length > 0 ? (
          <div className="all-courses">
            {courses?.map((c) => (
              <Course course={c} key={c.id} />
            ))}
          </div>
        ) : (
          <p className="tc">No Courses present</p>
        )}
      </div>
      {loading && <Loader />}
    </>
  );
}
export default Courses;
