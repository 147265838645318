import React from 'react';
import PageWrapper from '../../common/components/PageWrapper';
import BasicTabs from '../../common/components/Tabs';
import TeamList from './TeamList';

function Teams() {
  const tabsComponents = [
    { title: 'Main', component: <TeamList teamType="Main" /> },
    { title: 'Substitute', component: <TeamList teamType="Substitute" /> },
  ];

  return (
    <PageWrapper>
      <h1 className="b text-2xl">Your Teams</h1>
      {/* TABS */}
      <BasicTabs components={tabsComponents} />
    </PageWrapper>
  );
}

export default Teams;
