import React from 'react';

const SearchFilter = ({
  searchByOption,
  setSearchByOption,
  searchString,
  setSearchString,
}) => {
  return (
    <div className="flex ba br2 w-30 ">
      <select
        style={{ border: 'none' }}
        className="f6 bg-black-10 pr1"
        value={searchByOption}
        onChange={(e) => setSearchByOption(e.target.value)}
      >
        <option value="name">Name</option>
        <option value="email">Email</option>
        <option value="mobileNo">Mobile No</option>
        <option value="whatsappNumber">Whatsapp No</option>
      </select>
      <input
        className="pa2 br2 f6 w-100"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        placeholder={`Search User `}
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default SearchFilter;
