import React, { useState } from 'react';
import {
  BiSolidLeftArrowSquare,
  BiSolidRightArrowSquare,
  BiNotepad,
} from 'react-icons/bi';
import { teamTypes } from '../../constants/otherConstants';
import { Chip, TableCell, TableRow } from '@mui/material';
import { getErrorMessage } from '../../constants/helperFunctions';
import { toast } from 'react-toastify';
import agent from '../../agent';
import AddNoteDialog from './AddNoteDialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import IconButton from '../../common/components/IconButton';
import { FaHistory } from 'react-icons/fa';
import { pathNames } from '../../constants/navigationPath';

function Team({ team, setTeams }) {
  const { User: user, teamType, note: existingNote } = team;

  const history = useHistory();
  const [note, setNote] = useState(existingNote ?? '');
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);

  const changeTeam = async () => {
    try {
      await agent.Team.updateTeam({
        UserId: user.id,
        teamType:
          teamType === teamTypes.Main ? teamTypes.Substitute : teamTypes.Main,
      });

      setTeams((prev) =>
        prev.filter((userTeam) => userTeam.User.id !== user.id)
      );

      return toast.success(
        `User moved to ${
          teamType === teamTypes.Main ? teamTypes.Substitute : teamTypes.Main
        } team successfully`
      );
    } catch (error) {
      return toast.error(getErrorMessage(error));
    }
  };

  const addNote = async () => {
    try {
      await agent.Team.updateTeam({
        UserId: user.id,
        note: note,
      });

      setTeams((prev) =>
        prev.map((userTeam) => {
          if (userTeam.User.id !== user.id) {
            return { ...userTeam, note: '' };
          }
          return userTeam;
        })
      );

      toggleAddNoteDialog();
      return toast.success('Note added successfully');
    } catch (error) {
      return toast.error(getErrorMessage(error));
    }
  };

  const toggleAddNoteDialog = () => {
    setShowAddNoteDialog((prev) => !prev);
  };

  const navigateToMockHistory = () => {
    history.push(pathNames.MOCK_HISTORY.replace(':userId', user.id));
  };

  return (
    <>
      <TableRow>
        <TableCell>{user?.id}</TableCell>
        <TableCell>{user?.name}</TableCell>
        <TableCell>{user?.email}</TableCell>
        <TableCell>{user?.mobileNo}</TableCell>
        <TableCell>{user?.whatsappNumber}</TableCell>
        <TableCell>
          <div className="flex gap-2">
            {user?.JobRoles?.map((role, index) => {
              return (
                <Chip
                  size="small"
                  key={index}
                  label={`${role?.name} : ${role?.UserJobRole?.rating}`}
                />
              );
            })}
          </div>
        </TableCell>
        <TableCell>
          <div className="flex gap-3 justify-center">
            <IconButton
              onClick={navigateToMockHistory}
              icon={<FaHistory className="text-xl" />}
              title="Mock History"
            />

            <IconButton
              title={`${
                teamType === teamTypes.Main
                  ? 'Move to Substitute'
                  : 'Move to Main'
              }`}
              onClick={changeTeam}
              icon={
                teamType === teamTypes.Main ? (
                  <BiSolidRightArrowSquare className="text-xl" />
                ) : (
                  <BiSolidLeftArrowSquare className="text-xl" />
                )
              }
            />

            <IconButton
              icon={<BiNotepad className="text-xl" />}
              title="Note"
              onClick={toggleAddNoteDialog}
            />
          </div>
        </TableCell>
      </TableRow>

      {showAddNoteDialog && (
        <AddNoteDialog
          note={note}
          setNote={setNote}
          onClose={toggleAddNoteDialog}
          open={showAddNoteDialog}
          onSubmit={addNote}
        />
      )}
    </>
  );
}

export default Team;
