import {
  GET_COURSE_ASSIGNMENTS,
  GET_COURSES,
  GET_COURSES_STUDENTS,
  GET_COURSE_OVERVIEW,
  GET_CATEGORIES,
} from '../../constants/actionTypes';

const defaultState = {
  courses: [],
  courseStudents: [],
  courseAssignments: [],
  courseOverview: [],
  categories: [],
};

const CoursesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case GET_COURSES:
      return { ...state, courses: action.payload };
    case GET_COURSE_ASSIGNMENTS:
      return { ...state, courseAssignments: action.payload };
    case GET_COURSES_STUDENTS:
      return { ...state, courseStudents: action.payload };
    case GET_COURSE_OVERVIEW:
      return { ...state, courseOverview: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default CoursesReducers;
