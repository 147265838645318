import React, { useEffect, useState } from 'react';
import Appointment from './Appointment';

const AppointmentList = ({
  appointments,
  handleChangeStatus,
  handleShowAppointments,
  upcoming,
  setAppointments,
}) => {
  const appointmentStatus = {
    Old: 'old',
    Upcoming: 'upcoming',
  };

  useEffect(() => {
    if (upcoming) {
      handleShowAppointments(appointmentStatus.Upcoming);
      return;
    }
    handleShowAppointments(appointmentStatus.Old);
  }, []);

  return (
    <>
      {appointments?.map((appointment) => {
        return (
          <Appointment
            key={appointment.id}
            appointment={appointment}
            handleChangeStatus={handleChangeStatus}
            setAppointments={setAppointments}
          />
        );
      })}
    </>
  );
};

export default AppointmentList;
