import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';

import {
  availableLanguages,
  ERROR_MSG,
  taskStatus,
} from '../../constants/otherConstants';
import { SET_USER } from '../../constants/actionTypes';

import CodeEditor from './CodeEditor';
import { useSelector, useDispatch } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Loader from '../../common/components/Loader';
import agent from '../../agent';
import { boilerPlateCodeDelimeter } from '../../constants/otherConstants';

import './Courses.scss';
import './Compiler.scss';
import { toast } from 'react-toastify';

function sleep(ms) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const addUserCodeToBoilerPlate = (userCode, bolierPlateCode) => {
  if (bolierPlateCode.indexOf(boilerPlateCodeDelimeter) === -1) return userCode;

  var codeToReplace = bolierPlateCode.substring(
    bolierPlateCode.indexOf(boilerPlateCodeDelimeter),
    bolierPlateCode.lastIndexOf(boilerPlateCodeDelimeter) +
      boilerPlateCodeDelimeter.length
  );

  return bolierPlateCode.replaceAll(codeToReplace, userCode);
};

var timer = null;

const CreateTestCases = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState({});

  const [testCases, setTestCases] = useState([]);
  const [testCase, setTestCase] = useState({ isHidden: false });
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [state, setState] = useState({
    code: ``,
    language_id: availableLanguages[0].id,
  });

  const user = useSelector((state) => state.common.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const language = availableLanguages.find(
      (l) => l.id === Number(user.codingLanguageId)
    );
    if (language) {
      setSelectedLanguage(language);
    }
  }, [user]);

  useEffect(() => {
    if (props.task && props.task !== task) {
      setTestCases(props.task.TaskTestCases || []);
      setState({ ...state, code: props.task.solution });
      setTask(props.task);
    }
  }, [props.task]);

  useEffect(() => {
    if (task && task.BoilerPlateCodes) {
      if (!task.solution) {
        const code = task.BoilerPlateCodes.find(
          (c) => c.languageId === Number(selectedLanguage.id)
        );

        if (code) {
          setState({ ...state, code: code.code });
        }
      }
    }
  }, [selectedLanguage, task]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateTask = (testCases) => {
    if (testCases.length === 0) {
      toast.error('Add Some Test Cases');
      return false;
    }

    const invalidTestCase = testCases.find((t) => !t.output);
    if (invalidTestCase) {
      toast.error(
        'Some of your test cases are not having the output. Please Generate output then save the test cases'
      );
      return false;
    }
    return true;
  };

  const onLanguageChange = (event) => {
    setLoading(true);
    const language = availableLanguages.find(
      (l) => l.id === Number(event.target.value)
    );
    agent.User.updateUserDetails({
      user: { id: user.id, codingLanguageId: language.id },
    })
      .then(() => {
        setLoading(false);
        dispatch({
          type: SET_USER,
          payload: { ...user, codingLanguageId: language.id },
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data) {
          toast.error(err.response.data.error);
        } else {
          toast.error(ERROR_MSG);
        }
      });
  };

  const generateOutput = async () => {
    const bolierPlateCode = task.BoilerPlateCodes.find(
      (c) => c.languageId === Number(selectedLanguage.id)
    );

    const data = {
      languageId: selectedLanguage.id,
      code: bolierPlateCode
        ? addUserCodeToBoilerPlate(state.code, bolierPlateCode.code)
        : state.code,
      testCases,
    };

    if (testCases.length === 0) {
      toast.error('Add Some test case to generate the Output');
      return;
    }

    if (!data.code) {
      toast.error('Enter some code');
      return;
    }

    if (!data.languageId) {
      toast.error('Please Select a Coding Language');
      return;
    }

    setLoading(true);
    const response = await agent.Assignments.runTastCases(data);
    setLoading(false);
    const jsonResponse = response.data.data;
    let jsonGetSolution = {
      status: { description: 'Queue' },
      stderr: null,
      compile_output: null,
    };
    const tokens = jsonResponse.map((r) => r.token);
    let submisionDone = false;

    while (!submisionDone) {
      let tempTestCases = [...testCases];
      submisionDone = true;
      jsonGetSolution = await agent.Assignments.checkTestCaseStatus({
        codeTokens: tokens,
      });
      jsonGetSolution = jsonGetSolution.data.data;
      jsonGetSolution.submissions.forEach((s, i) => {
        let output = '';
        if (
          s.status.description === 'In Queue' ||
          s.status.description === 'Processing'
        ) {
          submisionDone = false;
          output = `Submission Status: ${s.status.description}`;
        } else {
          if (s.status.description === 'Time Limit Exceeded') {
            output = `Error :${s.status.description}`;
          } else if (s.stdout) {
            output = s.stdout;
            if (output.length > 100) {
              output = output.substring(0, 100);
              output += '\nTruncated Output';
            }
            output = `${output}`;
          } else if (s.stderr) {
            output = s.stderr;
            if (output.length > 100) {
              output = output.substring(0, 100);
              output += '\nTruncated Output';
            }
            output = `Error :${output}`;
          } else {
            output = s.compile_output;
            if (output && output.length > 100) {
              output = output.substring(0, 100);
              output += '\nTruncated Output';
            }
            output = `Error :${output}`;
          }
        }
        tempTestCases[i] = { ...tempTestCases[i], output: output };
      });

      setTestCases(tempTestCases);
      await sleep(1000);
    }
  };

  const saveTestCases = () => {
    if (!validateTask(testCases)) {
      return;
    }

    setLoading(true);
    const bolierPlateCode = task.BoilerPlateCodes.find(
      (c) => c.languageId === Number(selectedLanguage.id)
    );
    const data = {
      AssignmentTaskId: task.id,
      testCases,
      languageId: selectedLanguage.id,
      solution: bolierPlateCode
        ? addUserCodeToBoilerPlate(state.code, bolierPlateCode.code)
        : state.code,
    };

    agent.Assignments.createTastTestCase(data)
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
      });
  };

  const addTestCase = () => {
    if (!testCase.input) {
      toast.error('Enter some input');
      return;
    }

    const existingTestCaseIndex = testCases.findIndex(
      (t) => t.input?.trim() === testCase.input?.trim()
    );

    if (existingTestCaseIndex !== -1) {
      return toast.error(
        `Input already exists at ${existingTestCaseIndex + 1}`
      );
    }

    setTestCases([...testCases, testCase]);
    setTestCase({ input: '', output: '', isHidden: false });
  };

  const changeTestCaseStatus = (index) => {
    const temp = testCases.map((t, i) => {
      if (i === index) {
        return { ...t, isHidden: !t.isHidden };
      }
      return t;
    });
    setTestCases(temp);
  };
  const removeTestCase = (index) => {
    testCases.splice(index, 1);
    setTestCases([...testCases]);
  };

  const addSolution = (value) => {
    setState({ ...state, code: value });
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      agent.Assignments.addSolution({ TaskId: task.id, solution: value })
        .then(() => {})
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(ERROR_MSG);
          }
        });
    }, 500);
  };

  const resetCode = () => {
    addSolution('');
    const code = task.BoilerPlateCodes.find(
      (c) => c.languageId === Number(selectedLanguage.id)
    );
    if (code) {
      setState({ ...state, code: code.code });
    } else {
      setState({ ...state, code: '' });
    }
  };

  const SimpleTaskDialog = () => {
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullScreen>
        <DialogTitle>
          <div className="flex items-center justify-between">
            <h3>Create Test Cases : {task.name}</h3>
            <button type="button" className="red-btn f6 " onClick={handleClose}>
              Close
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="compiler">
            <div className="flex items-center justify-between mt2 mb2">
              <h4>Enter Your Code Here</h4>
              <select
                value={selectedLanguage ? selectedLanguage.id : ''}
                onChange={onLanguageChange}
                disabled={task.isLockedForEdit}
              >
                <option value={''}> Select Coding Language </option>
                {availableLanguages.map((l) => (
                  <option key={l.id} value={l.id}>
                    {' '}
                    {l.name}{' '}
                  </option>
                ))}
              </select>
              <button className="red-btn" onClick={() => resetCode()}>
                {' '}
                Reset
              </button>
            </div>
            <CodeEditor
              codeString={state.code}
              onCodeChange={(value) => addSolution(value)}
              language={selectedLanguage || {}}
              disabled={task.status == taskStatus.LIVE}
            />
            <div className="flex  mt2 mb2">
              <h4>Enter Test Case Input: </h4>
              <textarea
                className="ml2 mr2"
                placeholder="Enter Input"
                style={{ minHeight: '120px' }}
                value={testCase.input}
                onChange={(e) =>
                  setTestCase({ ...testCase, input: e.target.value })
                }
                disabled={task.isLockedForEdit}
              />
              <button
                type="button"
                className="secondary-btn pa2 br2 mr2 h-100"
                onClick={addTestCase}
                disabled={task.isLockedForEdit}
              >
                Add Input
              </button>
            </div>
            <h4 className="bt1">Test Cases: </h4>
            <div className="flex justify-between mt3">
              <table className="mr2">
                <thead>
                  <tr>
                    <th>Input</th>
                    <th>Output</th>
                    <th>Is Hidden</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {testCases.map(
                    (t, i) =>
                      i % 2 === 0 && (
                        <tr key={t.input}>
                          <td>
                            <textarea
                              value={t.input}
                              style={{ minHeight: '120px', border: 'none' }}
                            />
                          </td>
                          <td>
                            <textarea
                              value={
                                t.output && t.output.length > 120
                                  ? t.output.substring(0, 120) +
                                    '\nTruncated Output'
                                  : t.output
                              }
                              placeholder='Click on "Generate Output" button to generate the output'
                              style={{ minHeight: '120px', border: 'none' }}
                            />
                          </td>
                          <td>
                            <Switch
                              checked={t.isHidden}
                              onChange={() => changeTestCaseStatus(i)}
                            />
                          </td>
                          <td>
                            <button onClick={() => removeTestCase(i)}>
                              <DeleteForeverIcon className="danger" />
                            </button>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
              <table className="h-100">
                <thead>
                  <tr>
                    <th>Input</th>
                    <th>Output</th>
                    <th>Is Hidden</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {testCases.map(
                    (t, i) =>
                      i % 2 === 1 && (
                        <tr key={t.input}>
                          <td>
                            <textarea
                              value={t.input}
                              style={{ minHeight: '120px', border: 'none' }}
                            />
                          </td>
                          <td>
                            <textarea
                              value={
                                t.output && t.output.length > 120
                                  ? t.output.substring(0, 120) +
                                    '\nTruncated Output'
                                  : t.output
                              }
                              placeholder='Click on "Generate Output" button to generate the output'
                              style={{ minHeight: '120px', border: 'none' }}
                            />
                          </td>
                          <td>
                            <Switch
                              checked={t.isHidden}
                              onChange={() => changeTestCaseStatus(i)}
                            />
                          </td>
                          <td>
                            <button onClick={() => removeTestCase(i)}>
                              <DeleteForeverIcon className="danger" />
                            </button>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex items-center mt2 mb2">
              <button
                type="button"
                className=" pa2 br2 mt2 secondary-btn mr2"
                onClick={generateOutput}
              >
                Generate Output
              </button>
              {!task.isLockedForEdit && (
                <button
                  type="button"
                  className=" pa2 br2 mt2 secondary-btn mr2"
                  onClick={saveTestCases}
                >
                  Save Test Cases
                </button>
              )}
            </div>
          </div>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  return (
    <>
      <button
        type="button"
        className="ml2 f6 secondary-btn pa2 mt2 mb2"
        onClick={handleClickOpen}
      >
        Test Cases
      </button>
      {SimpleTaskDialog()}
    </>
  );
};

export default CreateTestCases;
