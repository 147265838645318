import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

function SelectInput({
  children,
  label,
  onChange = () => {},
  value,
  className = '',
  style = {},
  size,
}) {
  return (
    <FormControl style={style}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        size={size}
        style={{ paddingTop: '6px', paddingLeft: '6px' }}
        className={className}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
