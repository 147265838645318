import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader(props) {
  return (
    <div className={props.testimonials ? 'testimonials-loader' : 'loader'}>
      <CircularProgress />
      {props.children}
    </div>
  );
}
