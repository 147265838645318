import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Loader from '../../common/components/Loader';
import { GET_QUESTIONS } from '../../constants/actionTypes';
import { getParameterByName } from '../../common/functions';

import agent from '../../agent';
import { ERROR_MSG, questionStatus } from '../../constants/otherConstants';
import Question from './Question';
import './Questions.css';
import { pathNames } from '../../constants/navigationPath';

function Questions({ location: { search } }) {
  const [loading, setLoading] = useState(false);

  const courses = useSelector((state) => state.CoursesReducers.courses);
  const questions = useSelector(
    (state) => state.QuestionsReducers.questions.rows
  );

  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedAssignment, setSelectedAssignment] = useState({});
  const [selectedTask, setSelectedTask] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(
    questionStatus.UNANSWERED
  );

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const CourseId = getParameterByName('CourseId');
    const TaskId = getParameterByName('AssignmentTaskId');
    const AssignmentId = getParameterByName('AssignmentId');
    const status = getParameterByName('status');
    (async () => {
      let course,
        assignment,
        task,
        selectCourseAssignments = assignments;
      if (CourseId) {
        course = courses.find((c) => Number(c.id) === Number(CourseId));
        if (course && course.id !== selectedCourse.id) {
          // the course hs changes so take the new assignmetns of that course
          try {
            const res = await agent.Assignments.getAllAssignments(
              `?CourseId=${CourseId}`
            );
            setAssignments(res.data.data);
            selectCourseAssignments = res.data.data;
          } catch (err) {
            console.log(err, err.response);
            toast.error(ERROR_MSG);
          }
          setSelectedCourse(course);
        }
      }
      if (status) {
        setSelectedStatus(status);
      }

      if (
        AssignmentId &&
        selectCourseAssignments &&
        selectCourseAssignments.length > 1
      ) {
        assignment = selectCourseAssignments.find(
          (c) => Number(c.id) === Number(AssignmentId)
        );
        setSelectedAssignment(assignment);
        const res = await agent.Assignments.getAllTasks(
          `?AssignmentId=${AssignmentId}`
        );
        setTasks(res.data.data);
      } else {
        setSelectedAssignment({ id: '-1' });
        setTasks([]);
      }

      setLoading(true);
      agent.Questions.getQuestions(search)
        .then((res) => {
          dispatch({ type: GET_QUESTIONS, payload: res.data.data });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, err.response);
          toast.error(ERROR_MSG);
          setLoading(false);
        });
    })();
  }, [search]);

  useEffect(() => {
    const TaskId = getParameterByName('AssignmentTaskId');
    const task = tasks.find((c) => Number(c.id) === Number(TaskId));
    if (task) {
      setSelectedTask(task);
    } else {
      setSelectedTask({ id: '-1' });
    }
  }, [tasks]);

  const filterQuestions = (e, key) => {
    const { value } = e.target;
    if (value == '-1' && key == 'CourseId') {
      return;
    }

    const filters = {};

    filters.CourseId = getParameterByName('CourseId');

    // on selecting course we need only course parameter
    // so only get taskId when changing taskId or status
    // else we lose the taskId
    if (key == 'AssignmentTaskId' || key == 'status') {
      filters.AssignmentTaskId = getParameterByName('AssignmentTaskId');
    }

    // on selecting course we need only course parameter
    // so only get assignmentId when changing assignmentid or taskId or status
    // else we lose the assignmentId
    if (key == 'AssignmentId' || key == 'AssignmentTaskId' || key == 'status') {
      filters.AssignmentId = getParameterByName('AssignmentId');
    }

    filters.status = getParameterByName('status');
    filters[key] = value;

    let query = '';
    if (filters.CourseId) {
      query = '?CourseId=' + filters.CourseId;
    }
    if (filters.status) {
      if (query == '') {
        query = '?status=' + filters.status;
      } else {
        query += `&&status=${filters.status}`;
      }
    }
    if (filters.AssignmentId) {
      // set assignmentId in parameter when changing assignmentId or taskId or status
      // else  assignmentId wil become -1
      // if value becomes -1 we dont need that
      if (
        (key == 'AssignmentId' && value != '-1') ||
        key == 'AssignmentTaskId' ||
        key == 'status'
      ) {
        if (query == '') {
          query = '?AssignmentId=' + filters.AssignmentId;
        } else {
          query += `&&AssignmentId=${filters.AssignmentId}`;
        }
      }
    }
    if (filters.AssignmentTaskId) {
      // set taskId in parameter when changing taskId or status
      // else  taskId wil become -1
      // if value becomes -1 we have to remove that
      if ((key == 'AssignmentTaskId' && value != '-1') || key == 'status') {
        if (query == '') {
          query = '?AssignmentTaskId=' + filters.AssignmentTaskId;
        } else {
          query += `&&AssignmentTaskId=${filters.AssignmentTaskId}`;
        }
      }
    }

    history.push(`${pathNames.QNA_PAGE}${query}`);
  };
  return (
    <>
      <div className="questions-page">
        <div className="header">
          <h3>Questions</h3>
        </div>
        <div className="questions-page-content">
          <div className="filters-div">
            <div className="filters">
              <div className="select-div">
                <h3>Select Course</h3>
                <select
                  value={selectedCourse.id}
                  onChange={(e) => {
                    filterQuestions(e, 'CourseId');
                    setSelectedAssignment(e.target.value);
                  }}
                >
                  <option value="-1">Select a Course</option>
                  {courses.map((c) => (
                    <option value={c.id}> {c.name} </option>
                  ))}
                </select>
              </div>
              <div className="select-div">
                <h3>Select Assignment</h3>
                <select
                  value={selectedAssignment.id}
                  onChange={(e) => {
                    filterQuestions(e, 'AssignmentId');
                    setSelectedAssignment(e.target.value);
                  }}
                >
                  <option value="-1">Select an Assignment</option>
                  {assignments &&
                    assignments.map((c) => (
                      <option value={c.id}> {c.name} </option>
                    ))}
                </select>
              </div>
              <div className="select-div">
                <h3>Select Task</h3>
                <select
                  value={selectedTask.id}
                  onChange={(e) => {
                    filterQuestions(e, 'AssignmentTaskId');
                    setSelectedTask(e.target.value);
                  }}
                >
                  <option value="-1">Select a Task</option>
                  {tasks?.map((c) => (
                    <option value={c.id}> {c.name} </option>
                  ))}
                </select>
              </div>
              <div className="select-div">
                <h3>Select status</h3>
                <select
                  value={selectedStatus}
                  onChange={(e) => {
                    filterQuestions(e, 'status');
                    setSelectedStatus(e.target.value);
                  }}
                >
                  {/* <option value={questionStatus.ARCHIVE}>
                    {questionStatus.ARCHIVE}
                  </option> */}
                  <option value={questionStatus.ANSWERED}>
                    {questionStatus.ANSWERED}
                  </option>
                  <option value={questionStatus.UNANSWERED}>
                    {questionStatus.UNANSWERED}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="question-forum">
            <div className="questions-div">
              {!questions || questions.length === 0 ? (
                <h3 style={{ textAlign: 'center', marginTop: '100px' }}>
                  No Questions
                </h3>
              ) : (
                questions.map((q) => <Question key={q.id} question={q} />)
              )}
            </div>
          </div>
        </div>

        {loading && <Loader />}
      </div>
    </>
  );
}

export default Questions;
