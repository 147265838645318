import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import React from 'react';

function MockFeedbackDialog({ open, onClose, feedback }) {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between items-center">
          <p>Mock Feedbacks</p>
          <Close className="cursor-pointer" onClick={onClose} />
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="flex flex-col gap-1">
          {Object.keys(feedback ?? {}).map((key) => (
            <p>
              <span className="b">{key}</span>: {feedback[key] || 'N/A'}
            </p>
          ))}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MockFeedbackDialog;
