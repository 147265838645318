import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AssignmentSubmission.scss';

const ReviewQuestions = (props) => {
  const [task, setTask] = useState({});
  const [questionsAnswers, setQuestionsAnswers] = useState({});
  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    if (props.task && props.task !== task) {
      setTask(props.task);
    }
    if (props.answers && props.answers !== answers) {
      let temp = {};
      props.answers.forEach((q) => {
        temp[q.ReviewQuestionId] = q.answer || '';
      });
      setQuestionsAnswers(temp);
      setAnswers(props.answers);
    }
  }, [props.task, props.answers]);
  return (
    <div className="question-div">
      <div className="flex items-center justify-between bb pb3 pt2">
        <h3> Peer's Review</h3>
        {props.reviewingUser && (
          <Link to={`/user/${props.reviewingUser.id}`}>
            {' '}
            Reviewer: {props.reviewingUser.name}{' '}
          </Link>
        )}
      </div>
      <div>
        {task.AssignmentTaskReviewQuestions &&
          task.AssignmentTaskReviewQuestions.map((q) => (
            <div className="question">
              <h4>{`Q. ${q.question}`} </h4>
              <p style={{ color: questionsAnswers[q.id] ? '' : 'red' }}>
                {' '}
                {questionsAnswers[q.id] || 'Answer Not submitted by peer'}{' '}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ReviewQuestions;
