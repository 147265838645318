import React, { useEffect, useState } from 'react';
import PageWrapper from '../../common/components/PageWrapper';
import AllAppointmentQuestions from './AllAppointmentQuestions';
import AppointmentQuestion from './AppointmentQuestion';
import { useParams } from 'react-router';
import agent from '../../agent';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../constants/helperFunctions';
import './AppointmentReport.scss';

function AppointmentReport() {
  const params = useParams();

  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // Getting All the questions
  useEffect(() => {
    (async () => {
      try {
        const response = await agent.Appointments.getQuestions(
          `?appointmentId=${params?.appointmentId}`
        );
        setAllQuestions(response?.data?.data);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    })();
  }, []);

  // If no question is found then return
  if (allQuestions.length === 0) {
    return <h1>No Questions Available</h1>;
  }

  const totalQuestions = allQuestions.length;
  const totalPendingQuestions =
    totalQuestions -
    allQuestions?.filter((question) => !question?.feedback).length;

  return (
    <PageWrapper className="h-screen">
      {/* HEADING */}
      <div className=" flex  mb-5 gap-3 items-center justify-between ">
        <div className="flex gap-3">
          <p className="text-3xl  b">Appointment Questions</p>
        </div>
        <p className="bg-emerald-700 text-white p-2 rounded-md b">
          {totalPendingQuestions} / {totalQuestions}
        </p>
      </div>

      <div className="flex gap-5">
        <AllAppointmentQuestions
          allQuestions={allQuestions}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          currentQuestionIndex={currentQuestionIndex}
        />
        <AppointmentQuestion
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          allQuestions={allQuestions}
          setAllQuestions={setAllQuestions}
        />
      </div>
    </PageWrapper>
  );
}

export default AppointmentReport;
