import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import './TaskQuestions.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../common/components/Loader';
import { getObjectFromQueryString, reorder } from '../../common/functions';
import TaskQuestion from './TaskQuestion';
import agent from '../../agent';
import { toast } from 'react-toastify';
import { ERROR_MSG, taskStatus } from '../../constants/otherConstants';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

function TaskQuestions() {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState();
  const [questions, setQuestions] = useState([]);
  const location = useLocation();

  const courseAssignments = useSelector(
    (state) => state.CoursesReducers.courseAssignments
  );

  useEffect(() => {
    const filters = getObjectFromQueryString(location.search);
    const assignment = courseAssignments.find((a) => {
      return a.id === Number(filters['AssignmentId']);
    });

    if (assignment) {
      const task = assignment?.AssignmentTasks.find(
        (a) => a.id === Number(filters.TaskId)
      );
      setTask(task);
      setLoading(true);
      // get the questions of this task
      agent.Assignments.getTaskQuestions(`?AssignmentTaskId=${task.id}`)
        .then((res) => {
          setQuestions(res.data.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, []);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let reorderedQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    reorderedQuestions = reorderedQuestions.map((a, i) => ({
      ...a,
      index: i + 1,
    }));

    reorderedQuestions = reorderedQuestions.sort((a, b) => a.index - b.index);
    setQuestions(reorderedQuestions);
    const data = reorderedQuestions
      .map((a) => ({
        id: a.id,
        index: a.index,
      }))
      .filter((a) => a.id);

    agent.Assignments.reorderTaskQuestion({ taskQuestions: data }).catch(() => {
      toast.error(ERROR_MSG);
    });
  }

  const onQuestionAdd = (question) => {
    setQuestions([...questions, question]);
  };

  const onQuestionDelete = (question) => {
    const filteredQuestions = questions.filter((a) => a.id !== question.id);
    setQuestions(filteredQuestions);
  };

  return (
    <>
      <NavBar />
      {task && (
        <div className="task-questions-page">
          <h2 className="h2"> {task.name}</h2>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="task-question-dnd">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {questions.map((q, i) => (
                    <TaskQuestion
                      key={q.id}
                      question={q}
                      onQuestionDelete={onQuestionDelete}
                      isCreateNew={false}
                      index={i}
                      task={task}
                    />
                  ))}
                  {!task.isLockedForEdit && (
                    <TaskQuestion
                      isCreateNew={true}
                      onQuestionAdd={onQuestionAdd}
                      onQuestionDelete={onQuestionDelete}
                      task={task}
                      index={questions.length + 1}
                    />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
}
export default TaskQuestions;
