import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../common/components/Loader';
import { pathNames } from '../../constants/navigationPath';
import { assignmentStatus, ERROR_MSG } from '../../constants/otherConstants';
import NavBar from '../NavBar/NavBar';
import './Courses.scss';

import CreateAssignment from './CreateAssignment';

import { useDispatch, useSelector } from 'react-redux';
import { getParameterByName, reorder } from '../../common/functions';
import {
  GET_ASSIGNMENTS_TASK_TAGS,
  GET_CATEGORIES,
  GET_COURSE_ASSIGNMENTS,
} from '../../constants/actionTypes';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import agent from '../../agent';
import Assignment from './Assignment';

function CoursePlan({ location: { search } }) {
  const [course, setCourse] = useState({
    id: Math.round(Math.random() * 1000),
    staggeredAdmission: false,
  });
  const [filter, setFilter] = useState(assignmentStatus.LIVE);
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const courseAssignments = useSelector(
    (state) => state.CoursesReducers.courseAssignments
  );
  const courses = useSelector((state) => state.CoursesReducers.courses);
  const dispatch = useDispatch();
  const courseId = getParameterByName('CourseId');

  useEffect(() => {
    (async () => {
      try {
        const [tags, categories] = await Promise.all([
          agent.Assignments.getAllAssignmentTaskTags(),
          agent.Category.getCategories(),
        ]);

        dispatch({ type: GET_ASSIGNMENTS_TASK_TAGS, payload: tags.data.data });
        dispatch({ type: GET_CATEGORIES, payload: categories.data.data });
      } catch (err) {
        console.log(err, err.response);
      }
    })();
  }, []);

  useEffect(() => {
    const course = courses?.find((c) => c.id === Number(courseId));
    setCourse(course);
    const query = `?CourseId=${courseId}&&status=${filter}`;
    setLoading(true);

    agent.Assignments.getAllAssignments(query)
      .then((res) => {
        dispatch({ type: GET_COURSE_ASSIGNMENTS, payload: res.data.data });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
      });
  }, [search, filter]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let assignments = reorder(
      courseAssignments,
      result.source.index,
      result.destination.index
    );
    assignments = assignments?.map((a, i) => ({
      ...a,
      assignmentIndex: i + 1,
    }));

    dispatch({ type: GET_COURSE_ASSIGNMENTS, payload: assignments });

    const data = assignments?.map((a) => ({
      id: a.id,
      assignmentIndex: a.assignmentIndex,
    }));

    agent.Assignments.reorderAssignments({ assignments: data })
      .then(() => {})
      .catch((err) => {
        toast.error(ERROR_MSG);
        console.log(err, err.response);
      });
  }

  return (
    <>
      <NavBar />
      <div className="course-plan-page">
        <div className="header">
          <Link to={`${pathNames.COURSE_DETAILS}?CourseId=${course.id}`}>
            <h3>Course Plan for {course.name}</h3>
          </Link>
          <div className="body">
            <div>
              <select
                className="mr3"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value={assignmentStatus.LIVE}>Live</option>
                <option value={assignmentStatus.ARCHIVE}>Archive</option>
              </select>
            </div>
            <CreateAssignment
              index={courseAssignments && courseAssignments.length + 1}
            />
            <Link to={pathNames.COURSES} className="primary-btn">
              <ArrowBackIosIcon /> Go Back to Courses
            </Link>
          </div>
        </div>

        {/* <div className='body'>
          <button className='primary-btn' onClick={handleAddAssignmentsDialog}>
            Add assignments
          </button>
        </div> */}
        <div className="actions">
          {/* <button type='button' onClick={() => createCourse()} >

            {isUpdate ? "Update" : "Create"}
          </button> */}
        </div>
        <div className="assignments">
          {courseAssignments && courseAssignments.length > 0 ? (
            courseAssignments?.map((item, index) => (
              <Assignment assignment={item} key={item.id} index={index} />
            ))
          ) : (
            <p
              style={{
                textAlign: 'center',
                marginTop: '50px',
                fontSize: '20px',
              }}
            >
              No Assignment Found
            </p>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default CoursePlan;
