import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SelectAppointmentDay from './SelectAppointmentDay';
import agent from '../../agent';
import { ERROR_MSG } from '../../constants/otherConstants';
import './SolveAssignment.scss';
import AppointmentTimingConfirmation from './AppointmentTimingConfirmation';
import AppointmentTimingSubmitted from './AppointmentTimingSubmitted';
import { GET_ALL_APPOINTMENTS_SLOTS } from '../../constants/actionTypes';
import Loader from '../../common/components/Loader';

function generate_series(step) {
  let dt = moment(new Date(1970, 0, 1, 7, 0, 0));
  const rc = [];

  while (dt.get('hour') < 23) {
    const data = {
      from: moment(dt),
      to: moment(dt.add(step, 'minutes')),
    };
    rc.push(data);
  }

  return rc;
}

const AppointmentsFreeTimings = ({
  AppointmentSetupId,
  slotsLength,
  slots,
  setShowSlots,
}) => {
  const timeSeries = generate_series(slotsLength);
  const days = new Set();
  for (let i = 0; i < slots.length; ++i) {
    days.add(slots[i].day);
  }
  const appointments = useSelector((state) => state.SlotsReducer.appointments);
  const dispatch = useDispatch();
  const ChooseTimeSlot = ({
    appointmentSlots: propsAppointmentSlots,
    submitSlots,
  }) => {
    const [appointmentSlots, setAppointmentSlots] = useState([]);
    useEffect(() => {
      const filteredSlots = propsAppointmentSlots.filter(
        (slot) => +slot.day === +selectedDay
      );
      setAppointmentSlots(filteredSlots);
    }, [propsAppointmentSlots, selectedDay]);

    const addTimeSlot = (time) => {
      if (appointmentSlots.find((is) => is.time === time.format('HH:mm:ss'))) {
        const updatedAppointmentSlots = appointmentSlots.filter(
          (is) => is.time !== time.format('HH:mm:ss')
        );
        setAppointmentSlots(updatedAppointmentSlots);
      } else {
        setAppointmentSlots([
          ...appointmentSlots,
          { time: time.format('HH:mm:ss'), day: +selectedDay },
        ]);
      }
    };

    return (
      <div className="time-table">
        <div className="w-30">
          <p className="heading-1">Read carefully</p>
          <div className="pl3">
            <p className="f5">How many slots to choose? </p>
            <p className="f6">
              Select any number of slots based on when you are available
            </p>
            <p className="f6">
              The more options you choose, the faster you are matched with a
              student to appointment.
            </p>
            <p className="f6 selected">Green - Available Slots</p>
            <p className="f6 booked">Red - Booked Slots</p>
            <p className="f6">Grey - Empty Slots</p>
            <br />
            <button
              className="blue-btn mt3 ml2"
              onClick={() => submitSlots(appointmentSlots)}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="w-[80%] right">
          <p className="heading-2">Click the slot when you are available</p>
          <div className="grid-section">
            {timeSeries.map((t, i) => {
              const slot = appointmentSlots.find(
                (slot) => slot.time === t.from.format('HH:mm:ss')
              );
              const isSelected = slot !== undefined;
              return (
                <button
                  key={i}
                  className={`tc time ${
                    isSelected && !slot?.isBooked
                      ? 'selected'
                      : slot?.isBooked
                      ? 'booked'
                      : ''
                  }`}
                  onClick={() => addTimeSlot(t.from)}
                  disabled={slot?.isBooked}
                >
                  {`${t.from.format('hh:mm')} - ${t.to.format('hh:mm A')}`}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const [showDialog, setShowDialog] = useState(true);
  const [loading, setLoading] = useState(false);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [selectedDay, setSelectedDay] = useState('');

  const handleClose = () => {
    setShowSlots(false);
  };

  const submitSlots = async (appointmentSlots) => {
    try {
      const slots = appointmentSlots.map((i) => ({
        time: i.time,
        isBooked: i.isBooked,
      }));
      setAppointmentSlots(appointmentSlots);
      setLoading(true);

      const slotsArray = Object.keys(slots).map((key) => {
        return {
          ...slots[key],
          day: selectedDay,
        };
      });
      const res = await agent.Appointments.addMentorSlots({
        slots: slotsArray,
        AppointmentSetupId,
        selectedDay,
      });

      // START =>logic to add new  slots in  the specific day
      // const slotsWithSameDay = [];
      // const backendSlots = res.data.data;
      // for (let i = 0; i < appointments.length; i++) {
      //   for (let j = 0; j < backendSlots.length; j++) {
      //     if (
      //       appointments[i].AppointmentSetupId ===
      //       backendSlots[j].AppointmentSetupId
      //     ) {
      //       const slots = appointments[i].slots;

      //       for (let k = 0; k < slots.length; k++) {
      //         if (slots[k].day !== +selectedDay) {
      //           slotsWithSameDay.push(slots[k]);
      //         }
      //       }
      //     }
      //   }
      // }
      // const newArray = [...new Set(slotsWithSameDay), ...res.data.data];
      // let goOutOfLoop = false;
      // for (let i = 0; i < appointments.length; i++) {
      //   for (let j = 0; j < backendSlots.length; j++) {
      //     if (
      //       appointments[i].AppointmentSetupId ===
      //       backendSlots[j].AppointmentSetupId
      //     ) {
      //       appointments[i].slots = newArray;
      //       goOutOfLoop = true;
      //       dispatch({
      //         type: GET_ALL_APPOINTMENTS_SLOTS,
      //         payload: appointments,
      //       });
      //       break;
      //     }
      //     if (goOutOfLoop) {
      //       break;
      //     }
      //   }
      // }

      setActiveStep(2);
    } catch (error) {
      if (error?.response?.data)
        return toast.error(error?.response?.data?.message);
      toast.error(ERROR_MSG);
    } finally {
      setLoading(false);
    }
  };

  const deleteSlot = async (day, index) => {
    try {
      const slot = appointmentSlots[day][index];
      if (slot.id) {
        setLoading(true);
      }
      const filteredSlots = appointmentSlots[day].filter((s, i) => index !== i);
      setAppointmentSlots({ ...appointmentSlots, [day]: filteredSlots });
    } catch (error) {
      if (error?.response?.data)
        return toast.error(error?.response?.data?.message);
      toast.error(ERROR_MSG);
    } finally {
      setLoading(false);
    }
  };

  const getComponentForStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <SelectAppointmentDay
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            setActiveStep={setActiveStep}
            days={days}
          />
        );
      case 1:
        return (
          <ChooseTimeSlot
            appointmentSlots={slots}
            submitSlots={submitSlots}
            deleteSlot={deleteSlot}
            selectedDay={selectedDay}
            slots={slots}
          />
        );
      // case 2:
      //   return (
      //     <AppointmentTimingConfirmation
      //       appointmentSlots={appointmentSlots}
      //       submitSlots={submitSlots}
      //       setActiveStep={setActiveStep}
      //       onWhatsappNumberSubmitted={() => setActiveStep(2)}
      //     />
      //   );
      case 2:
        return (
          <AppointmentTimingSubmitted
            appointmentSlots={appointmentSlots}
            setActiveStep={setActiveStep}
            slotsLength={slotsLength}
          />
        );
      case 4: {
        setShowDialog(false);
        setActiveStep(0);
        handleClose();
        return;
      }

      default:
        return null;
    }
  };

  const freeSlotsDialog = () => {
    return (
      <Dialog
        onClose={handleClose}
        open={showDialog}
        maxWidth="md"
        fullWidth={activeStep === 0}
      >
        <p className="table-heading">Book appointment</p>

        {getComponentForStep()}

        {loading && <Loader />}
      </Dialog>
    );
  };

  return <>{freeSlotsDialog()}</>;
};

export default AppointmentsFreeTimings;
