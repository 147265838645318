'use strict';
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FlagIcon from '@material-ui/icons/Flag';
import Loader from '../../common/components/Loader';
import './AssignmentSubmission.scss';
import agent from '../../agent';
import { flagStatus } from '../../constants/data';
import { ERROR_MSG } from '../../constants/otherConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';

import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],

    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const UserFlags = ({ user, task, assignment }) => {
  const [showFlagDialog, setShowFlagDialog] = useState(false);
  const [flag, setFlag] = useState({ status: flagStatus.ACTIVE });
  const [userFlags, setUserFlags] = useState([]);
  const [currentFlag, setCurrentFlag] = useState({});
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVisibleToUser, setIsVisibleToUser] = useState(true);
  const dispatch = useDispatch();
  const countActiveFlags = () => {
    const activeFlags = userFlags.filter((flag) => flag.status === 'Active');
    return activeFlags.length;
  };

  useEffect(() => {
    (async () => {
      try {
        if (!(user && user.id)) {
          return;
        }

        let userFlags = [];
        setLoading(true);
        if (task && task.id) {
          const res = await agent.UserFlags.getUserFlags(
            `?UserId=${user.id}&&AssignmentTaskId=${task.id}`
          );
          userFlags = res.data.data;
        } else if (assignment && assignment.id) {
          const res = await agent.UserFlags.getUserFlags(
            `?UserId=${user.id}&&AssignmentId=${assignment.id}`
          );
          userFlags = res.data.data;
        } else {
          const res = await agent.UserFlags.getUserFlags(`?UserId=${user.id}`);
          userFlags = res.data.data;
        }

        setLoading(false);
        setUserFlags(userFlags);
      } catch (err) {
        toast.error(ERROR_MSG);
        console.log(err, err.response);
        setLoading(false);
      }
    })();
  }, [assignment, task]);

  const handleFlagDialog = async () => {
    setShowFlagDialog(!showFlagDialog);
  };

  const createFlag = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const data = {
        ...flag,
        UserId: user.id,
        AssignmentTaskId: task.id,
        isVisible: isVisibleToUser,
      };

      setLoading(true);

      const res = await agent.User.addFlag(data);

      const tempUserFlags = [{ ...res.data.data, task }, ...userFlags];
      // setUserFlags(tempUserFlags);
      console.log(tempUserFlags);
      setUserFlags(tempUserFlags);
      setFlag({ description: '', status: flagStatus.ACTIVE });
      toast.success('Flag added');
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      setLoading(false);
      toast.error(ERROR_MSG);
    }
  };

  const showFlagToUser = async (flag) => {
    try {
      const data = {
        ...flag,
        UserId: user.id,
        AssignmentTaskId: task.id,
        isVisible: !flag.isVisible,
      };

      setLoading(true);

      await agent.User.addFlag(data);

      const tempFlags = userFlags.map((f) => {
        if (f.id === flag.id) {
          f.isVisible = !f.isVisible;
        }
        return f;
      });

      setUserFlags(tempFlags);

      setFlag({ description: '', status: flagStatus.ACTIVE });
      toast.success('Flag updated');
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      setLoading(false);
      toast.error(ERROR_MSG);
    }
  };
  const deleteFlag = (id) => {
    setLoading(true);
    agent.User.deleteFlag(id)
      .then(() => {
        const tempUserFlags = userFlags.filter((f) => f.id !== id);

        setUserFlags(tempUserFlags);
        setLoading(false);
        toast.info('Flag deleted');
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
        toast.error(ERROR_MSG);
      });
  };
  const changeFlagStatus = async (flag) => {
    setLoading(true);
    const data = {
      ...flag,
      UserId: user.id,
      status:
        flag.status === flagStatus.ACTIVE
          ? flagStatus.INACTIVE
          : flagStatus.ACTIVE,
    };
    setLoading(true);
    // console.log(data);
    try {
      await agent.User.addFlag(data);

      const tempUserFlags = userFlags.map((f) => {
        if (f.id === flag.id) {
          return data;
        }
        return f;
      });
      // setUserFlags(tempUserFlags);
      setUserFlags(tempUserFlags);
      toast.success('Flag status changed');
      setLoading(false);
    } catch (err) {
      console.log(err, err.response);
      setLoading(false);
      toast.error(ERROR_MSG);
    }
  };

  const handleCommentsDialog = (flag) => {
    setCurrentFlag(flag);
    setShowCommentsDialog(!showCommentsDialog);
  };

  const handleCommentChange = (value) => {
    setComment(value);
  };

  const postComment = () => {
    const data = { UserFlagId: currentFlag.id, comment };
    const newFlagData = { ...currentFlag };
    setLoading(true);
    agent.UserFlags.postComment(data)
      .then((res) => {
        // console.log(res.data.data);
        setLoading(false);
        const tempUserFlags = userFlags.map((f) => {
          if (f.id === newFlagData.id) {
            newFlagData.UserFlagComments.unshift(res.data.data);
            return newFlagData;
          }
          return f;
        });

        setCurrentFlag(newFlagData);
        setUserFlags(tempUserFlags);
        setUserFlags(tempUserFlags);
        setComment('');
        toast.success('Comment Posted');
      })
      .catch((err) => {
        console.log(err, err.response, ' error in the postComment');
        toast.error(ERROR_MSG);
        setLoading(false);
      });
  };

  const FlagStudentDialog = () => {
    return (
      <Dialog open={showFlagDialog} onClose={handleFlagDialog} fullWidth>
        <DialogTitle>Flag Student: {user.name}</DialogTitle>
        <DialogContent>
          {assignment && <h4 className="mb2">Assignment: {assignment.name}</h4>}
          {task && <h4 className="mb2">Task: {task.name}</h4>}
          <div className="user-flag-div">
            <table className="">
              <thead>
                <tr>
                  <th>Flag Description</th>
                  <th>Task</th>
                  <th>Status</th>
                  <th>Comments</th>
                  <th>Actions</th>
                  <th>Showing To User</th>
                </tr>
              </thead>
              <tbody>
                {userFlags &&
                  userFlags.map((f, i) => (
                    <tr className="flag" key={i}>
                      <td>{f.description}</td>
                      <td>
                        {f.task ? (
                          <button
                            c
                            onClick={() =>
                              dispatch(
                                push(
                                  `/user/${f.UserId}/assignment/${f.task.AssignmentId}?taskId=${f.task.id}`,
                                  { user }
                                )
                              )
                            }
                            className="underline blue  "
                          >
                            {f.task.name}
                          </button>
                        ) : (
                          'No Task present'
                        )}
                      </td>
                      <td>
                        <button
                          className={`${
                            f.status === flagStatus.ACTIVE ? 'blue' : 'red'
                          } f6`}
                          onClick={() => changeFlagStatus(f)}
                        >
                          {f.status}
                        </button>
                      </td>
                      <td>
                        <button
                          className="underline blue "
                          onClick={() => handleCommentsDialog(f)}
                        >
                          Show
                        </button>{' '}
                      </td>
                      <td>
                        <button
                          className=" danger"
                          onClick={() => deleteFlag(f.id)}
                        >
                          <DeleteForeverIcon />{' '}
                        </button>{' '}
                      </td>
                      <td>
                        <button
                          className="primary-btn"
                          onClick={() => showFlagToUser(f)}
                        >
                          {f.isVisible ? 'Yes' : 'No'}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {task && (
              <>
                <legend className="mt2">Flag Description</legend>

                <form onSubmit={createFlag} id="create-flag-form">
                  <input
                    required
                    type="text"
                    className="pa2 f6"
                    placeholder="Add new flag"
                    value={flag.description}
                    onChange={(e) =>
                      setFlag({ ...flag, description: e.target.value })
                    }
                  />
                </form>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {task && (
            <button
              type="submit"
              formTarget="create-flag-form"
              className="primary-btn pa2 br2"
              onClick={createFlag}
            >
              Flag student
            </button>
          )}
          <button
            type="button"
            className="red-btn pa2 br2"
            onClick={handleFlagDialog}
          >
            Cancel
          </button>
        </DialogActions>
        {loading && <Loader />}
      </Dialog>
    );
  };
  const userFlagCommentsDialog = () => {
    return (
      <Dialog open={showCommentsDialog} onClose={handleCommentsDialog}>
        <DialogTitle>Flag Comments</DialogTitle>
        <DialogContent>
          <div>{currentFlag.description}</div>
          <div className="flag-comments-div">
            <div className="comment-editor">
              <ReactQuill
                className="editor"
                value={comment}
                onChange={(e) => handleCommentChange(e)}
                modules={modules}
                formats={formats}
              />
              <button
                type="button"
                className="primary-btn pa2 mt2 mb2 ml-auto"
                onClick={postComment}
              >
                Post comment
              </button>
            </div>
            <div className="flag-comments">
              <h3>Comments</h3>
              {currentFlag &&
              currentFlag.UserFlagComments &&
              currentFlag.UserFlagComments.length === 0 ? (
                <div> No comments </div>
              ) : (
                currentFlag &&
                currentFlag.UserFlagComments &&
                currentFlag.UserFlagComments.map((a, i) => (
                  <div key={i} className="comment">
                    <div className="comment-head">
                      <div className="user">
                        <AccountCircleIcon />
                        {a.User.id === user.id ? a.User.name : 'You'}
                      </div>
                      <div className="comment-date">
                        Posted on :{' '}
                        {moment(a.createdAt).format('DD/MM/YYYY HH:mm')}
                      </div>
                    </div>

                    <div className="content">
                      <div
                        className="ql-editor"
                        key={a.id}
                        dangerouslySetInnerHTML={{ __html: a.comment }}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="red-btn pa2 br2"
            onClick={handleCommentsDialog}
          >
            Close
          </button>
        </DialogActions>
        {loading && <Loader />}
      </Dialog>
    );
  };
  return (
    <>
      <button className=" flag pa2 br2 ml2 mr2" onClick={handleFlagDialog}>
        <FlagIcon className="danger" />
        <span className={countActiveFlags() > 9 ? 'badge double' : 'badge'}>
          {countActiveFlags()}
        </span>
      </button>

      {FlagStudentDialog()}
      {userFlagCommentsDialog()}
    </>
  );
};

export default UserFlags;
