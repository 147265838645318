import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions } from '@material-ui/core';
import Slider from '../../common/components/Slider';
import Switch from '@material-ui/core/Switch';

const CourseStudentsFilter = ({
  filterOptions,
  setFilterOptions,
  removeFilter,
  filterKeyToString,
  courseAssignments,
}) => {
  const [localFilterOptions, setLocalFilterOptions] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setLocalFilterOptions(filterOptions);
  }, [filterOptions]);
  const handleClose = () => {
    setOpen(false);
  };

  const onSliderChange = (e, val, key) => {
    setLocalFilterOptions({ ...localFilterOptions, [key]: val });
  };

  const applyFilters = () => {
    setFilterOptions(localFilterOptions);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setLocalFilterOptions(filterOptions);
  };

  const changeSelectFilter = (key, event) => {
    const {
      target: { value },
    } = event;
    if (!value) {
      return;
    }
    setLocalFilterOptions({ ...localFilterOptions, [key]: value });
  };

  const renderDialog = () => {
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true}>
        <DialogTitle>Filter Users</DialogTitle>
        <DialogContent>
          <div className="flex flex-wrap mt3 mb3 ">
            {Object.keys(localFilterOptions).map(
              (k) =>
                k !== 'role' && (
                  <button
                    onClick={() => removeFilter(k)}
                    className="ba br2 pa1 mr3 mt2 f6 flex items-center "
                  >
                    {k === 'currentAssignment' || k === 'lastAssignment' ? (
                      <p className="mr2 f6">
                        {filterKeyToString[k]} :{' '}
                        {`${
                          courseAssignments.find(
                            (a) =>
                              Number(a.id) === Number(localFilterOptions[k])
                          ).name
                        }`}
                      </p>
                    ) : (
                      <p className="mr2 f6">
                        {filterKeyToString[k]} : {`${localFilterOptions[k]}`}{' '}
                      </p>
                    )}

                    <p className="b">X</p>
                  </button>
                )
            )}
          </div>
          <div className="filters">
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">
                Min no. of task with pending review
              </p>
              <Slider
                onChange={(e, val) =>
                  onSliderChange(e, val, 'reviewPendingTasks')
                }
                value={localFilterOptions.reviewPendingTasks}
                max={50}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">
                Min no. of assignments with pending review
              </p>
              <Slider
                onChange={(e, val) =>
                  onSliderChange(e, val, 'reviewPendingAssignment')
                }
                value={localFilterOptions.reviewPendingAssignment}
                max={50}
              />
            </div>

            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">Min points</p>
              <Slider
                onChange={(e, val) => onSliderChange(e, val, 'totalPoints')}
                value={localFilterOptions.totalPoints}
                max={100}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">
                Min # of task submitted in last 7 days
              </p>
              <Slider
                onChange={(e, val) => onSliderChange(e, val, 'last7days')}
                value={localFilterOptions.last7days}
                max={15}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">
                Min # of task submitted in last 3 days
              </p>

              <Slider
                onChange={(e, val) => onSliderChange(e, val, 'last3days')}
                value={localFilterOptions.last3days}
                max={10}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">
                Min # of task submitted in last 1 days
              </p>

              <Slider
                onChange={(e, val) => onSliderChange(e, val, 'last1day')}
                value={localFilterOptions.last1day}
                max={5}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">Min # of days in course</p>

              <Slider
                onChange={(e, val) => onSliderChange(e, val, 'inCourse')}
                value={localFilterOptions.inCourse}
                max={500}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">Is Course Completed? </p>

              <Switch
                checked={localFilterOptions.isCompleted}
                onChange={() =>
                  setLocalFilterOptions({
                    ...localFilterOptions,
                    isCompleted: !localFilterOptions.isCompleted,
                  })
                }
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">Current Assignment </p>
              <select
                value={localFilterOptions.currentAssignment}
                onChange={(e) => changeSelectFilter('currentAssignment', e)}
              >
                <option value={''}>Select an Assignment</option>

                {courseAssignments?.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between items-center">
              <p className="w-50 mt2 mb2 mr2">Last Assignment </p>
              <select
                value={localFilterOptions.lastAssignment}
                onChange={(e) => changeSelectFilter('lastAssignment', e)}
              >
                <option value={''}>Select an Assignment</option>

                {courseAssignments?.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={() => setOpen(false)} className="f6 w-50 red-btn">
            Close
          </button>
          <button
            onClick={() => applyFilters()}
            className="f6 w-50 primary-btn"
          >
            Apply Filters
          </button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <button onClick={() => handleOpen(true)}>
        <img
          style={{ width: '30px' }}
          src="https://img.icons8.com/ios-filled/100/000000/filter--v1.png"
        />
      </button>
      {renderDialog()}
    </>
  );
};
export default CourseStudentsFilter;
